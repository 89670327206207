<template>
  <BCard no-body class="o-profile-card">
    <BCardHeader class="header text-white bg-secondary">
      <BRow>
        <BCol>
          <div class="header-title header-col">
            <SOTagLabel v-if="internalId" class="profile-tag" :title="$t('fields.profile-id-template', { internalId })">
              {{ internalId }}
            </SOTagLabel>
            <OProfileName v-if="person" v-bind="person" :as-anonym="asAnonym" />
            <template v-else>{{ $t('profile.invalid-name-label') }}</template>
          </div>
        </BCol>
        <BCol class="text-right" md="auto">
          <slot name="header-toolbar" />
        </BCol>
      </BRow>
    </BCardHeader>
    <slot name="prepend" />
    <BCardBody class="hideable-on-empty">
      <slot />
    </BCardBody>
    <slot name="append" />
    <slot name="footer" slot="footer" />
  </BCard>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BRow, BCol } from 'bootstrap-vue';
import OProfileName from './name';
import SOTagLabel from 'library/components/src/components/so/tag-label';

export default {
  name: 'o-profile-card',
  components: {
    OProfileName,
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    SOTagLabel,
  },
  props: {
    internalId: String,
    person: Object,
    asAnonym: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.header {
  padding: 0;
}

.profile-tag {
  margin-right: $space-small;
}
</style>

<style lang="scss">
.o-profile-card > .card-header {
  .header-col {
    display: inline-block;
    padding: 1rem 1.25rem;
    height: 100%;
  }
}
</style>
