<template>
  <span class="o-opener" :class="model ? 'is-open' : 'is-closed'" @click="model = !model">
    <SOIcon class="icon" name="chevron-up" :size="size" />
  </span>
</template>

<script>
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-opener',
  components: {
    SOIcon,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: Boolean,
    size: {
      type: String,
      default: 'sm',
    },
  },
  computed: {
    model: {
      get() {
        return this.open;
      },
      set(v) {
        this.$emit('change', !!v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.o-opener {
  display: inline-block;
  cursor: pointer;

  > .icon {
    display: inline-block;
    transition: transform ease-in-out 0.2s;
    transform: rotateZ(180deg);
  }

  &.is-open > .icon {
    transform: rotateZ(0deg);
  }
}
</style>
