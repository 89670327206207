var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-reservation-toolbar"},[_c('BRow',[_c('BCol',{attrs:{"sm":"12","md":"auto"}},[_c('BButton',{directives:[{name:"prompt",rawName:"v-prompt.click",value:({
          message: _vm.$t(`${_vm.prefix}.release-profile-message`),
          callback: { success: _vm.onClickReleaseProfile },
        }),expression:"{\n          message: $t(`${prefix}.release-profile-message`),\n          callback: { success: onClickReleaseProfile },\n        }",modifiers:{"click":true}}],attrs:{"variant":"danger","title":_vm.$t(`${_vm.prefix}.release-profile-label`)}},[_c('SOIcon',{attrs:{"name":"trash","size":"sm"}})],1)],1),_c('BCol',{staticClass:"text-right"},[_c('div',{staticClass:"button-container"},[(_vm.isExtensionAllowed)?_c('BButton',{directives:[{name:"prompt",rawName:"v-prompt.click",value:({
            message: _vm.$t(`${_vm.prefix}.extend-reservation-message`),
            callback: { success: _vm.onClickExtendReservation },
          }),expression:"{\n            message: $t(`${prefix}.extend-reservation-message`),\n            callback: { success: onClickExtendReservation },\n          }",modifiers:{"click":true}}],attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.prefix}.extend-reservation-label`))+" ")]):_vm._e(),(_vm.isContractionAllowed)?_c('BButton',{directives:[{name:"prompt",rawName:"v-prompt.click",value:({
            message: _vm.$t(`${_vm.prefix}.contract-message`),
            callback: { success: _vm.onClickContractReport },
          }),expression:"{\n            message: $t(`${prefix}.contract-message`),\n            callback: { success: onClickContractReport },\n          }",modifiers:{"click":true}}],attrs:{"variant":"primary"}},[_c('SOIcon',{attrs:{"name":"user-check","size":"sm","as-label-icon":""}}),_vm._v(" "+_vm._s(_vm.$t(`${_vm.prefix}.contract-label`))+" ")],1):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }