import { mapGetters } from 'library/src/utilities/vueex/store-mapping';
import { SessionKey } from 'library/src/utilities/session-storage';
const sessionAccountType = new SessionKey('registration-accountType');

const namespaced = true;

const state = {
  accountType: sessionAccountType.get(),
};

const getters = {
  ...mapGetters(state),
};

const mutations = {
  setAccountType(state, value) {
    state.accountType = value;
    sessionAccountType.set(value);
  },
};

const actions = {
  async reset({ state }) {
    state.accountType = null;
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
