<template>
  <div class="o-profile-info">
    <BRow no-gutters>
      <BCol sm="12" md="3" lg="2">
        <ProfileImage class="picture" :picture="picture" />
      </BCol>
      <BCol sm="12" md="7" lg="7">
        <div class="persona">
          <OValue label="profile.job" variant="column">
            <CModelPosition :positions="workspace.position.positions" />
          </OValue>
          <OValue v-if="workspace.position.experience" label="profile.experience" variant="column">
            {{ $t(`generic.in-years`, { value: workspace.position.experience }) }}
          </OValue>

          <OValue v-if="jobTitle" label="profile.last-career" variant="column">
            <OValue class="job-title" :value="jobTitle" show-not-set />
            <OValue class="business" :value="business" />
          </OValue>

          <OValue label="profile.locality" variant="column" :value="address.locality" />
          <OValue label="profile.gross-annual-salary" variant="column">{{ salaryLabel }}</OValue>

          <OValue label="profile.keywords" variant="column">
            <OModelKeywords :keywords="workspace.position.keywords" />
          </OValue>
        </div>
      </BCol>
      <BCol class="text-right">
        <OValue label="profile.available-from" variant="line">
          {{ availableFrom | available($t('generic.available.past')) }}
        </OValue>
        <OValue v-if="showDistance" label="profile.distance" variant="line">{{ distanceLabel }}</OValue>
        <div class="font-weight-bold">{{ locationTypeLabel }}</div>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import { WORKSPACE_LOCATIONS } from 'library/src/models/workplace-location.enum';
import { money as moneyFilter } from 'library/src/utilities/formater/money';
import { numeric as numericFilter } from 'library/components/src/tools/filters/numeric';
import { fromArray } from 'library/components/src/tools/filters/from-array';
import { available } from 'library/components/src/tools/filters/available';

import OValue from '../value';
import OModelKeywords from '../model/keywords';
import CModelPosition from '../model/position';
import ProfileImage from '../model/image';

const prefix = 'o.profile-info';

export default {
  name: 'o-profile-info',
  components: {
    BRow,
    BCol,
    OModelKeywords,
    OValue,
    CModelPosition,
    ProfileImage,
  },
  filters: {
    fromArray,
    available,
  },
  props: {
    education: Object,
    workspace: Object,
    address: Object,
    locationItem: Object,
    distance: Number,
  },
  computed: {
    prefix: () => prefix,
    /**
     * logic to display distance information
     * @returns {boolean|boolean}
     */
    showDistance() {
      const { distance } = this;
      return distance > 0 && distance < 100;
    },

    locationTypeLabel() {
      const { distance, locationItem } = this;

      if (locationItem && distance !== null) {
        const { locationType, countryCode } = locationItem;

        switch (locationType) {
          case WORKSPACE_LOCATIONS.WORLDWIDE:
            return this.$t(`${prefix}.worldwide`);

          case WORKSPACE_LOCATIONS.COUNTRY:
            return this.$t(`${prefix}.country`);

          case WORKSPACE_LOCATIONS.LOCATIONS:
            if (countryCode) {
              return this.$t(`${prefix}.country`);
            }
        }
      }

      return null;
    },
    distanceLabel() {
      const distance = numericFilter(this.distance);
      return this.$t('fields.distance-value', { distance });
    },
    salaryLabel() {
      const value = moneyFilter(this.workspace.salaryRequest);
      return this.$t('fields.salary-value', { value });
    },
    availableFrom() {
      return this.workspace.availability.date;
    },
    picture() {
      const { education } = this;
      if (education) {
        return education.picture;
      }
      return null;
    },
    lastCareer() {
      if (this.locationItem) {
        return this.locationItem.lastCareer || {};
      }
      return {};
    },
    business() {
      const { business } = this.lastCareer;
      if (business) {
        return this.$t(`generic.business.enum.${business}`);
      }
      return null;
    },
    jobTitle() {
      const { jobTitle } = this.lastCareer;
      return jobTitle ? jobTitle : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.persona {
  @include space-horizontal($space-default);
}
</style>
