<template>
  <div v-if="hasErrors" class="o-model-validation hideable-on-empty">
    <div v-for="validation in limitedValidationErrors">
      {{ validation.message }}
      <BLink :to="validation.link.url">
        {{ validation.link.label }}
      </BLink>
    </div>
    <OToggle v-if="isLimitedPossible" class="expander" v-model="isExpanded" :label="$t(`${prefix}.expander-label`)" />
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import { profileUpload } from '@/src/sitemap';
import OToggle from '../toggle';

const prefix = 'o.model-validation';

/**
 * Component to display validation errors and current state of profile
 * also allow route user to the right route for invalid error
 */
export default {
  name: 'o-model-validation',
  components: {
    BLink,
    OToggle,
  },
  data() {
    return {
      prefix,
      isExpanded: false,
    };
  },
  props: {
    profileId: {
      type: String,
      required: true,
    },
    status: String,
    validation: Object,
    limitedCount: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    hasErrors() {
      return this.validationErrors.length > 0;
    },
    isLimitedPossible() {
      return this.validationErrors.length > this.limitedCount;
    },
    limitedValidationErrors() {
      if (this.isLimitedPossible && !this.isExpanded) {
        return this.validationErrors.slice(0, this.limitedCount);
      }
      return this.validationErrors;
    },
    validationErrors() {
      const errors = [];

      if (this.validation) {
        const { result } = this.validation;

        result.forEach(error => {
          const params = { id: this.profileId };

          // primary section key
          const section = this.$t(`${prefix}.sections.${error.section}`);

          // error message, use global
          let message = String(error.message).replace('.', '::');
          message = this.$t(`validation-errors.${message}`, { section });

          // create link and map with sitemap
          const link = {
            url: { name: 'direct-pv.profile.step-1' },
            label: this.$t(`${prefix}.route-label`, { section }),
          };

          // map route by sitemap definition
          if (profileUpload.hasOwnProperty(error.section)) {
            link.url = profileUpload[error.section].route;
          }

          // always add params like profile id
          link.url.params = params;

          errors.push({
            message,
            section,
            link,
          });
        });
      }

      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.expander {
  margin-top: $space-default;
}
</style>
