<template>
  <div class="c-person-component">
    <BRow>
      <BCol md="6" :lg="stretchColumns ? 6 : 4">
        <OInputAbstract :label="$t(`${prefix}.title`)">
          <BFormSelect
            data-identity="person-select-title"
            v-model="title"
            :state="$v.title | validationState"
            :options="titleOptions"
            :disabled="disabled"
            @change="onChange"
          />
        </OInputAbstract>
      </BCol>
      <BCol md="6" :lg="stretchColumns ? 6 : 4">
        <OInputAbstract :label="$t(`${prefix}.salutation`)">
          <BInputGroup>
            <BFormSelect
              data-identity="person-select-salutation"
              v-model="salutation"
              :state="$v.salutation | validationState"
              :options="salutationOptions"
              :disabled="disabled"
              @change="onChange"
            />
            <OMarkRequired variant="append" />
          </BInputGroup>
        </OInputAbstract>
      </BCol>
    </BRow>
    <OInputValue
      required
      icon-name="user"
      identity="person-input-lastname"
      :label="$t(`${prefix}.lastname`)"
      :description="$t(`${prefix}.lastname-description`)"
      :disabled="disabled"
      :error="getErrorMessage($v.lastname, $t('validation-errors.lastname'))"
      v-model="lastname"
      @change="onChange"
    />
    <OInputValue
      required
      icon-name="user"
      identity="person-input-firstname"
      :label="$t(`${prefix}.firstname`)"
      :disabled="disabled"
      :description="$t(`${prefix}.firstname-description`)"
      v-model="firstname"
      @change="onChange"
      :error="getErrorMessage($v.firstname, $t('validation-errors.firstname'))"
    />

    <slot name="after-content" />
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { modelMixin } from 'library/components/src/tools/mixins/model.mixin';
import { computedModel } from 'library/src/utilities/vue/computed-model';
import { asOptions } from 'library//src/utilities/as-options';
import { validationState } from 'library/components/src/tools/filters/validation-state';

import { BRow, BCol, BInputGroup, BFormSelect } from 'bootstrap-vue';
import OInputAbstract from '../../o/input/abstract';
import OInputValue from '../../o/input/value';
import SOIcon from 'library/components/src/components/so/icon';
import OMarkRequired from '../../o/mark/required';

const prefix = 'c.model-person';

export default {
  name: 'c-model-person',
  mixins: [validationMixin, modelMixin],
  components: {
    BRow,
    BCol,
    BInputGroup,
    BFormSelect,
    OMarkRequired,
    OInputAbstract,
    SOIcon,
    OInputValue,
  },
  filters: { validationState },
  props: {
    stretchColumns: Boolean,
    disabled: Boolean,
  },
  computed: {
    prefix: () => prefix,
    ...computedModel(['title', 'salutation', 'lastname', 'firstname']),
    titleOptions() {
      return asOptions(this.$t('generic.title'));
    },
    salutationOptions() {
      return asOptions(this.$t('generic.salutations'));
    },
  },
  validations: {
    salutation: {
      required,
    },
    lastname: {
      required,
    },
    firstname: {
      required,
    },
  },
};
</script>
