<template>
  <div class="o-reservation-payment">
    <SOIcon size="sm" :name="iconName" />
    <span class="label font-weight-bold">{{ $t(`${prefix}.status.${status}`, { summary }) }}</span>
    <span v-if="showDueDate" class="due-date">
      {{ $t(`${prefix}.due-date`) }}
      {{ dueDate | dateTime }}
    </span>
    <span v-if="isPayed" class="debiting">
      {{ $t(`${prefix}.debiting`) }}
      {{ debiting | dateTime }}
    </span>
    <span v-else-if="isTerminated" class="terminating">
      {{ $t(`${prefix}.terminated`) }}
      {{ terminationDate | dateTime }}
    </span>
  </div>
</template>

<script>
import { PAYMENT_STATUS } from 'library/src/models/payment.enum';
import { dateTime } from 'library/components/src/tools/filters/date-time';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.reservation-payment';

export default {
  name: 'o-reservation-payment',
  components: { SOIcon },
  filters: { dateTime },
  data() {
    return {
      prefix,
    };
  },
  props: {
    status: String,
    dueDate: [String, Date],
    debiting: [String, Date],
    terminationDate: [String, Date],
    summary: Number,
  },
  computed: {
    iconName() {
      if (this.isTerminated) {
        return 'bomb';
      } else if (this.isPayed) {
        return 'check';
      }

      return 'clock-o';
    },
    isOnPaying() {
      return [PAYMENT_STATUS.IDLE, PAYMENT_STATUS.PAYING].includes(this.status);
    },
    isPayed() {
      return this.status === PAYMENT_STATUS.PAYED;
    },
    isTerminated() {
      return this.status === PAYMENT_STATUS.TERMINATED;
    },
    showDueDate() {
      return this.dueDate && this.isOnPaying;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.o-icon {
  margin-right: $space-small;
}

.label {
  margin-right: $space-default;
}
</style>
