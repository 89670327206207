<template>
  <BRow class="o-profile-condition text-left">
    <BCol md="7">
      <OList v-bind="commissionProps" />
      <OList v-bind="intervalsProps" />
    </BCol>
    <BCol v-if="allowForbittenCompanies" md="5">
      <OList v-bind="forbiddenCompaniesProps" />
    </BCol>
  </BRow>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue';
import OList from '../list';
const prefix = 'profile.condition';

export default {
  name: 'o-profile-conditions',
  components: { OList, BRow, BCol },
  props: {
    provision: Object,
    workspace: Object,
    allowForbittenCompanies: Boolean,
  },
  computed: {
    commissionProps() {
      const { commission } = this.provision;
      return {
        headline: this.$t(`${prefix}.commission-label`),
        headlineLevel: 6,
        items: [this.$t(`${prefix}.commission`, { commission })],
      };
    },
    intervalsProps() {
      const { intervals } = this.provision;
      return {
        headline: this.$t(`${prefix}.intervals-label`),
        headlineLevel: 6,
        items: intervals.map(interval => {
          return this.$t(`intervals.${interval}`);
        }),
      };
    },
    forbiddenCompaniesProps() {
      const { forbiddenCompanies } = this.workspace;

      if (!this.allowForbittenCompanies) {
        return {};
      }

      return {
        headline: this.$t(`${prefix}.forbidden-companies-label`),
        headlineLevel: 6,
        items: Array.from(forbiddenCompanies),
        emptyLabel: this.$t(`${prefix}.forbidden-companies-empty-label`),
      };
    },
  },
};
</script>
