<template>
  <div class="o-input-password">
    <OInputValue
      required
      autocomplete="off"
      type="password"
      icon-name="key"
      :identity="identityInput"
      :disabled="disabled"
      :state="errorState"
      v-bind="abstractProps"
      v-model="password"
      @change="onChange"
    />

    <OInputValue
      v-if="withConfirm"
      required
      autocomplete="off"
      type="password"
      icon-name="key"
      :identity="identityConfirm"
      :disabled="disabled"
      :state="errorState"
      v-bind="confirmProps"
      v-model="confirm"
      @change="onChange"
    />
  </div>
</template>

<script>
import OInputAbstract from './abstract';
import OInputValue from './value';

const prefix = 'o.input-password';

export default {
  name: 'o-input-password',
  mixins: [OInputAbstract],
  components: { OInputValue },
  model: {
    event: 'change',
  },
  data() {
    return {
      password: null,
      confirm: null,
    };
  },
  props: {
    identityInput: String,
    identityConfirm: String,
    withConfirm: Boolean,
    disabled: Boolean,
    label: {
      type: String,
      default() {
        return this.$t(`${prefix}.label`);
      },
    },
    confirmLabel: {
      type: String,
      default() {
        return this.$t(`${prefix}.confirm`);
      },
    },
    confirmDescription: String,
  },
  computed: {
    confirmProps() {
      return {
        label: this.confirmLabel,
        description: this.confirmDescription,
      };
    },
  },

  methods: {
    onChange() {
      const { password, confirm, withConfirm } = this;
      let input = password;

      if (withConfirm) {
        input = {
          password,
          confirm,
        };
      }

      this.$emit('change', input);
    },
  },
};
</script>
