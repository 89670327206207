<template>
  <div class="c-model-contract-terminate">
    <BRow>
      <BCol sm="12" md="4">
        <OInputDate v-bind="$t(`${prefix}.date`)" v-model="date" />
      </BCol>
      <BCol>
        <OInputUpload v-bind="$t(`${prefix}.document`)" acceptType="document" v-model="document" />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import { computedModel } from 'library/src/utilities/vue/computed-model';
import OInputUpload from '../../o/input/upload';
import OInputDate from '../../o/input/date';

const prefix = 'c.model-contract-terminate';

export default {
  name: 'c-model-contract-terminate',
  components: { OInputDate, OInputUpload, BRow, BCol },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    value: Object,
  },
  data() {
    return {
      prefix,
    };
  },
  computed: {
    ...computedModel(['date', 'document']),
  },
};
</script>
