<template>
  <div class="o-layout-group" :class="{ 'is-active': isActive }">
    <div class="label">
      <slot name="label" />
    </div>
    <div v-if="isActive" class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'o-layout-group',
  props: {
    isActive: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

$base-color: $color-softer;
$border: 1px solid #c2cfd6; // border style bootstrap;
$padding: $space-small;

.o-layout-group {
  display: block;

  > .label {
    display: block;
    padding: $padding;
    border-color: transparent;
  }

  > .content {
    border: $border;
    padding: $padding;
    background-color: $base-color;
  }

  &.is-active {
    &:not(:last-child) {
      margin-bottom: $space-default;
    }

    > .label {
      background-color: $base-color;
      border: $border {
        bottom: none;
      }
    }
  }
}
</style>
