<template>
  <OInputOverlay :show="overlay">
    <BFormGroup class="o-input-value" :label="label" :label-class="labelClassName" :description="description">
      <BFormSelect v-if="as === 'select'" :data-identity="identity" :options="optionValues" v-model="model" />
      <BFormCheckboxGroup
        v-else-if="as === 'checkbox'"
        :data-identity="identity"
        :options="optionValues"
        :stacked="stacked"
        v-model="model"
      />
      <BFormRadioGroup v-else v-model="model" :data-identity="identity" :options="optionValues" :stacked="stacked" />
      <div v-if="error" class="text-danger small">
        {{ error }}
      </div>
    </BFormGroup>
  </OInputOverlay>
</template>

<script>
import { BFormGroup, BFormSelect, BFormCheckboxGroup, BFormRadioGroup } from 'bootstrap-vue';
import OInputOverlay from './overlay';
import { asOptions } from 'library/src/utilities/as-options';
const AS_TYPES = ['radio', 'select', 'checkbox'];

export default {
  name: 'o-input-option',
  components: {
    BFormGroup,
    BFormSelect,
    BFormCheckboxGroup,
    BFormRadioGroup,
    OInputOverlay,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: String,
    identity: String,
    description: String,
    error: String,
    required: Boolean,
    stacked: Boolean,
    value: {}, // any type
    options: {
      type: [Array, Object],
      required: true,
    },
    as: {
      type: String,
      value: AS_TYPES[0],
      validate: v => AS_TYPES.includes(v),
    },
    overlay: Boolean,
  },
  computed: {
    labelClassName() {
      return [
        'font-weight-bold',
        {
          'text-danger': this.error,
        },
      ];
    },
    optionValues() {
      return asOptions(this.options);
    },
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
  },
};
</script>
