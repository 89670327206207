<template>
  <div class="vw-login">
    <BContainer class="inner-container">
      <BRow class="row-container" no-gutters align-h="center">
        <BCol md="6">
          <SCLoginDialog class="e-card" prefix="c.login-dialog" @logged="onLogged" data-identity="login-dialog">
            <BButton data-identity="dashboard-button" slot="logged" @click="onLogged">
              {{ $t(`${prefix}.dashboard-label`) }}
            </BButton>
          </SCLoginDialog>
        </BCol>
        <BCol v-if="!isLogged" md="6">
          <BCard no-body class="e-card text-white bg-primary py-5">
            <BCardBody class="text-center">
              <h2>{{ $t(`${prefix}.title`) }}</h2>
              <p>{{ $t(`${prefix}.description`) }}</p>
              <BButton
                data-identity="registration-button"
                variant="light"
                class="mt-3 register-button"
                to="registration"
                rel="nofollow"
              >
                {{ $t(`${prefix}.register-label`) }}
                <SOIcon name="signature" size="sm" />
              </BButton>
            </BCardBody>
          </BCard>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { loginEnterRoute } from '@/src/sitemap';
import { acl } from '../../services';

import { BContainer, BRow, BCol, BButton, BCard, BCardBody } from 'bootstrap-vue';
import SCLoginDialog from 'library/components/src/components/sc/login-dialog';

import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'vw.login';

export default {
  mixins: [currentUserMixin],
  components: {
    SCLoginDialog,
    SOIcon,
    BContainer,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
  },
  data() {
    return {
      prefix,
    };
  },
  methods: {
    onLogged() {
      // redirect after logged
      if (acl.hasRedirectPath) {
        this.$router.push(acl.redirectPathOnce);
      }
      // or redirect to default page
      else {
        this.$router.push(loginEnterRoute);
      }
    },
  },
};
</script>
<style lang="scss">
@import '../../component';

.vw-login {
  @include property(height, (auto, 100%));
  @include space-vertical($space-default, padding);

  > .inner-container {
    height: 100%;
    display: flex;
    align-items: center;

    > .row-container {
      width: 100%;
    }
  }

  .e-card {
    height: 100%;
  }
}
</style>
