<template>
  <div v-if="display" :class="className" :title="title">
    <BSpinner v-if="isLoading" class="loader" small />
    <SOIcon v-else :name="iconName" size="md" />
  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue';
import { TERMINATED, CLOSED } from 'library/src/models/reservation.enum';
import APPROVAL_REQUEST from 'library/src/models/approval-request.enum';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.profile-request-status';

// simple key value map to detect reservation and approval request
const STATUS = {
  OPEN: 'open',
  RESERVATION_BY_OWNER: 'reservation-by-owner',
  RESERVATION_BY_OTHER: 'reservation-by-other',
  RESERVATION_DISALLOW: 'reservation-disallow',
  APPROVAL_REQUEST_BY_OWNER: 'approval-request-by-owner',
  APPROVAL_REQUEST_BY_COMPANY: 'approval-request-by-company',
  APPROVAL_REQUEST_DISALLOW: 'approval-request-disallow',
};

export default {
  name: 'o-profile-request-status',
  components: { SOIcon, BSpinner },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    display() {
      return this.isLoading || this.status !== STATUS.OPEN;
    },
    className() {
      let colorSet = 'light';

      switch (this.status) {
        case STATUS.RESERVATION_BY_OWNER:
        case STATUS.RESERVATION_BY_OTHER:
        case STATUS.APPROVAL_REQUEST_BY_OWNER:
        case STATUS.APPROVAL_REQUEST_BY_COMPANY:
          colorSet = 'warning';
          break;
        case STATUS.APPROVAL_REQUEST_DISALLOW:
        case STATUS.RESERVATION_DISALLOW:
          colorSet = 'danger';
          break;
      }

      return ['o-profile-request-status', `color-set-${colorSet}`];
    },
    iconName() {
      switch (this.status) {
        case STATUS.APPROVAL_REQUEST_BY_OWNER:
        case STATUS.APPROVAL_REQUEST_BY_COMPANY:
          return 'user-clock';

        case STATUS.APPROVAL_REQUEST_DISALLOW:
          return 'user-slash';
      }

      return 'user-lock';
    },
    title() {
      return this.$t(`${prefix}.title.${this.status}`);
    },
    // status problem
    requestStatus() {
      return this.$store.getters['profile/getRequestStatus'](this.id);
    },
    /**
     * @return {{
        reservationAllowed: Boolean,
        reservationStatus: String,
        approvalRequestAllowed: Boolean,
        approvalRequestStatus: String
     * }}
     */
    status() {
      const requestStatus = this.requestStatus;

      if (!requestStatus.reservationAllowed) {
        if (requestStatus.isReservationOwner) {
          return [TERMINATED, CLOSED].includes(requestStatus.reservationStatus)
            ? STATUS.RESERVATION_DISALLOW
            : STATUS.RESERVATION_BY_OWNER;
        }
        return STATUS.RESERVATION_BY_OTHER;
      }

      if (!requestStatus.approvalRequestAllowed) {
        if (requestStatus.approvalRequestStatus === APPROVAL_REQUEST.DISALLOW) {
          return STATUS.APPROVAL_REQUEST_DISALLOW;
        }

        return requestStatus.isReservationOwner ? STATUS.APPROVAL_REQUEST_BY_OWNER : STATUS.APPROVAL_REQUEST_BY_COMPANY;
      }

      return STATUS.OPEN;
    },
    isLoading() {
      return this.$store.state.profile.isOnFetchingStatus;
    },
  },
  method: {},
};
</script>
