import { DATETIMES } from 'library/src/utilities/regular-pattern';

/**
 * internal valid function
 *
 * @param v
 * @returns {*}
 */
function valid(v) {
  return this.pattern.test(v);
}

const DATE_FLEX = 'date-flex';
const DATE_FORCE = 'date-force';
const DATE_RANGE_FLEX = 'date-range-flex';

export const PATTERN_TYPES = {
  DATE_FLEX,
  DATE_FORCE,
  DATE_RANGE_FLEX,
};

/**
 *
 * @info
 *  flex define a flexible pattern, that can combine more values or variants of result
 *  a force version have to be exactly like defined
 * @param type
 * @param language
 * @returns {{pattern : RegExp}|{pattern : (RegExp|string)}|{messageKey : string, pattern : (RegExp|string)}}
 */
export function getPatternConfig(type, language = 'de') {
  switch (type) {
    case DATE_FLEX:
      return {
        pattern: DATETIMES[language].flex,
        valid,
        messageKey: 'validation-errors.date-flex',
      };

    case DATE_FORCE:
      return {
        pattern: DATETIMES[language].force,
        valid,
        messageKey: 'validation-errors.date-force',
      };

    /**
     *  date range flex definition allow single or multi (range) values
     **/
    case DATE_RANGE_FLEX:
      return {
        pattern: DATETIMES[language].flex,
        valid(v) {
          if (typeof v === 'string' && v.indexOf('-')) {
            const parts = v.split('-').map(v => v.trim());
            const length = parts.length;

            if (length < 1 || length > 2) {
              return false;
            }

            return (
              parts.filter(p => {
                return !this.pattern.test(p);
              }).length === 0
            );
          }

          return false;
        },
        messageKey: 'validation-errors.date-range-flex',
      };
      break;
  }

  return {
    pattern: /.*/,
    valid,
    messageKey: 'validation-error.pattern',
  };
}
