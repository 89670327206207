<template>
  <span class="o-model-position">
    <span v-for="key in positions" :key="key" class="position-item">
      {{ getLabel(key) }}
    </span>
  </span>
</template>

<script>
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';

/**
 * resolve and display position
 */
export default {
  name: 'o-model-position',
  mixins: [languageMixin],
  props: {
    positions: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    const { language } = this;
    this.$store.dispatch('position/fetchLanguage', { language });
  },
  methods: {
    getLabel(key) {
      return this.$store.getters['position/positionByKey'](key);
    },
  },
};
</script>

<style lang="scss">
.o-model-position {
  display: inline-block;

  > .position-item {
    display: block;
  }
}
</style>
