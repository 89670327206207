/**
 * Vacancy api provider
 */
export class VacancyApi {
  /**
   * @param httpClient
   * @param logger
   */
  constructor({ httpClient, logger }) {
    this.basePath = '/vacancy';
    this.httpClient = httpClient;
    this.logger = logger;
  }

  /**
   * create vacancy
   *
   * @param {Object} vacanyModel
   * @returns {Promise<*>}
   */
  async create(vacanyModel) {
    const response = await this.httpClient.post(`${this.basePath}/vacancy`, vacanyModel);
    return response.data.data;
  }

  /**
   * update vacancy
   *
   * @param {string} id
   * @param {Object} updateModel
   * @returns {Promise<*>}
   */
  async update(id, updateModel) {
    const response = await this.httpClient.put(`${this.basePath}/vacancy/${id}`, updateModel);
    return response.data.data;
  }

  /**
   * detele vacancy (archive)
   *
   * @param {string} id
   * @return {Promise<*>}
   */
  async deleting(id) {
    const response = await this.httpClient.delete(`${this.basePath}/vacancy/${id}`);
    return response.data.data;
  }

  /**
   * @param {string} id
   * @returns {Promise<*>}
   */
  async getById(id) {
    const response = await this.httpClient.get(`${this.basePath}/vacancy/${id}`);
    return response.data.data;
  }

  /**
   * @param {string} id
   * @returns {Promise<*>}
   */
  async getSuggesstionsById(id) {
    const response = await this.httpClient.get(`${this.basePath}/vacancy/${id}/suggestions`);
    return response.data.data;
  }

  /**
   * @param {string} id
   * @returns {Promise<*>}
   */
  async getReservationsById(id) {
    const response = await this.httpClient.get(`${this.basePath}/vacancy/${id}/reservations`);
    return response.data.data;
  }

  /**
   * search vacancies and get a pagination list
   *
   * @param { Object } filter Filtering parameter for search request
   * @returns {Promise<*>}
   */
  async search(filter = {}) {
    const response = await this.httpClient.patch(`${this.basePath}/vacancy/search`, filter);
    return response.data.data;
  }

  /**
   * search vacancies and get a pagination list
   *
   * @param { Object } filter Filtering parameter for search request
   * @returns {Promise<*>}
   */
  async owner(filter = {}) {
    const response = await this.httpClient.patch(`${this.basePath}/vacancy/owner`, filter);
    return response.data.data;
  }

  /**
   * @param {string} id
   * @returns {Promise<*>}
   */
  async updateStatus(id, status) {
    const response = await this.httpClient.put(`${this.basePath}/vacancy/${id}/status`, { status });
    return response.data.data;
  }

  /**
   * @param {String} id
   * @param {Object} suggestModel
   * @return {Promise<void>}
   */
  async suggestByOwners(id, suggestModel) {
    const response = await this.httpClient.post(`${this.basePath}/vacancy/${id}/suggest-profile`, suggestModel);
    return response.data.data;
  }

  /**
   * @param {String} id
   * @param {String} profileId
   * @return {Promise<*>}
   */
  async declineProfile(id, profileId) {
    const response = await this.httpClient.put(`${this.basePath}/vacancy/${id}/decline-profile`, { profileId });
    return response.data.data;
  }
}
