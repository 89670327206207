<template>
  <div :class="className" @click="onClick">
    <SOIcon v-if="icon" :name="icon" class="icon" size="sm" />
    <span class="label">{{ innerLabel }}</span>
  </div>
</template>
<script>
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-navigation-mobile-button',
  components: { SOIcon },
  props: {
    icon: String,
    label: String,
    to: String,
    routeId: String,
    redirect: String,
    variant: String,
    asDark: Boolean,
  },
  computed: {
    className() {
      return [
        'o-navigation-mobile-button',
        'card-header',
        'clickable',
        this.icon ? 'has-icon' : 'without-icon',
        {
          'is-active': this.isActive,
          'bg-dark': this.asDark,
        },
      ];
    },
    innerLabel() {
      return this.$t(`${this.label}`);
    },
    hasChilds() {
      return !!this.childs;
    },
  },
  methods: {
    onClick(event) {
      const name = this.redirect ? this.redirect : this.routeId;
      const currentRouteName = this.$route.name;

      this.$emit('click', event);

      // cache current route recall
      if (name && name !== currentRouteName) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.has-icon > .label {
  margin-left: $space-small;
}

.dropdown {
  vertical-align: 0;
  margin-left: $space-small;
  opacity: 0.4;
}
</style>
