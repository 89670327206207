<template>
  <OProfileCard class="c-profile-item" :data-id="id" v-bind="cardProps">
    <OProfileInfo v-if="showContent" v-bind="profile" />

    <!-- for all other, search list -->
    <template v-if="variant === VARIANTS.search">
      <OProfileBookmark slot="header-toolbar" class="header-col" :id="id" />

      <OProfileRequestStatus slot="header-toolbar" class="header-col" :id="id" />

      <OModelStatusinfo v-if="statusInfo" slot="prepend" class="statusbar" v-bind="statusInfo" />
      <BCardBody slot="append" class="conditions color-set-softer">
        <OToggle class="condition-toggle" v-model="isConditionOpen" :label="$t('profile.condition.opener-label')" />
        <OProfileConditions
          v-if="isConditionOpen"
          v-bind="profile"
          :allow-forbitten-companies="allowForbittenCompanies"
        />
      </BCardBody>
      <OProfileToolbarSearch
        v-if="showSearchToolbar"
        slot="footer"
        :id="id"
        :is-applicant-approval="isApplicantApproval"
        :relation-to="relationTo"
        @change="$emit('change', this)"
      />
    </template>

    <!-- for owner -->
    <template v-else-if="variant === VARIANTS.owner">
      <OProfileStatusLabel slot="header-toolbar" class="header-col" :status="status">
        <OOpener v-if="hasOpener" v-model="isOpen" class="opener" />
      </OProfileStatusLabel>
      <OProfileReservation
        v-if="hasReservation"
        :id="id"
        slot="prepend"
        class="statusbar"
        for-owner
        v-bind="profile.reservation"
        @change="$emit('change', this)"
      />

      <OProfileReason v-if="hasReason" slot="prepend" v-bind="profile.reason" />

      <OModelValidation v-if="isInvalid" :profile-id="id" v-bind="profile" />

      <OProfileToolbarOwner
        slot="footer"
        :id="id"
        v-bind="profile"
        @updated="$emit('change', this)"
        @deleted="$emit('change', this)"
      />
    </template>

    <!-- as reservation -->
    <template v-else-if="variant === VARIANTS.reservation">
      <OProfileReservation slot="prepend" class="statusbar" :status-info="statusInfo" v-bind="profile.reservation" />
      <BCardBody v-if="showContent" slot="append" class="conditions color-set-softer">
        <OProfileConditions v-bind="profile" />
      </BCardBody>
      <OProfileToolbarReservation slot="footer" :id="id" />
    </template>

    <!-- inside the interview mode -->
    <template v-else-if="variant === VARIANTS.interview">
      <BCardBody slot="append" class="conditions color-set-softer">
        <OProfileConditions v-bind="profile" />
      </BCardBody>
      <OProfileToolbarReservation slot="footer" :id="id" hide-open-link />
    </template>

    <!-- inside the plain mode -->
    <template v-else-if="variant === VARIANTS.plain">
      <OProfileToolbarReservation slot="footer" :id="id" hide-open-link />
    </template>
  </OProfileCard>
</template>

<script>
import PROFILE_STATUS from 'library/src/models/profile.enum';
import ACL from 'library/src/models/acl.enum';
import { hasVariant, VARIANTS } from './item.enum';
import { hasInterface } from 'library/src/utilities/has-interface';
import isObject from 'library/src/utilities/types/is-object';
import get from 'lodash.get';

import { BCardBody, BCardFooter } from 'bootstrap-vue';

import OProfileInfo from '../../o/profile/info';
import OProfileCard from '../../o/profile/card';
import OProfileConditions from '../../o/profile/conditions';
import OValue from '../../o/value';
import OProfileBookmark from '../../o/profile/bookmark';
import OToggle from '../../o/toggle';
import OProfileToolbarReservation from '../../o/profile/toolbar/reservation';
import OProfileToolbarOwner from '../../o/profile/toolbar/owner';
import OProfileToolbarSearch from '../../o/profile/toolbar/search';
import OProfileReservation from '../../o/profile/reservation';
import OProfileReason from '../../o/profile/reason';
import OModelStatusinfo from '../../o/model/statusinfo';
import OModelValidation from '../../o/model/validation';
import OList from '../../o/list';
import OOpener from '../../o/opener';
import OProfileStatusLabel from '../../o/profile/status-label';
import SOIcon from 'library/components/src/components/so/icon';
import OProfileRequestStatus from '../../o/profile/request-status';

export default {
  name: 'c-profile-item',
  components: {
    OProfileRequestStatus,
    OProfileReason,
    BCardBody,
    BCardFooter,
    OOpener,
    OList,
    OModelStatusinfo,
    OProfileReservation,
    OProfileToolbarSearch,
    OProfileToolbarOwner,
    OProfileToolbarReservation,
    OProfileStatusLabel,
    OToggle,
    OProfileCard,
    OProfileInfo,
    OProfileConditions,
    OValue,
    OProfileBookmark,
    OModelValidation,
    SOIcon,
  },
  inheritAttrs: false,
  props: {
    profile: Object,
    variant: {
      type: String,
      default: VARIANTS.owner,
      validate: hasVariant,
    },
    relationTo: Object,
  },
  data() {
    return {
      isOpen: false,
      isConditionOpen: false,
    };
  },
  computed: {
    VARIANTS: () => VARIANTS,
    id() {
      return this.profile ? this.profile._id : '';
    },
    isInvalid() {
      return [PROFILE_STATUS.INVALID, PROFILE_STATUS.VALIDATION].includes(this.status);
    },
    hasOpener() {
      if (this.isInvalid) {
        return false;
      }

      return [VARIANTS.owner, VARIANTS.reservation].includes(this.variant);
    },
    showSearchToolbar() {
      if (this.isConditionOpen) {
        return !this.statusInfo.isReservated;
      }

      return false;
    },
    showContent() {
      if (this.isInvalid) {
        return false;
      }

      if (this.hasOpener) {
        return this.isOpen;
      }
      return true;
    },
    statusInfo() {
      return this.profile.statusInfo || {};
    },
    hasReservation() {
      return isObject(this.profile.reservation) ? !!this.profile.reservation : this.statusInfo.isReservated;
    },
    hasReason() {
      return hasInterface(this.profile.reason, { note: String });
    },
    allowForbittenCompanies() {
      return this.$store.getters['auth/hasRight'](ACL.PROFILE_CONVEYING);
    },
    status() {
      return this.profile.status;
    },
    isApplicantApproval() {
      const { profile } = this;

      if (profile.locationItem) {
        return profile.locationItem.applicantApproval;
      }

      return get(profile, 'workspace.applicantApproval');
    },
    cardProps() {
      const { internalId, person, distance } = this.profile;
      const props = { person, distance, internalId };

      // convert anonym format of name
      if ([VARIANTS.search, VARIANTS.SUGGEST].includes(this.variant)) {
        props.asAnonym = true;
      }

      return props;
    },
  },
};
</script>

<style lang="scss">
@import '../../../component';

.c-profile-item {
  .conditions {
    border-top: $border-default;
  }

  .statusbar {
    border-bottom: $border-default;
  }

  .condition-toggle:not(:last-child) {
    margin-bottom: $space-default;
  }

  .opener {
    margin-left: $space-default;
  }
}
</style>
