<template>
  <footer class="c-footer">
    <BContainer class="container"> {{ $t('brand.copyright') }} v{{ version }}</BContainer>
  </footer>
</template>

<script>
import { version } from '../../../package.json';
import { BContainer } from 'bootstrap-vue';

export default {
  name: 'c-footer',
  components: {
    BContainer,
  },
  computed: {
    version: () => version,
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.c-footer {
  text-align: right;
  font-size: 80%;
  opacity: 0.6;
  @include space-vertical($space-default);
  height: 40px;
}
</style>

<style>
/* set fixed height for body, for align .c-footer */
main {
  min-height: calc(100vh - 156px);
}
</style>
