import axios from 'axios';
import env from '../environment';
import { HEADER_KEY, getSessionByStorage } from 'library/src/utilities/get-session';

export const httpClient = axios.create({
  baseURL: '/api/v1',
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Issuer: env.issuer,
    [HEADER_KEY]: getSessionByStorage('PVN-'),
  },
});
