<template>
  <BContainer class="v-profile-payment mt-4">
    <template v-if="hasProfile">
      <OProfileCard v-bind="profile">
        <OProfileInfo v-bind="profile" />
        <OProfileToolbarOwner slot="footer" :id="profileId" v-bind="profile" />
      </OProfileCard>

      <SCFetch :scope="$api.reservation" method="getStatusByProfileId" :args="[profileId]">
        <template v-slot:default="payments">
          <CReservationDemands v-bind="payments" />
        </template>
      </SCFetch>
    </template>
  </BContainer>
</template>

<script>
import { profileMixin } from '../../../tools/mixins/profile.mixin';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import SCFetch from 'library/components/src/components/sc/fetch';

import { BContainer } from 'bootstrap-vue';
import OProfileInfo from '../../o/profile/info';
import OProfileCard from '../../o/profile/card';
import OProfileToolbarOwner from '../../o/profile/toolbar/owner';
import OInputUpload from '../../o/input/upload';
import CReservationDemands from '../../c/reservation/demands';

const prefix = 'vw.profile-payment';

export default {
  mixins: [pageMixin, profileMixin, serviceMixin],
  components: {
    BContainer,
    SCFetch,
    OProfileCard,
    OProfileInfo,
    OProfileToolbarOwner,
    OInputUpload,
    CReservationDemands,
  },
  data() {
    return {
      prefix,
    };
  },
};
</script>
