<template>
  <div :class="className">
    <BContainer>
      <BRow class="align-items-center">
        <BCol v-if="!noIcon" sm="auto">
          <SOIcon :name="iconTypeName" size="sm" class="notify-icon" />
        </BCol>
        <BCol>
          <div v-if="headline" class="font-weight-bold">{{ headline }}</div>
          <div class="notify-message" v-html="message"></div>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import { VARIANTS, VARIANTS_COLOR_SET, VARIANTS_ICON } from '../../setting/notifier';
export const VARIANT_NAMES = Object.values(VARIANTS);

import { BRow, BCol, BContainer } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-notify',
  components: {
    BRow,
    BCol,
    BContainer,
    SOIcon,
  },
  props: {
    headline: String,
    message: String,
    variant: {
      type: String,
      default: VARIANTS.INFO,
      validate: v => VARIANT_NAMES.includes(v),
    },
    // lifetime in seconds
    lifetime: {
      type: Number,
      default: 3,
    },
    noIcon: Boolean,
    iconName: String,
  },
  computed: {
    className() {
      const { variant } = this;
      const classes = ['o-notify', `as-variant-${variant}`];

      const colorSet = VARIANTS_COLOR_SET[variant];
      if (colorSet) {
        classes.push(`color-set-${colorSet}`);
      }
      return classes;
    },
    iconTypeName() {
      const { noIcon, iconName, variant } = this;
      if (!noIcon) {
        // custom icon name
        if (iconName) {
          return iconName;
        }

        // by variant
        if (VARIANTS_ICON.hasOwnProperty(variant)) {
          return VARIANTS_ICON[variant];
        }
      }

      return 'exclamation-triangle'; // fallback
    },
  },
  mounted() {
    if (this.lifetime > 0) {
      this.lifetimeHandler = setTimeout(() => {
        this.$emit('expired', this);
      }, this.lifetime * 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.o-notify {
  padding: $space-small;
}

.notify-icon {
  width: 10px;
  text-align: center;
}
</style>
