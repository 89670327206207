<template>
  <BCardBody class="o-profile-reservation color-set-soft">
    <BRow>
      <BCol>
        <OValue label="profile.reservation.status">{{ statusLabel }}</OValue>
      </BCol>
      <BCol v-if="validTo" class="text-right">
        <SOTimeleft :value="validTo" />
      </BCol>
    </BRow>
    <div v-if="isTerminated && forOwner" class="button-container">
      <BButton
        variant="danger"
        v-prompt.click="{
          message: $t(`${prefix}.archive-prompt`),
          callback: { success: onClickArchive },
        }"
      >
        {{ $t(`${prefix}.archive-label`) }}
      </BButton>
      <BButton variant="primary" @click="onClickRelease">
        {{ $t(`${prefix}.release-label`) }}
      </BButton>
      <OReservationLink :id="id" variant="dark" />
    </div>
  </BCardBody>
</template>

<script>
import { TERMINATED } from 'library/src/models/reservation.enum';
import { BCardBody, BButton, BCol, BRow } from 'bootstrap-vue';
import SOTimeleft from 'library/components/src/components/so/timeleft';
import OValue from '../value';
import OReservationLink from '../reservation/link';

const prefix = 'o.profile-reservation';

export default {
  name: 'o-profile-reservation',
  components: {
    OReservationLink,
    OValue,
    BCardBody,
    BButton,
    BCol,
    BRow,
    SOTimeleft,
  },
  data() {
    return { prefix };
  },
  props: {
    id: String,
    status: String,
    created: [String, Date],
    validTo: [String, Date],
    forOwner: Boolean,
  },
  computed: {
    statusLabel() {
      const { status } = this;
      return this.$t(`profile.reservation.status-label.${status}`);
    },
    isTerminated() {
      return this.status === TERMINATED;
    },
  },
  methods: {
    onClickArchive() {
      this.$api.profile.deleting(this.id).finally(() => this.$emit('change'));
    },
    onClickRelease() {
      this.$api.reservation.deleting(this.id).finally(() => this.$emit('change'));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.button-container {
  margin-top: $space-small;
}
</style>
