<template>
  <b-button v-bind="$attrs" :class="className" type="button" variant="secondary" size="sm" @click="onClick">
    <SOIcon v-if="!labelOnly" class="icon" :name="iconName" :as-label-icon="!!label" />
    <span v-if="label" class="label">{{ label }}</span>
  </b-button>
</template>
<script>
import SOIcon from 'library/components/src/components/so/icon';
export default {
  name: 'o-toggle',
  components: { SOIcon },
  model: {
    prop: 'isOpen',
    event: 'change',
  },
  props: {
    label: String,
    variant: {
      type: String,
      default: 'dark',
    },
    isOpen: Boolean,
    labelOnly: Boolean,
  },
  computed: {
    iconName() {
      return this.isOpen ? 'angle-up' : 'angle-down';
    },
    className() {
      return [
        'o-toggle',
        {
          'has-label': this.label,
        },
      ];
    },
  },
  methods: {
    onClick() {
      this.$emit('change', !this.isOpen);
    },
  },
};
</script>
