<template>
  <div class="o-layout-subheader bg-secondary text-white py-2">
    <BContainer>
      <h2 class="my-0">
        <span v-if="pageSectionGroup" class="text-uppercase font-weight-bold">
          {{ pageSectionGroup }}
        </span>
        <span v-else-if="titleKey" class="text-uppercase font-weight-bold">
          {{ $t(titleKey) }}
        </span>

        <template v-if="pageSectionTitle">
          <span> / </span>
          <span>{{ pageSectionTitle }}</span>
        </template>
        <template v-else-if="sectionKey">
          <span> / </span>
          <span>{{ $t(sectionKey) }}</span>
        </template>
      </h2>
    </BContainer>
  </div>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

/**
 * @property {String} pageSectionGroup defined by pageMixin
 * @property {String} pageSectionTitle defined by pageMixin
 */
export default {
  // for getter only use page mixin
  mixins: [pageMixin],
  components: { BContainer },

  data() {
    // for internal use
    return {
      titleKey: null,
      sectionKey: null,
    };
  },

  beforeMount() {
    this.onPageUpdate(this.$router.currentRoute);
    this.$router.afterEach(to => this.onPageUpdate(to));
  },

  methods: {
    onPageUpdate(to) {
      // passed by meta data
      const { title, sectionGroup, sectionTitle } = to.meta;

      if (sectionTitle) {
        this.sectionKey = sectionTitle;
      } else if (title) {
        this.sectionKey = title;
      }

      if (sectionGroup) {
        this.titleKey = sectionGroup;
      }

      // always reset page params
      this.setPageParams({
        prefix: '',
        title: null, // meta
        sectionGroup: null,
        sectionTitle: null,
      });
    },
  },
};
</script>
