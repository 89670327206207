import Vue from 'vue';
import Router from 'vue-router';

import ACL from 'library/src/models/acl.enum';
import { acl } from '../services';
import VBaseContainer from '../components/c/container/base';
import VLogin from '../components/vw/login';

// routes
import registrationRoutes from './registration';
import directPvRoutes from './direct-pv';
import directPvProfileRoutes from './direct-pv-profile';
import myRoutes from './my';

import vacancyModuleRoutes from '../module/vacancy/routes';

Vue.use(Router);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: VLogin,
  },
  {
    path: '/magic/:type/:token*',
    name: 'magic',
    component: () => import(/* webpackChunkName: "magic" */ '../components/vw/magic'),
  },
  {
    name: 'direct-pv.profile.detail',
    path: '/direct-pv/profile/detail/:id',
    props: true,
    component: () => import(/* webpackChunkName: "dpv-profile-detail" */ '../components/vw/profile/detail'),
    meta: {
      printable: true,
    },
  },
  {
    name: 'access.profile',
    path: '/access/profile/:id/:token',
    props: true,
    component: () => import(/* webpackChunkName: "dpv-profile-detail" */ '../components/vw/profile/detail'),
    meta: {
      printable: true,
    },
  },
  {
    // page root
    path: '/',
    component: VBaseContainer,
    children: [
      registrationRoutes,
      directPvRoutes,
      myRoutes,
      directPvProfileRoutes,
      {
        name: 'privacy.obtaining-accept',
        path: '/privacy/obtaining/:access',
        component: () => import(/* webpackChunkName: "privacy" */ '../components/vw/privacy/obtaining-accept'),
        meta: {
          printable: true,
        },
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  routes,
});

export default router;
