export const TYPE_IMAGE = 'image';
export const TYPE_DOCUMENT = 'document';
export const TYPES = [TYPE_IMAGE, TYPE_DOCUMENT];

const prefix = 'o.input-upload';
const mb = 1024 * 1024;

export const ALLOWED_TYPES = {
  [TYPE_IMAGE]: {
    iconName: 'image',
    accept: ['image/png', 'image/jpeg'],
    maxSize: mb * 3,
    placeholderKey: `${prefix}.choose-image`,
    errorFormatKey: 'image-format-not-allowed',
    errorSizeKey: 'file-oversize',
  },
  [TYPE_DOCUMENT]: {
    iconName: 'file-pdf',
    accept: ['application/pdf', 'image/jpeg', 'image/png'],
    maxSize: mb * 10,
    placeholderKey: `${prefix}.choose-document`,
    errorFormatKey: 'file-format-not-allowed',
    errorSizeKey: 'file-oversize',
  },
};
