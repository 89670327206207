/**
 * configuration for navigation
 *
 * @property key should be unique
 * @property name the id for routes
 * @property label the i18 key for label optional otherwise name with be the label
 * @property path route path if set otherwise name with be the path
 */
import ACL from 'library/src/models/acl.enum';
import { acl } from './services';

// search item
const search = {
  routeId: 'direct-pv.search',
  icon: 'search',
  path: 'search',
};
export const ownerRouteId = 'direct-pv.owners';

// routes by section key
export const profileUpload = {
  internalId: { route: { name: 'direct-pv.profile.step-1' } },
  owner: { route: { name: 'direct-pv.profile.step-1' } },
  status: { route: { name: 'direct-pv.profile.step-1' } },
  provision: { route: { name: 'direct-pv.profile.step-1' } },
  person: { route: { name: 'direct-pv.profile.step-2' } },
  address: { route: { name: 'direct-pv.profile.step-2' } },
  informed: { route: { name: 'direct-pv.profile.step-2' } },
  personal: { route: { name: 'direct-pv.profile.step-2' } },
  addressLocation: { route: { name: 'direct-pv.profile.step-2' } },
  workspace: { route: { name: 'direct-pv.profile.step-3' } },
  assessment: { route: { name: 'direct-pv.profile.step-4' } },
  image: { route: { name: 'direct-pv.profile.step-5' } },
  education: { route: { name: 'direct-pv.profile.step-5' } },
  schools: { route: { name: 'direct-pv.profile.step-5' } },
  studies: { route: { name: 'direct-pv.profile.step-5' } },
  documents: { route: { name: 'direct-pv.profile.step-5' } },
  career: { route: { name: 'direct-pv.profile.step-6' } },
};

export const profileUploadSteps = {
  provision: 'direct-pv.profile.step-1',
  person: 'direct-pv.profile.step-2',
  workspace: 'direct-pv.profile.step-3',
  assessment: 'direct-pv.profile.step-4',
  education: 'direct-pv.profile.step-5',
  career: 'direct-pv.profile.step-6',
};

export const navigation = {
  directPv: {
    routeId: 'direct-pv',
    label: 'navigation.direct-pv-label',
    // @todo change to search page before release
    redirect: ownerRouteId, // will be changed on navigation component
    childs: {
      search: {
        ...search,
        label: 'navigation.search-label',
        right: ACL.PROFILE_SEARCHING,
      },
      create: {
        routeId: 'direct-pv.profile',
        path: 'create',
        redirect: 'direct-pv.profile.create',
        right: ACL.PROFILE_CONVEYING,
        icon: 'user-plus',
        label: 'navigation.create-profile-label',
      },
      bookmarked: {
        routeId: 'direct-pv.bookmarked',
        icon: 'bookmark',
        label: 'navigation.bookmarked-label',
        path: 'bookmarked',
        right: ACL.PROFILE_SEARCHING,
      },
      reserved: {
        routeId: 'direct-pv.reserved',
        icon: 'user-cog',
        label: 'navigation.reserved-label',
        path: 'reserved',
        right: ACL.PROFILE_SEARCHING,
        // not part if navigation
        childs: {
          open: {
            routeId: 'direct-pv.reservation',
            path: 'reservation/:id',
            label: 'profile.reservation.open-button-label',
          },
        },
      },
      owners: {
        routeId: ownerRouteId,
        icon: 'users',
        label: 'navigation.owners-label',
        path: 'owners',
        right: ACL.PROFILE_CONVEYING,
      },
    },
  },
  my: {
    routeId: 'my',
    label: 'navigation.my-label',
    redirect: 'my.account',
    childs: {
      account: {
        routeId: 'my.account',
        path: 'account',
        label: 'navigation.account-label',
        icon: 'address-card',
      },
      statistic: {
        routeId: 'my.statistic',
        path: 'statistic',
        right: ACL.PROFILE_CONVEYING,
        label: 'navigation.statistic-label',
        icon: 'chart-line',
      },
      download: {
        routeId: 'my.download',
        path: 'download',
        label: 'navigation.download-label',
        icon: 'download',
      },
      sessions: {
        routeId: 'my.sessions',
        path: 'sessions',
        label: 'navigation.sessions-label',
        icon: 'lock',
      },
      obtaining: {
        routeId: 'privacy.obtaining',
        path: 'obtaining',
        label: 'navigation.obtaining-label',
        icon: 'file-signature',
        right: ACL.PROFILE_CONVEYING,
      },
    },
  },
  logout: {
    icon: 'sign-out-alt',
    label: 'navigation.logout-label',
  },
};

// tool to resolve props of item
export function getRouteItem(item) {
  const routeItem = {
    name: item.routeId,
    path: '/',
  };

  if (item.redirect) {
    routeItem.redirect = { name: routeItem.redirect };
  }

  if (item.path) {
    routeItem.path = `${item.path}`;
  } else if (item.routeId) {
    routeItem.path = `${item.routeId}`;
  } else {
    throw new Error(`path not set for route(${item.routeId})`);
  }

  if (item.right) {
    routeItem.beforeEnter = acl.allowGuardRight(item.right, { name: 'my' });
  }

  return routeItem;
}

export function getRoute(item, params = {}) {
  if (item.redirect) {
    return { name: item.redirect, params };
  }

  return {
    name: item.routeId,
    params,
  };
}

// define enter page after logged, if not redirect exists!
export const loginEnterRoute = getRouteItem(navigation.directPv);
