<template>
  <div class="c-reservation-bar">
    <BProgress :max="reservationTotalDays">
      <BProgressBar v-for="(item, index) in processItems" :key="index" v-bind="item" />
    </BProgress>
  </div>
</template>

<script>
import { BProgress, BProgressBar } from 'bootstrap-vue';
import { each } from 'library/src/utilities/each';
import {
  FIRST,
  reservationDays,
  reservationTotalDays,
  reservationIndex,
} from 'library/src/models/reservation.enum';

export default {
  name: 'o-reservation-bar',
  components: { BProgress, BProgressBar },
  data() {
    return {
      reservationTotalDays,
    };
  },
  props: {
    status: {
      type: String,
      default: FIRST,
    },
  },
  computed: {
    processItems() {
      const { status } = this;
      const statusIndex = reservationIndex.indexOf(status);

      return each(reservationDays, (days, key, scope, index) => {
        const isActive = index === statusIndex;
        //const isPast = index > statusIndex;
        const isNext = index < statusIndex;
        const labelKey = `${key}`.toLowerCase();
        let variant = 'secondary';

        if (isNext) {
          variant = 'dark';
        } else if (isActive) {
          variant = 'primary';
        }

        return {
          label: this.$t(`profile.reservation.status-bar.${labelKey}`),
          variant,
          striped: isNext,
          animated: isActive,
          value: days,
        };
      });
    },
  },
};
</script>
