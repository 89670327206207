<template>
  <span class="o-profile-name">
    {{ label }}
  </span>
</template>

<script>
import { fullname } from 'library/components/src/tools/filters/fullname';

export default {
  name: 'o-profile-name',
  props: {
    salutation: String,
    title: String,
    firstname: String,
    lastname: String,
    asAnonym: Boolean,
    withAge: Boolean,
  },
  computed: {
    label() {
      return fullname(this, this.asAnonym);
    },
  },
};
</script>
