<template>
  <ODialog v-model="opening" v-bind="dialogProps" @success="$emit('success')" @cancel="$emit('cancel')">
    <div class="content" v-html="inner"></div>
    <slot slot="toolbar" name="toolbar" />
  </ODialog>
</template>
<script>
import { dialogMixin } from 'library/components/src/tools/mixins/dialog.mixin';
import { onlyProps } from 'library/src/utilities/only-props';
import ODialog from '../dialog';

const DIALOG_PROPS = Object.keys(dialogMixin.props);

export default {
  name: 'o-dialog-content',
  mixins: [dialogMixin],
  components: {
    ODialog,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogOverwrites: null,
      inner: null,
    };
  },
  computed: {
    dialogProps() {
      const props = onlyProps(this, DIALOG_PROPS, null, { ignoreOwn: true });
      Object.assign(props, this.dialogOverwrites);
      return props;
    },
  },
  beforeMount() {
    this.fetch();
  },
  watch: {
    open(v) {
      this.fetch();
    },
  },
  methods: {
    fetch() {
      // not fetch if loaded or not open
      if (!this.open || this.dialogOverwrites) {
        return;
      }

      this.$api.content.get(this.name).then(content => {
        this.dialogOverwrites = content;
        this.inner = content.body;
      });
    },
  },
};
</script>
