<script>
import TRACKING from 'library/src/models/tracking.enum';
import SOStatisticValue from 'library/components/src/components/so/statistic/value';
import SOStatisticDiagram from 'library/components/src/components/so/statistic/diagram';

const prefix = 'c.statistic-owner';

const COMPONENT_MAP = {
  value: SOStatisticValue,
  diagram: SOStatisticDiagram,
};

const NAME_MAP_TO_PROPS = {
  [TRACKING.OWNER_PROFILE_CREATED]: {
    variant: 'info',
    iconName: 'users',
  },
  [TRACKING.OWNER_PROFILE_RESERVATED]: {
    variant: 'success',
    iconName: 'user-cog',
  },
  [TRACKING.OWNER_PROFILE_CONTRACTED]: {
    variant: 'primary',
    iconName: 'user-check',
  },
  [TRACKING.OWNER_PROFILE_BOOKMARKED]: {
    variant: 'warning',
    iconName: 'bookmark',
  },
  [TRACKING.PROFILE_CONTRACTED]: {
    variant: 'primary',
    iconName: 'user-check',
  },
  [TRACKING.PROFILE_RESERVATED]: {
    variant: 'secondary',
    iconName: 'user-cog',
  },
  [TRACKING.PROFILE_BOOKMARKED]: {
    variant: 'warning',
    iconName: 'bookmark',
  },
  [TRACKING.PROTECTION_FEE_CREDIT]: {
    variant: 'dark',
    iconName: 'bookmark',
  },
};

export default {
  name: 'c-statistic-owner',
  props: {
    name: {
      type: String,
      required: true,
      validate: v => TRACKING.hasOwnProperty(v),
    },
    variant: {
      type: String,
      default: 'value',
      validate: v => COMPONENT_MAP.hasOwnProperty(v),
    },
  },

  beforeMount() {
    this.$store.dispatch('statistic/updateOwner');
  },

  render(createElement, context) {
    const { name, variant } = this;
    const defaultProps = NAME_MAP_TO_PROPS[name] || {};
    const component = COMPONENT_MAP[variant];
    const props = { ...defaultProps, name };

    // props by translation
    const translationKey = `${prefix}.variants.${name}.${variant}`;
    const propsByTranslation = this.$i18n.t(translationKey);

    if (typeof propsByTranslation === 'object') {
      const append = { ...propsByTranslation };

      // revert translation path for translation key property
      if (append.hasOwnProperty('headlineTranslationKey')) {
        append.headlineTranslationKey = `${translationKey}.headlineTranslationKey`;
      }

      if (append.hasOwnProperty('labelTranslationKey')) {
        append.labelTranslationKey = `${translationKey}.labelTranslationKey`;
      }

      Object.assign(props, append);
    }

    const statistic = this.$store.getters['statistic/statisticByName'](this.name);
    // add statistic values
    if (statistic) {
      if (variant === 'diagram') {
        props.diagram = statistic.months;
      } else {
        props.value = statistic.totalRange;
      }
    }

    return createElement(component, { props });
  },
};
</script>
