<script>
export default {
  name: 'o-input-overlay',
  props: {
    show: Boolean,
  },
  render(createElement, context) {
    const defaultSlot = this.$slots.default;

    if (this.show) {
      return createElement('div', { class: 'o-input-overlay' }, defaultSlot);
    }

    return createElement('div', defaultSlot);
  },
};
</script>

<style scoped lang="scss">
@import '../../../component';

.o-input-overlay {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: white;
    opacity: 0.8;
    z-index: $index-overlap;
  }
}
</style>
