<template>
  <OThumbnail v-if="pictureDocument" :class="className" :document="pictureDocument" :size="size" />
  <BImg
    v-else-if="!noPlaceholder"
    :class="className"
    thumbnail
    :width="thumbnailSize"
    :height="thumbnailSize"
    :src="imageSrc"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue';
import placeholderSrc from '../../../../public/img/placeholder/profile-neutral.jpg';
import OThumbnail from '../thumbnail';
import { MEDIUM, SIZES } from '../thumbnail-size.enum';

/**
 * this image component are for profile images only
 */
export default {
  name: 'o-model-image',
  components: { OThumbnail, BImg },
  props: {
    picture: Object,
    noPlaceholder: Boolean,
    size: {
      default: MEDIUM,
      validate: v => SIZES.includes(v),
    },
    thumbnailSize: {
      type: Number,
      default: 150,
    },
  },
  computed: {
    className() {
      return ['o-model-image'];
    },
    pictureDocument() {
      const { picture } = this;
      if (picture) {
        return picture.id;
      }
      return null;
    },
    imageSrc() {
      return placeholderSrc;
    },
  },
};
</script>

<style lang="scss" scoped>
.o-model-image {
  width: 100%;
}
</style>
