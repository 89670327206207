<template>
  <BContainer class="c-pagination">
    <BRow>
      <BCol>
        <BPagination
          v-if="showPaginatorToolbar"
          class="pagination-bar"
          pills
          :total-rows="total"
          :per-page="itemsPerPage"
          v-model="currentPage"
        />
      </BCol>
      <BCol class="text-right my-auto">
        <span class="pagination-label">{{ paginationLabel }}</span>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import { BContainer, BRow, BCol, BPagination } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'c-pagination',
  components: { SOIcon, BContainer, BRow, BCol, BPagination },
  model: {
    prop: 'pagination',
    event: 'change',
  },
  props: {
    pagination: Object,
  },
  computed: {
    currentPage: {
      get() {
        return this.params.currentPage;
      },
      set(currentPage) {
        this.pushModel({ currentPage });
      },
    },
    itemsPerPage() {
      return this.params.itemsPerPage;
    },
    showPaginatorToolbar() {
      return this.params.total > this.params.itemsPerPage;
    },
    total() {
      return this.params.total;
    },
    params() {
      return {
        currentPage: 1,
        itemsPerPage: 20,
        total: 0,
        ...this.pagination,
      };
    },
    paginationLabel() {
      return this.$t('c.pagination.page-label', this.params);
    },
  },
  methods: {
    pushModel(updates) {
      this.$emit('change', {
        ...this.params,
        ...updates,
      });
    },
  },
};
</script>

<style scoped>
.pagination-bar {
  margin-bottom: 0;
}
.pagination-label {
}
</style>
