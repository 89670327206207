<template>
  <ODialog
    v-model="isDisplayed"
    hide-header
    size="ms"
    :success-button-label="$t(`${prefix}.variant.yes-no.success`)"
    :cancel-button-label="$t(`${prefix}.variant.yes-no.cancel`)"
    @success="onSuccess"
    @cancel="onCancel"
  >
    {{ message }}
  </ODialog>
</template>
<script>
import ODialog from '../dialog';

const prefix = 'o.dialog';

export default {
  name: 'o-dialog-prompt',
  components: { ODialog },
  data() {
    return {
      isDisplayed: false,
    };
  },
  props: {
    message: String,
    callback: Function,
  },
  computed: {
    prefix: () => prefix,
  },
  watch: {
    message(value) {
      this.isDisplayed = !!value;
    },
  },
  beforeMount() {
    this.isDisplayed = !!this.message;
  },
  methods: {
    onSuccess() {
      if (this.callback) {
        this.callback(true);
      }
      this.isDisplayed = false;
    },
    onCancel() {
      if (this.callback) {
        this.callback(false);
      }
      this.isDisplayed = false;
    },
  },
};
</script>
<style scoped></style>
