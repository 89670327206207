<template>
  <div class="c-model-interview">
    <BRow>
      <BCol sm="12" md="6">
        <OInputDate
          :label="$t(`${prefix}.appointment-date-label`)"
          :readonly="readonly"
          :preset="new Date()"
          v-model="appointmentDate"
          required
        />
      </BCol>
    </BRow>
    <BRow>
      <BCol sm="12" md="6">
        <OInputValue :label="$t(`${prefix}.company-label`)" :readonly="!companyEditable" v-model="company" />
      </BCol>
      <BCol sm="12" md="6">
        <OInputValue :label="$t(`${prefix}.place-label`)" :readonly="readonly" v-model="place" />
      </BCol>
      <BCol sm="12">
        <OInputTextarea
          :label="$t(`${prefix}.note-label`)"
          :readonly="readonly"
          :placeholder="$t(`${prefix}.note-placeholder-label`)"
          v-model="note"
        />
      </BCol>
      <BCol v-if="showInformated" sm="12">
        <BFormCheckbox :value="true" :unchecked-value="false" v-model="informated">
          {{ informedLabel }}
        </BFormCheckbox>
      </BCol>
    </BRow>
  </div>
</template>
<script>
import { computedModel } from 'library/src/utilities/vue/computed-model';
import { fullname as fullnameFilter } from 'library/components/src/tools/filters/fullname';

import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue';
import OInputDate from '../../o/input/date';
import OInputValue from '../../o/input/value';
import OInputTextarea from '../../o/input/textarea';

const prefix = 'c.model-interview';

export default {
  name: 'c-model-interview',
  components: {
    OInputTextarea,
    OInputValue,
    OInputDate,
    BRow,
    BCol,
    BFormCheckbox,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Object,
    person: Object, // required if not informated
    readonly: Boolean,
  },
  data() {
    return {
      prefix,
    };
  },
  computed: {
    ...computedModel(['updated', 'appointmentDate', 'company', 'place', 'note', 'informated']),
    companyEditable() {
      const { updated, readonly } = this;
      return !readonly && !updated;
    },
    informedLabel() {
      const name = fullnameFilter(this.person);
      return this.$t(`${prefix}.informed-template`, { name });
    },
    showInformated() {
      return !this.updated || !this.informated;
    },
  },
};
</script>
