<template>
  <nav class="o-profile-toolbar-owner text-right button-container">
    <BButton
      v-show="allowEdit"
      variant="danger"
      data-identity="profile-toolbar-owner-button-delete"
      :title="$t(`${prefix}.delete-label`)"
      v-prompt.click="{
        message: $t(`${prefix}.delete-prompt`),
        callback: { success: onDelete },
      }"
    >
      <SOIcon name="trash" size="sm" />
    </BButton>

    <OProfileButtonPreview :id="id" />

    <BButton
      v-if="allowUnpublish"
      data-identity="profile-toolbar-owner-button-publishing"
      variant="warning"
      @click="onUnpublish"
    >
      {{ $t(`${prefix}.edit-label`) }}
    </BButton>

    <template v-if="allowEdit">
      <BDropdown variant="primary">
        <template v-slot:button-content>
          <SOIcon name="edit" size="sm" as-label-icon />
          {{ $t(`${prefix}.edit-label`) }}
        </template>
        <BDropdownItem v-for="(editItem, index) of editItems" :to="editItem.to" :key="`${id}-edit-${index}`"
          >{{ editItem.label }}
        </BDropdownItem>
      </BDropdown>

      <BButton
        v-if="allowPublish"
        data-identity="profile-toolbar-owner-button-publishing"
        variant="primary"
        @click="onPublish"
      >
        {{ $t(`${prefix}.publish-label`) }}
      </BButton>
    </template>

    <BButton
      v-if="allowPayment"
      variant="primary"
      :to="paymentRoute"
      data-identity="profile-toolbar-owner-button-payment"
    >
      <SOIcon name="calendar" size="sm" as-label-icon />
      {{ $t(`${prefix}.payment-label`) }}
    </BButton>
  </nav>
</template>

<script>
import { BButton, BDropdown, BDropdownItem } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import OReservationButton from '../../reservation/button';
import OProfileButtonPreview from '../button/preview';

import PROFILE_STATUS from 'library/src/models/profile.enum';
import { profileUploadSteps } from '@/src/sitemap';
import { each } from 'library/src/utilities/each';

const prefix = 'o.profile-toolbar-owner';

export default {
  name: 'o-profile-toolbar-owner',
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    OReservationButton,
    OProfileButtonPreview,
    SOIcon,
  },
  data() {
    return {
      prefix,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    reservation: Object,
  },
  computed: {
    hasReservation() {
      return !!this.reservation;
    },
    allowEdit() {
      return [PROFILE_STATUS.DRAFT, PROFILE_STATUS.INVALID, PROFILE_STATUS.VALIDATION].includes(this.status);
    },
    allowUnpublish() {
      return this.status === PROFILE_STATUS.PUBLISHED && !this.hasReservation && !this.allowPayment;
    },
    allowPublish() {
      return this.status === PROFILE_STATUS.DRAFT;
    },
    allowPayment() {
      if (this.hasReservation) {
        // @todo use enum
        return this.reservation.status === 'signed';
      }

      return false;
    },
    editItems() {
      const { id } = this;
      const items = [];

      each(profileUploadSteps, (name, key) => {
        items.push({
          to: { name, params: { id } },
          label: this.$t(`${prefix}.edit-sections.${key}`),
        });
      });

      return items;
    },
    paymentRoute() {
      const { id } = this;
      return { name: 'profile-payment', params: { id } };
    },
  },
  methods: {
    async onDelete() {
      await this.$api.profile.deleting(this.id);
      this.$emit('deleted', this.id);
    },
    async onPublish() {
      await this.$api.profile.publish(this.id);
      this.$emit('updated', this.id);
    },
    async onUnpublish() {
      await this.$api.profile.unpublish(this.id);
      this.$emit('updated', this.id);
    },
  },
};
</script>

<style lang="scss">
// fix dropdown on card footer
.o-profile-toolbar-owner {
  justify-content: flex-end;

  .b-dropdown {
    > .dropdown-menu {
      display: none;
      width: auto;
    }

    &.show {
      > .dropdown-menu {
        display: block;
      }
    }
  }

  .dropdown-menu {
    display: block;

    > li {
      display: block;
      text-align: left;
      padding: 0;
    }
  }
}
</style>
