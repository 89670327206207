<template>
  <BButton class="o-reservation-button" :disabled="disabled" :variant="variant" @click="onClick">
    <SOIcon :name="iconName" size="sm" as-label-icon />
    {{ buttonLabel }}
    <BSpinner v-if="isLoading" small />

    <ODialogContent v-model="openDialog" :name="`${variantKey}-dialog`" hide-close-button>
      <template slot="toolbar">
        <BButton variant="dark" @click="onCancel">
          {{ $t(`${prefixVariant}.cancel-label`) }}
        </BButton>
        <BButton variant="primary" @click="onSuccess">
          {{ $t(`${prefixVariant}.success-label`) }}
        </BButton>
      </template>
    </ODialogContent>
  </BButton>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue';
import ODialogContent from '../dialog/content';
import SOIcon from 'library/components/src/components/so/icon';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { resolveMessage } from 'library/src/utilities/resolve-message';
import { computedMapping } from 'library/src/utilities/vueex/computed-mapping';

const prefix = 'o.reservation-button';

export default {
  name: 'o-reservation-button',
  mixins: [serviceMixin],
  components: { SOIcon, ODialogContent, BButton, BSpinner },
  data() {
    return {
      openDialog: false,
      error: null,
      successLabel: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isApplicantApproval: Boolean,
    relationTo: Object,
    label: String,
  },
  computed: {
    prefix: () => prefix,
    prefixVariant() {
      return `${prefix}.variant.${this.variantKey}`;
    },
    variantKey() {
      return this.isApplicantApproval ? 'approval' : 'reservation';
    },
    variant() {
      return this.error ? 'danger' : 'primary';
    },
    iconName() {
      return 'user-cog';
    },
    disabled() {
      const { error, successLabel } = this;
      return !!(error || successLabel);
    },
    buttonLabel() {
      const { error, successLabel } = this;

      if (error) {
        return error;
      } else if (successLabel) {
        return successLabel;
      }

      // custom label
      if (this.label) {
        return this.label;
      }

      return this.$t(`${this.prefixVariant}.button-label`);
    },
    ...computedMapping('currentAccount', ['companyName']),
  },
  methods: {
    onClick() {
      if (!this.error) {
        this.openDialog = true;
      }
    },
    onCancel() {
      this.openDialog = false;
    },
    onSuccess() {
      this.openDialog = false;

      this.dispatchLoading('button.reservation', () =>
        this.$store
          .dispatch('profile/reservation', {
            id: this.id,
            companyName: this.companyName,
            reject: true,
            relation: this.relationTo,
          })
          .then(({ isApprovalRequest }) => {
            // only update list of request successfull
            if (isApprovalRequest) {
              this.successLabel = this.$t(`${prefix}.variant.approval.submitted-label`);
              this.$store.dispatch('notify', {
                message: this.$t(`${prefix}.notify.approval-request`),
              });
            } else {
              this.$store.dispatch('notify', {
                message: this.$t(`${prefix}.notify.reservation`),
              });
            }

            this.$emit('success', { id: this.id, isApprovalRequest });
          })
          .catch(error => {
            const message = resolveMessage(error);
            this.error = this.$t(`${prefix}.errors.${message.message}`);
            this.$emit('error', { message, error });
          })
          .finally(() => {
            this.$emit('change');
          }),
      );
    },
  },
};
</script>
