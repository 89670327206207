<template>
  <component :is="componentName" :class="className" :title="tooltipLabel">
    <SOIcon name="asterisk" size="xs" />
  </component>
</template>
<script>
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.mark-required';

const VARIANT_OVERLAP = 'overlap';
const VARIANT_INLINE = 'inline';
const VARIANT_APPEND = 'append';
const VARIANTS = [VARIANT_APPEND, VARIANT_OVERLAP, VARIANT_INLINE];

const COMPONENT_MAP = {
  [VARIANT_OVERLAP]: 'b-input-group-prepend',
  [VARIANT_APPEND]: 'b-input-group-text',
  [VARIANT_INLINE]: 'span',
};

export default {
  name: 'o-mark-required',
  components: { SOIcon },
  props: {
    tooltip: String,
    component: String,
    variant: {
      type: String,
      default: VARIANT_OVERLAP,
      validate: v => VARIANTS.includes(v),
    },
  },
  computed: {
    /**
     * define component wrapper tag or component
     *
     * @returns {string}
     */
    componentName() {
      const { component, variant } = this;

      if (component) {
        return component;
      }

      return COMPONENT_MAP[variant];
    },

    className() {
      const { variant } = this;
      return ['o-mark-required', `as-variant-${variant}`];
    },

    /**
     * define the tooltip
     *
     * @returns {*}
     */
    tooltipLabel() {
      const { tooltip } = this;
      return tooltip ? tooltip : this.$t(`${prefix}.tooltip`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

$input-height: 24px;

.o-mark-required {
  cursor: help;
  color: $color-noted;

  &.as-variant-append {
    background-color: $color-softer-touch;
  }

  &.as-variant-overlap {
    position: relative;

    > .o-icon {
      position: absolute;
      right: 0;
      top: 0;
      padding: $input-height / 2;
    }
  }

  > .o-icon {
  }
}
</style>
