<template>
  <BContainer class="vw-profile-reservation content-component">
    <CProfileItem v-if="profile" :profile="profile" :variant="profileItemVariant" />
    <SCFetch
      v-if="profile"
      no-clear-on-reload
      method="getByProfileId"
      :scope="$api.reservation"
      :args="[id]"
      ref="fetch"
      @loaded="onLoaded"
    >
      <template v-slot:default="reservation">
        <!-- interview -->
        <template v-if="isStatus(reservation, 'interview')">
          <CReservationInterview :person="person" v-bind="reservation" @refresh="$refs.fetch.$emit('refresh')">
            <OReservationToolbar
              slot="footer"
              :id="id"
              :is-extension-allowed="reservation.rights.allowExtend"
              :is-contraction-allowed="reservation.rights.allowContraction"
              @refresh="$refs.fetch.$emit('refresh')"
            />
          </CReservationInterview>
        </template>

        <!-- contraction -->
        <CReservationContract
          v-else-if="isStatus(reservation, 'contraction')"
          :payment-intervals="paymentIntervals"
          v-bind="reservation"
          @refresh="$refs.fetch.$emit('refresh')"
        />

        <!-- demands -->
        <template v-else>
          <SCFetch v-if="profile" method="getStatusByProfileId" :scope="$api.reservation" :args="[id]">
            <template v-slot:default="reservationStatus">
              <CReservationDemands v-bind="reservationStatus" />
              <CReservationTerminate
                v-if="!isStatus(reservation, [RESERVATION_STATUS.CLOSED, RESERVATION_STATUS.TERMINATED])"
                v-bind="reservation"
              />
            </template>
          </SCFetch>
        </template>
      </template>
    </SCFetch>
  </BContainer>
</template>
<script>
import { RESERVATION_STATUS, reservationIndex, SIGNED, SIGNING } from 'library/src/models/reservation.enum';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { isOneOf } from 'library/src/utilities/types';

import { INTERVIEW, PLAIN } from '../../c/profile/item.enum';
import get from 'lodash.get';

import { BContainer } from 'bootstrap-vue';
import CReservationInterview from '../../c/reservation/interview';
import CReservationContract from '../../c/reservation/contract';
import CReservationDemands from '../../c/reservation/demands';
import CReservationTerminate from '../../c/reservation/terminate';
import OReservationToolbar from '../../o/reservation/toolbar';
import CProfileItem from '../../c/profile/item';
import SCFetch from 'library/components/src/components/sc/fetch';

export default {
  mixins: [pageMixin],
  components: {
    SCFetch,
    BContainer,
    CProfileItem,
    OReservationToolbar,
    CReservationInterview,
    CReservationContract,
    CReservationDemands,
    CReservationTerminate,
  },
  data() {
    return {
      reservationStatus: 'first',
      profile: null,
    };
  },
  computed: {
    RESERVATION_STATUS: () => RESERVATION_STATUS,
    id() {
      return this.$route.params.id;
    },
    profileItemVariant() {
      return this.reservationStatus === SIGNED ? PLAIN : INTERVIEW;
    },
    person() {
      return get(this.profile, 'person', {});
    },
    paymentIntervals() {
      return get(this.profile, 'provision.intervals', []);
    },
  },
  beforeMount() {
    this.$api.profile
      .getById(this.id)
      .then(profile => (this.profile = profile))
      .catch(error => this.$store.dispatch('error', { error }));
  },
  methods: {
    isStatus(reservation, status) {
      const reservationStatus = reservation.status;

      if (status === 'interview') {
        status = reservationIndex;
      } else if (status === 'contraction') {
        return reservationStatus === SIGNING && !reservation.contraction;
      }

      return isOneOf(reservationStatus, status);
    },
    onLoaded({ status }) {
      this.reservationStatus = status;
      this.pageSectionTitle = this.$t(`profile.reservation.status-label.${status}`);
    },
  },
};
</script>
