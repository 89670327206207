export const SINGLETON_KEY = '$notifier';

export const VARIANTS = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};
export const VARIANTS_ICON = {
  [VARIANTS.ERROR]: 'exclamation-triangle',
  [VARIANTS.WARNING]: 'exclamation-triangle',
  [VARIANTS.INFO]: 'info-circle',
};
export const VARIANTS_COLOR_SET = {
  [VARIANTS.ERROR]: 'error',
  [VARIANTS.WARNING]: 'exclamation-triangle',
  [VARIANTS.INFO]: 'invert-soft',
};
