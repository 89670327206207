<template>
  <ODialogPrompt class="c-prompt" v-bind="promptProps" />
</template>
<script>
import ODialogPrompt from '../o/dialog/prompt';

export default {
  name: 'c-prompt',
  components: { ODialogPrompt },
  computed: {
    promptProps() {
      return this.$store.state.currentPrompt;
    },
  },
};
</script>
