<template>
  <div class="c-container-base">
    <CNavigation />
    <main class="main">
      <router-view></router-view>
    </main>
    <CFooter />
  </div>
</template>

<script>
import CNavigation from '../../c/navigation';
import CFooter from '../../c/footer';

export default {
  name: 'c-container-base',
  components: {
    CNavigation,
    CFooter,
  },
  props: {
    subheader: Object,
  },
};
</script>
