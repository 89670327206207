<template>
  <div class="c-profile-list">
    <BOverlay :show="isLoading" variant="transparent" rounded="sm">
      <BContainer class="items profile-list-container" :class="{ 'as-expanded': containerExpanded }">
        <template v-if="isLoading">
          <slot v-if="$slots.loading" name="loading" />
        </template>
        <template v-else-if="isEmpty">
          <slot v-if="$slots.empty" name="empty" />
          <div v-else class="empty-label">
            {{ $t(`${prefix}.empty-label`) }}
          </div>
        </template>
        <component
          v-for="item in list"
          :is="itemComponent"
          :key="item._id"
          :profile="item"
          v-bind="itemProps"
          @change="onChange"
          @action="$emit('action', $event)"
        />
      </BContainer>
      <CPagination v-if="showPagination" v-model="pagination" />
    </BOverlay>
  </div>
</template>

<script>
import { computedMapping } from 'library/src/utilities/vueex/computed-mapping';

import { BContainer, BOverlay } from 'bootstrap-vue';
import CProfileItem from './item';
import CPagination from '../pagination';

const STATUS_LOADING = 'loading';
const STATUS_LOADED = 'loaded';

const ITEMS_PER_PAGE = 8;
const PAGINATION = { itemsPerPage: ITEMS_PER_PAGE, total: 0, currentPage: 0 };

const prefix = 'c.list';

export default {
  name: 'c-profile-list',
  components: {
    BContainer,
    CPagination,
    BOverlay,
  },
  data() {
    return {
      status: null,
      list: [],
      currentPage: 1,
      pagination: { ...PAGINATION },
    };
  },
  props: {
    itemComponent: {
      default: () => CProfileItem,
    },
    itemProps: Object,
    filter: Object,
    listService: Function,
    fetchProfileStatus: Boolean,
    containerExpanded: Boolean,
  },
  computed: {
    ...computedMapping('currentAccount', ['companyName']),
    prefix: () => prefix,
    isEmpty() {
      return this.list.length < 1;
    },
    isLoading() {
      return this.status === STATUS_LOADING;
    },
    showPagination() {
      return !(this.isEmpty || this.isLoading);
    },
  },
  watch: {
    filter(v) {
      this.$logger.info('fetch by new filter', v);
      // clear pagination
      this.pagination = { ...PAGINATION };
      this.fetch();
    },
    pagination(v) {
      if (v.currentPage !== this.currentPage) {
        this.currentPage = v.currentPage;
      }
    },
    currentPage() {
      this.fetch();
    },
  },

  // mounted
  beforeMount() {
    this.fetch();
  },

  // methods
  methods: {
    onChange() {
      this.fetch();
    },
    async fetch() {
      if (this.isLoading) {
        return;
      }

      this.status = STATUS_LOADING;

      try {
        const filtering = {
          ...PAGINATION,
          ...this.pagination,
          ...this.filter,
        };

        const result = await this.listService(filtering);
        this.list = result.list;
        this.pagination = result.pagination;

        if (this.fetchProfileStatus) {
          await this.$store.dispatch('currentAccount/fetch');

          // fetch an dublicate request status
          this.$store.dispatch('profile/fetchRequestStatus', {
            items: this.list.map(i => i._id),
            companyName: this.companyName,
          });
        }

        this.$emit('list-loaded', this.list);
      } catch (error) {
        this.$store.dispatch('error', error, { root: true });
      }

      this.status = STATUS_LOADED;
    },
  },
};
</script>

<style>
.profile-list-container.as-expanded {
  padding: 0;
  max-width: none;
}
</style>
