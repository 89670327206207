<template>
  <OInputAbstract :class="className" v-bind="abstractInputProps">
    <BInputGroup>
      <BInputGroupText>
        <SOIcon name="euro-sign" size="sm" />
      </BInputGroupText>
      <BFormInput
        type="number"
        :data-identity="identity"
        :class="inputClassName"
        v-bind="inputProps"
        v-model.number.trim="model"
        :state="errorState"
      />
      <OMarkRequired v-if="required" />
    </BInputGroup>
  </OInputAbstract>
</template>

<script>
import { BInputGroup, BFormInput, BInputGroupText } from 'bootstrap-vue';
import OInputAbstract from './abstract';
import SOIcon from 'library/components/src/components/so/icon';
import OMarkRequired from '../mark/required';
import { money } from 'library/src/utilities/formater/money';

export default {
  name: 'o-input-money',
  mixins: [OInputAbstract],
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupText,
    OMarkRequired,
    SOIcon,
    OInputAbstract,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    identity: String,
    required: Boolean,
    value: {},
    noValueDescription: Boolean,
  },
  computed: {
    className() {
      return ['o-input-money', { 'is-required': this.required }];
    },
    labelClassName() {
      return [
        'font-weight-bold',
        {
          'text-danger': this.error,
        },
      ];
    },
    inputClassName() {
      return {
        'has-required-marked': this.required,
        'none-appearance': true,
      };
    },
    abstractInputProps() {
      const props = this.abstractProps;

      if (!this.noValueDescription) {
        props.description = money(this.model);
      }

      return props;
    },
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
    inputState() {
      if (!this.inputValue && this.required) {
        return {
          type: 'is-danger',
          message: this.$i18n.t('input-required'),
        };
      }
      return {
        type: 'is-primary',
        message: '',
      };
    },
    inputProps() {
      const attrs = { ...this.$attrs };

      if (attrs.type === 'number') {
        attrs.class = 'none-appearance';
      }

      return attrs;
    },
  },
};
</script>
