<template>
  <nav class="o-profile-toolbar-reservation text-right button-container">
    <OProfileButtonPreview :id="id" />
    <OReservationLink v-if="!hideOpenLink" :id="id" />
  </nav>
</template>

<script>
import OProfileButtonPreview from '../button/preview';
import OReservationLink from '../../reservation/link';
import { computedMapping } from 'library/src/utilities/vueex/computed-mapping';

export default {
  name: 'o-profile-toolbar-reservation',
  components: { OReservationLink, OProfileButtonPreview },
  props: {
    id: {
      type: String,
      required: true,
    },
    hideOpenLink: Boolean,
  },
  methods: {
    onOpen() {
      const { id } = this;
      this.$router.push({
        name: 'profile.reservation.process',
        params: { id },
      });
    },
  },
};
</script>
