<template>
  <div class="c-reservation-demands">
    <BCard no-body>
      <BCardHeader class="color-set-soft font-weight-bolder">
        {{ $t(`${prefix}.condition-headline-label`) }}
      </BCardHeader>
      <BCardBody>
        <OValue :label="`${prefix}.commission-label`" variant="line">
          {{ $t(`${prefix}.commission-template`, { commission }) }}
        </OValue>
        <OValue :label="`${prefix}.interval-label`" variant="line">
          {{ $t(`intervals.${interval}`) }}
        </OValue>
      </BCardBody>

      <BCardHeader class="color-set-soft font-weight-bolder">
        {{ $t(`${prefix}.payment-headline-label`) }}
      </BCardHeader>
      <template v-for="(payment, index) in payments">
        <BCardHeader :key="`payment-header-${index}`">
          <BRow>
            <BCol>
              {{ $t(`invoice.products.${payment.type}`) }}
            </BCol>
            <BCol sm="auto" class="font-weight-bold">
              {{ $t(`${prefix}.demands-status.${payment.status}`) }}
            </BCol>
          </BRow>
        </BCardHeader>
        <BCardBody :key="`payment-body-${index}`">
          <div class="process-steps">
            <OValues as-timeline variant="line" :values="getProcessSteps(payment)" />
          </div>

          <div v-if="canUploadInvoice(payment)" class="mt-4">
            <hr />
            <div class="font-weight-bold mb-2">
              {{ $t(`${prefix}.invoice-document-headline`) }}
            </div>
            <p>{{ getInvoiceUploadCopy(payment.creditUpload) }}</p>

            <!-- upload field -->
            <OInputUpload
              v-model="payment.creditUpload.document"
              :accept-type="TYPE_DOCUMENT"
              :additional="{ relation: payment.creditUpload.relation }"
              @change="onUploadChanged($event, payment, index)"
            />

            <BAlert variant="primary" :show="!!payment.creditUpload.document">
              <SOIcon name="info-circle" class="mr-2 align-middle" />
              {{ $t(`${prefix}.invoice-document-exists-label`) }}
            </BAlert>
          </div>
        </BCardBody>
      </template>
    </BCard>
  </div>
</template>
<script>
import { PAYMENT_STATUS } from 'library/src/models/payment.enum';
import { TYPE_DOCUMENT } from '../../o/input/upload-type.enum';
import { dateTime } from 'library/components/src/tools/filters/date-time';
import { money } from 'library/src/utilities/formater/money';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { BCard, BCardHeader, BCardBody, BRow, BCol, BAlert } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import OValue from '../../o/value';
import OValues from '../../o/values';
import OInputUpload from '../../o/input/upload';

const prefix = 'c.reservation-demands';

export default {
  name: 'c-reservation-demands',
  mixins: [serviceMixin],
  components: {
    OValue,
    OValues,
    OInputUpload,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    SOIcon,
  },
  props: {
    reservationId: String,
    commission: Number,
    interval: String,
    payments: Array,
    rights: Object,
  },
  computed: {
    prefix: () => prefix,
    TYPE_DOCUMENT: () => TYPE_DOCUMENT,
  },
  methods: {
    getInvoiceUploadCopy(creditUpload) {
      const sum = money(creditUpload.summary);
      return this.$t(`${prefix}.invoice-document-copy`, { sum });
    },
    getProcessSteps(payment) {
      return payment.process.map(item => {
        let value = this.$t(`${prefix}.process.status.idle`);

        if (item.date) {
          value = this.$t(`${prefix}.process.status.payed-template`, {
            date: dateTime(item.date),
          });
        } else if (item.status === PAYMENT_STATUS.TERMINATED) {
          value = this.$t(`${prefix}.process.status.terminated`);
        }

        return {
          label: `${prefix}.process.labels.${item.type}`, // translate by OValues
          value,
          isPast: item.isPast,
        };
      });
    },
    canUploadInvoice(payment) {
      return payment.hasOwnProperty('creditUpload');
    },
    onUploadChanged(document, payment, index) {
      this.dispatchLoading(prefix, () =>
        this.$api.reservation.creditInvoice(this.reservationId, {
          index,
          document,
        }),
      );
    },
  },
};
</script>
