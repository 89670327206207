import { acl } from '../services';
import VSubHeaderContainer from '../components/c/container/subheader';

export default {
  name: 'direct-pv.profile.create',
  path: '/direct-pv/profile',
  component: VSubHeaderContainer,
  redirect: { name: 'direct-pv.profile.step-1' },
  beforeEnter: acl.loggedGuard(true),
  props: {
    showProcessBar: true,
    progress: [
      { translationTitle: 'navigation.profile.commission-title', name: 'direct-pv.profile.step-1' },
      { translationTitle: 'navigation.profile.person-title', name: 'direct-pv.profile.step-2' },
      { translationTitle: 'navigation.profile.workspace-title', name: 'direct-pv.profile.step-3' },
      { translationTitle: 'navigation.profile.interview-title', name: 'direct-pv.profile.step-4' },
      { translationTitle: 'navigation.profile.document-title', name: 'direct-pv.profile.step-5' },
      { translationTitle: 'navigation.profile.career-title', name: 'direct-pv.profile.step-6' },
    ],
  },
  children: [
    {
      path: 'commission/:id?',
      name: 'direct-pv.profile.step-1',
      component: () => import(/* webpackChunkName: "dpv-profile" */ '../components/vw/profile/commission'),
      meta: {
        title: 'navigation.profile.commission-title',
      },
    },
    {
      path: 'person/:id',
      name: 'direct-pv.profile.step-2',
      component: () => import(/* webpackChunkName: "dpv-profile" */ '../components/vw/profile/person'),
      meta: {
        title: 'navigation.profile.person-title',
      },
    },
    {
      path: 'workspace/:id',
      name: 'direct-pv.profile.step-3',
      component: () => import(/* webpackChunkName: "dpv-profile" */ '../components/vw/profile/workspace'),
      meta: {
        title: 'navigation.profile.workspace-title',
      },
    },
    {
      path: 'assessment/:id',
      name: 'direct-pv.profile.step-4',
      component: () => import(/* webpackChunkName: "dpv-profile" */ '../components/vw/profile/assessment'),
      meta: {
        title: 'navigation.profile.interview-title',
      },
    },
    {
      path: 'upload/:id',
      name: 'direct-pv.profile.step-5',
      component: () => import(/* webpackChunkName: "dpv-profile" */ '../components/vw/profile/upload'),
      meta: {
        title: 'navigation.profile.document-title',
      },
    },
    {
      path: 'career/:id',
      name: 'direct-pv.profile.step-6',
      component: () => import(/* webpackChunkName: "dpv-profile" */ '../components/vw/profile/career'),
      meta: {
        title: 'navigation.profile.career-title',
      },
    },
  ],
};
