<template>
  <b-container class="vw-my-statistic">
    <div class="f-headline mt-4">
      {{ $t(`${prefix}.profiler-headline-label`) }}
    </div>
    <div class="text-dimmed mb-3">
      {{ $t(`${prefix}.profiler-subline-label`) }}
    </div>
    <b-row>
      <b-col v-for="name in profilerNames" :key="name" md="6">
        <CStatisticOwner class="statistic-header" variant="value" :name="name" />
        <CStatisticOwner variant="diagram" :name="name" />
      </b-col>
    </b-row>

    <div class="f-headline mt-4">
      {{ $t(`${prefix}.takers-headline-label`) }}
    </div>
    <div class="text-dimmed mb-3">
      {{ $t(`${prefix}.takers-subline-label`) }}
    </div>
    <b-row>
      <b-col v-for="name in takersNames" :key="name" md="6">
        <CStatisticOwner class="statistic-header" variant="value" :name="name" />
        <CStatisticOwner variant="diagram" :name="name" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import TRACKING from 'library/src/models/tracking.enum';

import CStatisticOwner from '../../c/statistic/owner';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

const prefix = 'vw.my-statistic';

export default {
  mixins: [pageMixin],
  components: {
    CStatisticOwner,
  },
  data() {
    return {
      prefix,
    };
  },
  computed: {
    profilerNames() {
      return [
        TRACKING.OWNER_PROFILE_CREATED,
        TRACKING.OWNER_PROFILE_BOOKMARKED,
        TRACKING.OWNER_PROFILE_RESERVATED,
        TRACKING.OWNER_PROFILE_CONTRACTED,
      ];
    },
    takersNames() {
      return [TRACKING.PROFILE_BOOKMARKED, TRACKING.PROFILE_CONTRACTED];
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`${prefix}.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';
.statistic-header {
  margin-bottom: $space-small;
}
</style>
