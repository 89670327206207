import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { logger, httpClient } from './services';
import './registerServiceWorker';
import { navigation } from './sitemap';

Vue.$navigation = navigation;

// install plugins
import './plugins/bootstrap';
import './plugins/vuelidate';
import i18n from './plugins/i18n';
import './plugins/logger';
import './plugins/api';

// install module vacancy
import vacancyPlugin from './module/vacancy/plugin';
Vue.use(vacancyPlugin, { logger, router, i18n, httpClient, store });

// always route to default login route if not match 404
router.addRoutes([
  {
    // page root
    path: '*',
    redirect: { name: 'login' },
  },
]);

// regist all filters and directives
import './tools/filters';
import './tools/directives';

// regist css
import './main.scss';
import VBaseContainer from './components/c/container/base';
import registrationRoutes from './router/registration';
import directPvRoutes from './router/direct-pv';
import myRoutes from './router/my';
import directPvProfileRoutes from './router/direct-pv-profile';

Vue.config.productionTip = false;

async function boostrap() {
  await store.dispatch('auth/authentificate');

  Vue.$i18n = i18n;

  // install router hooks
  router.beforeEach((to, from, next) => {
    // remove all page props before enter
    store.dispatch('page/reset');

    // always enter login page on root
    if (to.path === '/') {
      return next({ name: 'login' });
    }

    next();
  });

  const app = new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
  });

  Vue.$root = app;

  app.$mount('#app');

  store.$root = app;
}

boostrap();
