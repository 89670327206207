<template>
  <div :class="statusClassName">
    <span class="status">{{ statusLabel }}</span>
    <slot />
  </div>
</template>

<script>
import PROFILE_STATUS from 'library/src/models/profile.enum';

export default {
  name: 'o-profile-status-label',

  props: {
    status: String,
  },

  computed: {
    statusLabel() {
      return this.$t(`profile.status.${this.status}`);
    },
    statusClassName() {
      const classes = ['o-profile-status-label'];

      switch (this.status) {
        case PROFILE_STATUS.INVALID:
          classes.push('color-set-error');
          break;
        case PROFILE_STATUS.DRAFT:
        case PROFILE_STATUS.VALIDATION:
          classes.push('color-set-warning');
          break;
      }

      return classes;
    },
  },
};
</script>

<style scoped></style>
