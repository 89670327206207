<template>
  <OInputOverlay :show="overlay">
    <BFormGroup :label="label" label-class="font-weight-bold">
      <slot />
      <BFormInvalidFeedback v-if="error && !hideInvalidFeedback" force-show>
        {{ error }}
      </BFormInvalidFeedback>
      <BFormText v-else-if="description" :class="{ 'as-single-line-description': asSingleLineDescription }">
        {{ description }}
      </BFormText>
      <slot name="append" />
    </BFormGroup>
  </OInputOverlay>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback, BFormText } from 'bootstrap-vue';
import OInputOverlay from './overlay';

/**
 * o-input-abstract
 */
export default {
  name: 'o-input-abstract',
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    BFormText,
    OInputOverlay,
  },
  data() {
    return { internalError: null };
  },
  props: {
    label: String,
    description: String,
    error: String,
    asSingleLineDescription: Boolean,
    errorEvent: String,
    hideInvalidFeedback: Boolean,
    required: Boolean,
    overlay: Boolean,
  },
  computed: {
    abstractProps() {
      const { label, description, labelClassName, asSingleLineDescription, errorEvent, overlay } = this;
      let errorMessage = this.errorMessage;

      // disable interal error message
      if (errorEvent) {
        errorMessage = null;
      }

      return {
        label,
        description,
        overlay,
        error: errorMessage,
        labelClassName,
        asSingleLineDescription,
        required: this.required,
      };
    },

    /**
     * @return {Boolean}
     **/
    hasError() {
      return !!this.errorMessage;
    },

    /**
     * @return {String|undefined}
     **/
    errorMessage() {
      if (this.error) {
        return this.error;
      } else if (this.internalError) {
        return this.internalError;
      }
      return undefined;
    },

    /**
     * return false if error is set or null for default state
     * @returns {false|null}
     */
    errorState() {
      const { errorMessage, errorEvent } = this;

      if (errorEvent) {
        this.$emit(errorEvent, errorMessage);
      }

      return errorMessage ? false : undefined;
    },
  },
};
</script>
