/**
 * all variants/types of item
 * @type {string}
 */
export const OWNER = 'owner';
export const SEARCH = 'search';
export const RESERVATION = 'reservation';
export const INTERVIEW = 'interview';
export const PLAIN = 'plain';
export const SUGGEST = 'suggest';

export const VARIANTS = {
  // @todo refactor use uppercase enum
  owner: OWNER,
  search: SEARCH,
  reservation: RESERVATION,
  interview: INTERVIEW,
  plain: PLAIN,
  SUGGEST,
};

export function hasVariant(value) {
  return VARIANTS.hasOwnProperty(value);
}
