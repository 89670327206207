<script>
import OValue from './value';
import { dateTime } from 'library/components/src/tools/filters/date-time';
import { money } from 'library/src/utilities/formater/money';
import { VARIANTS } from './value-variants.enum';

export default {
  name: 'o-values',
  components: { OValue },
  props: {
    /**
     * @example
     * [ {label: 'test', value: 'abc' } ]
     */
    values: Array,
    variant: {
      default: VARIANTS[0],
      validate: v => VARIANTS.includes(v),
    },
    asTimeline: Boolean,
  },
  computed: {
    className() {
      return ['o-values', `as-variant-${this.variant}`, { 'as-timeline': this.asTimeline }];
    },
  },
  /**
   * rendering
   *
   * @param createElement
   * @return {VNode}
   */
  render(createElement) {
    const childs = this.values.map((item, key) => {
      let { value, transform, condition, translationKey, invalidTranslation, template, renderer, label, props } = item;

      const config = {
        ...props,
        key,
        props: {
          label,
          variant: this.variant,
        },
      };

      if (this.asTimeline && item.isPast) {
        config.class = 'is-past';
      }

      // custom transform value
      if (transform) {
        if (typeof transform === 'function') {
          value = transform(value);
        } else if (transform === 'date') {
          value = dateTime(value);
        } else if (transform === 'money') {
          value = money(value);
        }
      }

      // disable on condition function
      if (typeof condition === 'function') {
        if (!condition(item)) {
          return;
        }
      }
      // disable on flag
      else if (condition === false) {
        return;
      }

      // translation of value
      if (!value && invalidTranslation) {
        value = this.$t(invalidTranslation);
      } else if (translationKey) {
        // translate array
        if (Array.isArray(value)) {
          value = value.map(v => this.$t(`${translationKey}.${v}`));
        }
        // single value translation
        else if (value) {
          value = this.$t(`${translationKey}.${value}`);
        }
      } else if (template) {
        value = this.$t(`${template}`, { value });
      }

      // custom node rendering
      if (typeof renderer === 'function') {
        const childs = renderer(createElement, item);
        const slotContent = Array.isArray(childs) ? childs : [childs];
        return createElement(OValue, config, slotContent);
      }
      // // auto transform array
      else if (Array.isArray(value)) {
        value = value.join(', ');
      }
      // auto tranform boolean value
      else if (typeof value === 'boolean') {
        value = this.$t(`generic.${value ? 'true' : 'false'}`);
      }

      if (value) {
        return createElement(OValue, config, value);
      }
    });

    return createElement('div', { class: this.className }, childs);
  },
};
</script>

<style lang="scss">
@import '../../component';

.o-values {
  &.as-variant-column {
    > .o-value {
      margin-bottom: $space-small;
    }
  }

  &.as-timeline {
    $past-color: $color-ci;
    $color: $color-border;
    $top-offset: 0.4rem;
    $space-between: $space-small;
    $dot-size: to-rem(11px);
    $line-size: to-rem(2px);

    > .o-value {
      position: relative;
      padding: {
        left: $space-default + $dot-size;
        bottom: $space-between;
      }

      // line
      &::before {
        @include position($top-offset, null, null, $dot-size / 2 - ($line-size / 2));
        content: '';
        display: inline-block;
        border-left: 3px solid $color;
        height: 100%;
      }

      // dot
      &::after {
        @include position($top-offset, null, null, 0);
        content: '';
        display: inline-block;
        width: $dot-size;
        height: $dot-size;
        background-color: $color;
        border-radius: $dot-size;
      }

      // end line
      &:last-child {
        padding-bottom: 0;

        &::before {
          display: none;
        }
      }

      // option is past
      &.is-past {
        &::after {
          background-color: $past-color;
        }
        &::before {
          border-left-color: $past-color;
        }
      }
    }
  }
}
</style>
