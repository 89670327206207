/**
 * bridge to get current account data by current user
 *
 * @param Vue
 * @returns {{mutations : *, state : *, getters : *, actions : *, namespaced : *}}
 */

import { mapMutations, mapGetters } from 'library/src/utilities/vueex/store-mapping';

export const accountState = {
  isLoading: false,
  accountType: null,
  companyName: null,
};

export function createModule({ Vue }) {
  const namespaced = true;

  const state = {
    ...accountState,
  };

  const getters = {
    ...mapGetters(state),
  };

  const mutations = {
    ...mapMutations(state),
  };

  const actions = {
    /**
     * fetch current data
     *
     * @param commit
     * @param dispatch
     */
    fetch({ commit, dispatch, state }, options = {}) {
      if (state.isLoading || state.companyName) {
        return;
      }

      state.isLoading = true;

      return Vue.$api.account
        .get()
        .then(account => {
          commit('setAccountType', account.accountType);
          commit('setCompanyName', account.person.companyName);
        })
        .catch(error => {
          if (!options.passive) {
            dispatch('error', error, { root: true });
          }
        })
        .finally(() => {
          state.isLoading = false;
        });
    },

    /**
     * clear states
     * @param state
     */
    reset({ state }) {
      state.accountType = null;
      state.companyName = null;
    },
  };

  return {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  };
}
