<template>
  <div :class="className">
    <div v-if="hasLabel" class="value-label font-weight-bold mr-2">
      <slot v-if="hasCustomLabel" name="label" />
      <span v-else :title="labelValue">{{ labelValue }}{{ divider }}</span>
    </div>
    <div class="value-input">
      <slot v-if="$slots.default" />
      <template v-else-if="value">{{ value }}</template>
      <template v-else-if="showNotSet">{{ $t('generic.not-set') }}</template>
    </div>
  </div>
</template>

<script>
import { VARIANTS } from './value-variants.enum';

export default {
  name: 'o-value',
  props: {
    label: String,
    noTranslation: Boolean,
    divider: {
      type: String,
      default: ':',
    },
    variant: {
      type: String,
      default: VARIANTS[0],
      validate: v => VARIANTS.includes(v),
    },
    value: String,
    showNotSet: Boolean,
  },
  computed: {
    className() {
      return [
        'o-value',
        `as-variant-${this.variant}`,
        {
          'as-flexible': this.asFlexible,
        },
      ];
    },
    hasLabel() {
      return this.label || this.hasCustomLabel;
    },
    hasCustomLabel() {
      return this.$slots.label;
    },
    labelValue() {
      const { noTranslation, label } = this;

      if (noTranslation) {
        return label;
      }

      return this.$t(label);
    },
  },
};
</script>

<style lang="scss">
.o-value {
  &.as-variant-column {
    display: flex;

    > .value-label {
      min-width: 200px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.as-variant-line {
    > .value-input,
    > .value-label {
      display: inline-block;
    }
  }
}
</style>
