<template>
  <div class="o-list">
    <component v-if="headline" :is="`h${headlineLevel}`" class="font-weight-bold" v-text="headline" />
    <ul v-if="hasItems" :class="listClassName">
      <li v-for="(value, index) in items" :key="index">{{ value }}</li>
    </ul>
    <div v-else-if="emptyLabel" class="empty-label text-muted">
      {{ emptyLabel }}
    </div>
  </div>
</template>
<script>
const TYPES = ['bullet', 'order'];

export default {
  name: 'o-list',
  props: {
    headline: String,
    items: {
      type: Array,
      required: true,
    },
    headlineLevel: {
      default: 2,
      validate: v => v > 0 && v < 7,
    },
    variant: {
      type: String,
      default: TYPES[0],
      validate: v => TYPES.includes(v),
    },
    emptyLabel: String,
  },
  computed: {
    listClassName() {
      const { variant } = this;
      return `${variant}-list`;
    },
    hasItems() {
      return Array.isArray(this.items) && this.items.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.o-list {
  margin-bottom: $space-default;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
