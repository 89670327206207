<template>
  <span class="o-profile-bookmark" :title="title" @click="onClick">
    <SOIcon class="clickable highlight" name="bookmark" :variant="iconVariant" />
    <BSpinner v-if="isLoading" class="loader" />
  </span>
</template>
<script>
import { BSpinner } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.profile-bookmark';

/**
 * @todo refactor this use o.bookmark component
 */
export default {
  name: 'o-profile-bookmark',
  components: { SOIcon, BSpinner },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    iconVariant() {
      return this.isBookmarked ? 'solid' : 'outline';
    },
    title() {
      const key = this.isBookmarked ? 'title-is-bookmarked' : 'title-no-bookmarked';
      return this.$t(`${prefix}.${key}`);
    },
    isBookmarked: {
      get() {
        return this.$store.getters['profile/isBookmarkedById'](this.id);
      },
      set() {
        this.isLoading = true;
        this.$store.dispatch('profile/toggleBookmark', this.id).then(() => {
          this.isLoading = false;
          this.$emit('change', this.isBookmarked);
        });
      },
    },
  },
  methods: {
    onClick() {
      if (!this.isLoading) {
        this.isBookmarked = !this.isBookmarked;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.o-profile-bookmark {
  position: relative;
}

.loader {
  @include rect(0.5rem);
  border-width: 0.1rem;
  @include position(0, 0);
  margin: {
    right: -50%;
    top: -50%;
  }
}
</style>
