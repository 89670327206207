<template>
  <BModal
    ref="modal"
    :modal-class="modalClass"
    :size="size"
    :hide-header="hideHeader"
    :hide-footer="hideToolbar"
    header-bg-variant="primary"
    footer-bg-variant="light"
    v-model="opening"
    scrollable
    centered
    :no-close-on-backdrop="important"
    :no-close-on-escp="important"
  >
    <template slot="modal-header">
      <div class="header">
        <SOIcon v-if="iconName" class="headline-icon" :name="iconName" />
        <span class="h5 headline">{{ headline }}</span>
      </div>
      <BButtonClose v-if="!hideCloseButton" @click="onCancel" />
    </template>
    <slot />

    <slot v-if="$slots.toolbar" name="toolbar" slot="modal-footer" />
    <template v-else slot="modal-footer">
      <div v-if="rememberAnswerKey" class="as-flex-grow">
        <BFormCheckbox v-model="rememberAnswer">
          {{ $t(`${prefix}.remember-label`) }}
        </BFormCheckbox>
      </div>
      <BButton v-if="!hideSuccessButton" size="sm" variant="primary" @click="onSuccess">
        {{ successButtonLabel }}
      </BButton>
      <BButton v-if="!hideCancelButton" size="sm" variant="dark" @click="onCancel">
        {{ cancelButtonLabel }}
      </BButton>
    </template>
  </BModal>
</template>
<script>
import { BModal, BButton, BButtonClose, BFormCheckbox } from 'bootstrap-vue';
import { dialogMixin } from 'library/components/src/tools/mixins/dialog.mixin';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-dialog',
  mixins: [dialogMixin],
  components: { SOIcon, BModal, BButton, BButtonClose, BFormCheckbox },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.headline {
  margin-bottom: 0;
}

.headline-icon {
  margin-right: $space-small;
}
</style>
