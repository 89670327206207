import { acl } from '../services';
import { getRoute, getRouteItem, navigation } from '../sitemap';
import CContainerSubheader from '../components/c/container/subheader';

import VDirectPVSearch from '../components/vw/direct-pv/search';
import VDirectPVBookmarked from '../components/vw/direct-pv/bookmarked';
import VDirectPVOwners from '../components/vw/direct-pv/owners';
import VDirectPVReserved from '../components/vw/direct-pv/reserved';

import VProfileReservation from '../components/vw/profile/reservation';
import VProfilePayment from '../components/vw/profile/payment';

export default {
  ...getRouteItem(navigation.directPv),
  beforeEnter: acl.loggedGuard(true),
  redirect: getRoute(navigation.directPv),
  component: CContainerSubheader,
  children: [
    {
      ...getRouteItem(navigation.directPv.childs.search),
      component: VDirectPVSearch,
      meta: {
        title: 'vw.directPv.search.title',
        sectionGroup: 'vw.directPv.index.title',
      },
    },
    {
      ...getRouteItem(navigation.directPv.childs.create),
      redirect: getRoute(navigation.directPv.childs.create),
    },
    {
      ...getRouteItem(navigation.directPv.childs.bookmarked),
      component: VDirectPVBookmarked,
      meta: {
        title: 'vw.directPv.bookmarked.title',
        sectionGroup: 'vw.directPv.index.title',
      },
    },
    {
      ...getRouteItem(navigation.directPv.childs.reserved),
      component: VDirectPVReserved,
      meta: {
        title: 'vw.directPv.reserved.title',
        sectionGroup: 'vw.directPv.index.title',
      },
    },
    {
      ...getRouteItem(navigation.directPv.childs.owners),
      component: VDirectPVOwners,
      meta: {
        title: 'navigation.my-account.established-profiles-title',
        sectionTitle: 'vw.directPv.owners.title',
        sectionGroup: 'vw.directPv.index.title',
      },
    },
    {
      path: 'owner/:id/payment',
      name: 'profile-payment',
      component: VProfilePayment,
    },

    // extra
    {
      ...getRouteItem(navigation.directPv.childs.reserved.childs.open),
      component: VProfileReservation,
      meta: {
        title: 'vw.directPv.reservation.title',
        sectionGroup: 'vw.directPv.index.title',
      },
    },
  ],
};
