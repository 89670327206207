<template>
  <section class="c-reservation-terminate">
    <BCard no-body bg-variant="danger">
      <BCardHeader :header="$t(`profile.reservation.status-headline.terminate`)" />
      <BCardBody>
        <CModelContractTerminate v-model="termination" />

        <div class="text-right">
          <BButton
            :disabled="$v.$invalid"
            variant="dark"
            v-prompt.click="{
              message: $t(`${prefix}.prompt-message`),
              callback: { success: onClickSave },
            }"
          >
            {{ $t(`${prefix}.save-label`) }}
          </BButton>
        </div>
      </BCardBody>
    </BCard>
  </section>
</template>
<script>
import { BCard, BCardFooter, BCardBody, BCardHeader, BButton } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import OReservationToolbar from '../../o/reservation/toolbar';
import OInputOption from '../../o/input/option';
import CModelContractTerminate from '../model/contract-terminate';
import CReservationPayment from './payment';

const prefix = 'c.reservation-terminate';

export default {
  name: 'c-reservation-terminate',
  mixins: [validationMixin],
  components: {
    BCard,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BButton,
    CReservationPayment,
    CModelContractTerminate,
    OInputOption,
    OReservationToolbar,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    profileId: {
      type: String,
      required: true,
    },
    contraction: Object,
    payment: Object,
  },
  data() {
    return {
      prefix,
      termination: {},
    };
  },
  validations: {
    termination: {
      date: {
        required,
      },
      document: {
        required,
      },
    },
  },
  methods: {
    onClickSave() {
      this.$api.reservation
        .termination(this.profileId, this.termination)
        .then(() => {
          this.$store.dispatch('notify', {
            message: this.$t(`${prefix}.notify`),
          });
          this.$router.push({ name: 'direct-pv.reserved' });
        })
        .catch(e => this.$store.dispatch('error', e));
    },
  },
};
</script>
