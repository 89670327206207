export function createModule({ Vue }) {
  const namespaced = true;

  const state = {
    valid: 0,
    statistic: null,
  };

  const getters = {
    statisticByName: state => name => {
      if (state.statistic) {
        return state.statistic.find(item => item.name === name);
      }
      return undefined;
    },
  };

  const mutations = {
    setValid(state, value) {
      state.valid = value;
    },
    setStatistic(state, value) {
      state.statistic = value;
    },
  };

  const actions = {
    /**
     * reset or clear statistic
     *
     * @returns {Promise<void>}
     */
    async reset({ commit }) {
      commit('setValid', 0);
      commit('setStatistic', null);
    },

    /**
     * load last statistic data and cache it for 2 minutes
     *
     * @param commit
     * @param state
     * @param dispatch
     * @returns {Promise<void>}
     */
    async updateOwner({ commit, state, dispatch }) {
      const now = Date.now();
      if (now > state.valid) {
        const valid = new Date();
        valid.setSeconds(valid.getSeconds() + 5); // cache 5s cache
        commit('setValid', valid.getTime());
      } else {
        return false;
      }

      Vue.$logger.info('statistic/updateOwner fetching');

      Vue.$api.statistic
        .byOwner()
        .then(statistic => {
          commit('setStatistic', statistic);
        })
        .catch(error => {
          Vue.$logger.error(error);
          dispatch('error', { error }, { root: true });
        });

      return true;
    },
  };

  return {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  };
}
