<template>
  <div class="c-model-contact">
    <BRow>
      <BCol md="6" sm="12">
        <OInputValue
          as-single-line-description
          type="tel"
          identity="contact-phone"
          :label="$t(`${prefix}.phone`)"
          :description="$t(`${prefix}.phone-description`)"
          :required="phoneRequired"
          :error="getErrorMessage($v.phone, $t('validation-errors.phone'))"
          v-model="phone"
          @change="onChange"
        />
      </BCol>
      <BCol md="6" sm="12">
        <OInputValue
          as-single-line-description
          type="tel"
          identity="contact-additional-phone"
          :overlay="additionalPhoneOverlay"
          :label="$t(`${prefix}.additional-phone`)"
          :description="$t(`${prefix}.additional-phone-description`)"
          :error="getErrorMessage($v.additionalPhone, $t('validation-errors.additional-phone'))"
          v-model="additionalPhone"
          @change="onChange"
        />
      </BCol>
    </BRow>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue';
import { modelMixin } from 'library/components/src/tools/mixins/model.mixin';
import { computedModel } from 'library/src/utilities/vue/computed-model';
import { validationMixin } from 'vuelidate';
import { helpers, requiredIf } from 'vuelidate/lib/validators';
import OInputValue from '../../o/input/value';

const PATTERN = require('library/src/utilities/regular-pattern');
const phone = helpers.regex('phone', PATTERN.PHONE);
const prefix = 'c.model-contact';

export default {
  name: 'c-model-contact',
  mixins: [validationMixin, modelMixin],
  components: { OInputValue, BRow, BCol },
  props: {
    phoneRequired: Boolean,
    additionalPhoneOverlay: Boolean,
  },
  computed: {
    prefix: () => prefix,
    p: () => phone,
    ...computedModel(['phone', 'additionalPhone']),
  },
  validations: {
    phone: {
      required: requiredIf(function (comp) {
        return comp.phoneRequired;
      }),
      phone,
    },
    additionalPhone: {
      phone,
    },
  },
};
</script>
