<template>
  <OInputAbstract
    class="o-input-select-position"
    v-bind="abstractProps"
    :label="$t(`${prefix}.label`)"
    :description="canInsert ? description : undefined"
  >
    <SOTags is-removable item-variant="flow-row" :items="getItems()" @item-remove-clicked="onRemoveItem" />
    <BFormInput
      v-if="canInsert"
      autofocus
      autocomplete="off"
      data-identity="input-select-position"
      :state="validationState"
      v-model="input"
      @keyup="onChange"
    />
    <OSuggestions :items="options" @item-clicked="onOptionClicked" />
  </OInputAbstract>
</template>

<script>
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';
import { BFormInput } from 'bootstrap-vue';
import SOTags from 'library/components/src/components/so/tags';
import OInputAbstract from '../abstract';
import OSuggestions from '../../suggestions';

const prefix = 'o.input-select-position';

export default {
  name: 'o-input-select-position',
  mixins: [languageMixin, OInputAbstract],
  components: { OSuggestions, SOTags, OInputAbstract, BFormInput },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      isTouched: false,
      input: null,
      options: null,
      model: [],
    };
  },
  props: {
    value: Array,
    description: {
      type: String,
      default() {
        return this.$t(`${prefix}.description`);
      },
    },
    max: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    prefix: () => prefix,
    canInsert() {
      return this.model.length < this.max;
    },
    validationState() {
      // not invalid if have one entry
      if (this.model.length > 0) {
        return null;
      }

      return this.isTouched ? false : null;
    },
  },
  watch: {
    value(v) {
      this.mapValue(v);
    },
  },
  beforeMount() {
    const { language } = this;
    this.$store.dispatch('position/fetchLanguage', { language });

    this.mapValue(this.value);
  },
  methods: {
    mapValue(v) {
      this.model = Array.isArray(v) ? v : [];
    },
    onOptionClicked(positionItem) {
      this.model.push(positionItem.key);
      this.input = '';
      this.options = [];
      this.$emit('change', this.model);
    },
    onChange() {
      const { input } = this;
      this.isTouched = true;

      if (input) {
        this.options = this.$store.getters['position/findPositionByInputFilter'](input, this.model);
      } else {
        this.options = [];
      }
    },
    onRemoveItem({ key }) {
      this.model = this.model.filter(modelKey => key !== modelKey);
      this.$emit('change', this.model);
    },
    getItems() {
      return this.model.map(key => {
        const label = this.$store.getters['position/positionByKey'](key);

        return {
          label,
          key,
        };
      });
    },
  },
};
</script>
