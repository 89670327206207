<template>
  <div class="o-input-tags-abstract">
    <SOTags :item-variant="tagVariant" v-model="model" is-editable is-removable @item-edit-clicked="onEditItem" />
    <div class="flex-input">
      <BInputGroup>
        <BFormInput ref="input" v-bind="inputProps" v-model="input" @keyup="onChange" @blur="onBlur" />
        <OMarkRequired v-if="required" />
      </BInputGroup>
      <OSuggestions :items="options" @item-clicked="insertTag" />
    </div>
  </div>
</template>

<script>
import { delay } from 'library/src/utilities/delay';
import { BFormInput, BInputGroup } from 'bootstrap-vue';
import SOTags from 'library/components/src/components/so/tags';
import OSuggestions from '../../suggestions';
import OMarkRequired from '../../mark/required';

const prefix = 'o.input-tags-abstract';
const INSERT_KEY_CODES = [
  188, // for comma
  13, // enter
  // tab or blue
];

export default {
  name: 'o-input-tags-abstract',
  components: {
    BFormInput,
    OSuggestions,
    SOTags,
    OMarkRequired,
    BInputGroup,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      options: null,
      input: null,
      isLoading: false,
    };
  },
  props: {
    identity: String,
    placeholder: String,
    value: Array,
    createNewKeyword: Boolean,
    createTag: Function,
    loadOptions: Function,
    // @see tag variants
    tagVariant: String,
    maxlength: [String, Number],
    required: Boolean,
  },
  computed: {
    prefix: () => prefix,
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
    /**
     * define props
     **/
    inputProps() {
      const { identity, placeholder, maxlength } = this;
      const props = {
        autocomplete: 'off',
        'data-identity': identity,
        placeholder,
      };

      if (maxlength > 0) {
        props.maxlength = maxlength;
      }

      return props;
    },
  },
  methods: {
    /**
     * edit item event, will be called after click on tag
     **/
    onEditItem(item) {
      this.input = item;
      this.$refs.input.focus();
    },

    /**
     *  blur event
     **/
    onBlur() {
      const { input, isLoading } = this;

      //  ignore empty
      if (!input || isLoading) {
        return;
      }

      delay(200).then(() => {
        this.isLoading = true;
        this.insertTag(this.input).finally(() => (this.isLoading = false));
      });
    },
    onChange(event) {
      if (this.isLoading) {
        return;
      }

      const { input } = this;
      const keyCode = event.keyCode;

      // show reaction if any input exists
      if (input) {
        // insert new entry on match last keyCode
        if (INSERT_KEY_CODES.includes(keyCode)) {
          this.isLoading = true;

          this.insertTag(input).finally(() => (this.isLoading = false));
        }
      }

      this.fetchOptions();
    },

    /**
     * insert tag
     **/
    async insertTag(input) {
      if (!input) {
        return; // return void
      }

      this.input = null; // clear input

      const items = Array.isArray(this.value) ? this.value : [];
      input = input.replace(',', '').trim(',');

      if (!input) {
        return; // invalid input
      }

      // ignore duplicate
      if (items.includes(input)) {
        return;
      }

      // use first option if exists
      const item = this.options ? this.options[0] : null;

      // get first suggestion item and check if compare
      if (item && item === input) {
        if (!items.includes(item)) {
          input = item;
        }
      }
      // create new
      else if (this.createNewKeyword) {
        if (this.createTag) {
          await this.createTag(input);
        }
      }
      // not allow to create new
      else {
        this.$logger.warn(`Not allowed create new tag ${input}`);
        return;
      }

      items.push(input);
      this.model = items;

      // clear input
      this.options = [];
      this.input = '';

      // focus on input
      this.$refs.input.focus();
    },

    /**
     * fetch new list for options
     */
    async fetchOptions() {
      const { input, loadOptions } = this;

      if (typeof loadOptions !== 'function') {
        return;
      }

      try {
        this.isLoading = true;
        const list = await loadOptions(input);

        if (this.input) {
          this.options = list;
        }
      } catch (error) {
        this.options = null;
        this.$logger.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.flex-input {
  display: block;
}
</style>
