<template>
  <div class="c-model-company">
    <OInputValue
      required
      icon-name="building"
      identity="company-input-companyName"
      :label="$t(`${prefix}.company-name.label`)"
      :description="$t(`${prefix}.company-name.description`)"
      :error="getErrorMessage($v.companyName, $t('validation-errors.company-name'))"
      v-model="companyName"
      @change="onChange"
    />
    <OInputValue
      required
      icon-name="user"
      identity="company-input-jobTitle"
      :label="$t(`${prefix}.job-title.label`)"
      :description="$t(`${prefix}.job-title.description`)"
      :error="getErrorMessage($v.jobTitle, $t('validation-errors.job-title'))"
      v-model="jobTitle"
      @change="onChange"
    />
    <OInputValue
      required
      identity="company-input-vatId"
      icon-name="money-check-alt"
      :label="$t(`${prefix}.vat.label`)"
      :description="$t(`${prefix}.vat.description`)"
      :error="getErrorMessage($v.vatId, $t('validation-errors.vat-id'))"
      v-model="vatId"
      @change="onChange"
    />
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, helpers } from 'vuelidate/lib/validators';
import { modelMixin } from 'library/components/src/tools/mixins/model.mixin';
import { computedModel } from 'library/src/utilities/vue/computed-model';
import OInputValue from '../../o/input/value';

const PATTERN = require('library/src/utilities/regular-pattern');

const prefix = 'c.model-company';

export default {
  name: 'c-model-company',
  mixins: [validationMixin, modelMixin],
  components: { OInputValue },
  computed: {
    prefix: () => prefix,
    ...computedModel(['companyName', 'vatId', 'jobTitle']),
  },
  validations: {
    companyName: {
      required,
    },
    vatId: {
      vatId: helpers.regex('vat-id', PATTERN.VATID),
      required,
    },
    jobTitle: {
      required,
    },
  },
};
</script>
