import Vue from 'vue';
import store from '../../store';

/**
 * small prompt handling
 */
Vue.directive('prompt', {
  // When the bound element is inserted into the DOM...
  inserted(el, binging, vnode) {
    if (binging.modifiers.click) {
      el.addEventListener('click', event => {
        event.preventDefault();
        store.dispatch('prompt', binging.value);
      });
    }
  },
});
