<template>
  <div class="c-reservation-toolbar">
    <BRow>
      <BCol sm="12" md="auto">
        <BButton
          variant="danger"
          :title="$t(`${prefix}.release-profile-label`)"
          v-prompt.click="{
            message: $t(`${prefix}.release-profile-message`),
            callback: { success: onClickReleaseProfile },
          }"
        >
          <SOIcon name="trash" size="sm" />
        </BButton>
      </BCol>
      <BCol class="text-right">
        <div class="button-container">
          <BButton
            v-if="isExtensionAllowed"
            variant="primary"
            v-prompt.click="{
              message: $t(`${prefix}.extend-reservation-message`),
              callback: { success: onClickExtendReservation },
            }"
          >
            {{ $t(`${prefix}.extend-reservation-label`) }}
          </BButton>
          <BButton
            v-if="isContractionAllowed"
            variant="primary"
            v-prompt.click="{
              message: $t(`${prefix}.contract-message`),
              callback: { success: onClickContractReport },
            }"
          >
            <SOIcon name="user-check" size="sm" as-label-icon />
            {{ $t(`${prefix}.contract-label`) }}
          </BButton>
        </div>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.reservation-toolbar';

/**
 * @event refresh called after action
 */
export default {
  name: 'o-reservation-toolbar',
  components: {
    SOIcon,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isExtensionAllowed: Boolean,
    isContractionAllowed: Boolean,
  },
  computed: {
    prefix: () => prefix,
  },
  methods: {
    /**
     * release profile
     */
    onClickReleaseProfile() {
      this.isLoading = true;
      this.$api.reservation.deleting(this.id).finally(() => {
        this.isLoading = false;
        this.$router.push({ name: 'direct-pv.reserved' });
      });
    },
    /**
     * extend reservation
     */
    onClickExtendReservation() {
      this.isLoading = true;
      this.$api.reservation.extending(this.id).finally(() => {
        this.isLoading = false;
        this.$emit('refresh');
      });
    },
    onClickContractReport() {
      this.isLoading = true;
      this.$api.reservation.contracting(this.id).finally(() => {
        this.isLoading = false;
        this.$emit('refresh');
      });
    },
  },
};
</script>
