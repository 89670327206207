<template>
  <BCardBody v-if="hasInformation" class="o-profile-statusinfo color-set-soft">
    <BRow>
      <BCol v-if="isOwners">{{ $t(`${prefix}.owner`) }}</BCol>
      <BCol v-if="isReservated">
        {{ isReservatedByOwner ? $t(`${prefix}.reservated-by-owner`) : $t(`${prefix}.reservated`) }}
      </BCol>
    </BRow>
  </BCardBody>
</template>

<script>
import { BCardBody, BRow, BCol } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import OValue from '../value';
const prefix = 'o.model-statusinfo';

export default {
  name: 'o-model-statusinfo',
  components: { SOIcon, OValue, BCardBody, BRow, BCol },
  data() {
    return { prefix };
  },
  props: {
    isOwners: Boolean,
    isReservated: Boolean,
    isReservatedByOwner: Boolean,
  },
  computed: {
    hasInformation() {
      return [this.isOwners, this.isReservated, this.isReservatedByOwner].includes(true);
    },
  },
};
</script>
