<template>
  <div class="c-notifier">
    <ONotify v-if="notify" v-bind="notify" @expired="notify = null" />
  </div>
</template>

<script>
import types from 'library/src/utilities/types';
import { SINGLETON_KEY } from '../../setting/notifier';
import ONotify from '../o/notify';

export default {
  name: 'c-notifier',
  components: {
    ONotify,
  },
  data() {
    return {
      notify: null,
    };
  },
  methods: {
    addNotification(config) {
      if (!types.object(config)) {
        throw new Error('Invalid configuration');
      }

      if (types.empty(config.message)) {
        throw new Error('Message required');
      }

      this.notify = null;
      this.$nextTick(() => {
        this.notify = config;
      });
    },
  },
  mounted() {
    if (this.$store[SINGLETON_KEY]) {
      throw new Error('Notifier already append to your app');
    }

    this.$store[SINGLETON_KEY] = this;
  },
  beforeDestroy() {
    if (this.$store[SINGLETON_KEY] === this) {
      delete this.$store[SINGLETON_KEY];
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.c-notifier {
  @include position(null, 0, 0, 0, fixed);
  z-index: $index-overlay;
}
</style>
