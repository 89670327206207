<template>
  <nav class="o-profile-toolbar-search text-right button-container">
    <OReservationButton
      :id="id"
      :is-applicant-approval="isApplicantApproval"
      :relation-to="relationTo"
      @change="$emit('change')"
    />
  </nav>
</template>

<script>
import OReservationButton from '../../reservation/button';

export default {
  name: 'o-profile-toolbar-search',
  components: { OReservationButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    isApplicantApproval: Boolean,
    relationTo: Object,
  },
};
</script>
