<template>
  <OInputAbstract :class="className" v-bind="abstractProps">
    <BInputGroup>
      <slot name="before" />
      <BInputGroupText v-if="iconName">
        <SOIcon :name="iconName" size="sm" />
      </BInputGroupText>
      <BFormInput
        :class="{
          'has-required-marked': required,
          'none-appearance': noneAppearance,
        }"
        :data-identity="identity"
        :state="errorState"
        v-bind="inputProps"
        v-model="model"
        @keyup="$emit('keyup', $event)"
      />
      <OMarkRequired v-if="required" />
    </BInputGroup>
    <slot name="append" />
  </OInputAbstract>
</template>

<script>
import { getPatternConfig } from './pattern.enum';
import { BInputGroup, BInputGroupText, BFormInput } from 'bootstrap-vue';
import OInputAbstract from './abstract';
import SOIcon from 'library/components/src/components/so/icon';
import OMarkRequired from '../mark/required';

export default {
  name: 'o-input-value',
  mixins: [OInputAbstract],
  inheritAttrs: false,
  components: {
    OMarkRequired,
    SOIcon,
    OInputAbstract,
    BInputGroup,
    BInputGroupText,
    BFormInput,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    iconName: String,
    identity: String,
    required: Boolean,
    noneAppearance: Boolean,
    pattern: String,
    language: {
      type: String,
      default: 'de',
    },
    value: {},
    overlay: Boolean,
  },
  computed: {
    className() {
      return ['o-input-value', { 'is-required': this.required }];
    },
    labelClassName() {
      return [
        'font-weight-bold',
        {
          'text-danger': this.error,
        },
      ];
    },
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
    inputState() {
      if (!this.inputValue && this.required) {
        return {
          type: 'is-danger',
          message: this.$i18n.t('input-required'),
        };
      }
      return {
        type: 'is-primary',
        message: '',
      };
    },
    inputProps() {
      const attrs = { ...this.$attrs };

      if (attrs.type === 'number') {
        attrs.class = 'none-appearance';
      }

      return attrs;
    },
    patternConfig() {
      if (this.pattern) {
        return getPatternConfig(this.pattern, this.language);
      }
      return null;
    },
  },
  watch: {
    value() {
      const { patternConfig, value, required } = this;

      if (patternConfig) {
        if (!value && !required) {
          this.internalError = null;
        } else if (patternConfig.valid(value)) {
          this.internalError = null;
        } else {
          this.internalError = this.$t(patternConfig.messageKey);
        }
      }
    },
  },
};
</script>
