<template>
  <div class="c-reservation-payment">
    <OReservationPayment v-for="invoice in invoices" :key="invoice.type" v-bind="invoice" />
  </div>
</template>
<script>
import OReservationPayment from '../../o/reservation/payment';
const prefix = 'c.reservation-payment';

export default {
  name: 'c-reservation-payment',
  components: { OReservationPayment },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      prefix,
    };
  },
};
</script>
