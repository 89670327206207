<template>
  <div class="v-account">
    <!-- top navigation -->
    <div class="account-navigation bg-light alignable-top">
      <BContainer>
        <BRow>
          <BCol>
            <div class="button-container">
              <BButton variant="primary" size="sm" :to="{ name: 'my.account.edit' }">
                {{ $t(`${prefix}.edit-button-label`) }}
              </BButton>
              <BButton variant="primary" size="sm" :to="{ name: 'my.account.password' }">
                {{ $t(`${prefix}.password-button-label`) }}
              </BButton>
            </div>
          </BCol>
          <BCol class="text-right font-xs font-dimmed">
            <SOIcon name="info-circle" size="sm" as-label-icon />
            {{ $t(`${prefix}.menu-copy`) }}
          </BCol>
        </BRow>
      </BContainer>
    </div>

    <BContainer class="mt-4">
      <BRow v-if="account">
        <BCol sm="12" lg="6">
          <BCard no-body>
            <BCardHeader>
              <OProfileName class="account-name" v-bind="account.person" />
            </BCardHeader>
            <BCardBody>
              <OValues :values="personValues" variant="line" />
            </BCardBody>
          </BCard>
        </BCol>

        <BCol sm="12" lg="6">
          <BCard :header="$t(`${prefix}.address-label`)">
            <OValues :values="addressValues" variant="line" />
          </BCard>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import { each } from 'library/src/utilities/each';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { BButton, BCard, BCardBody, BCardHeader, BCol, BContainer, BRow } from 'bootstrap-vue';
import OValues from '../../o/values';
import OProfileName from '../../o/profile/name';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'vw.my-dashboard';

export default {
  mixins: [pageMixin],
  components: {
    OValues,
    OProfileName,
    SOIcon,
    BContainer,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
  },
  data() {
    return {
      prefix,
      account: null,
    };
  },
  computed: {
    personValues() {
      const { companyName, jobTitle, vatId, phone, additionalPhone } = this.account.person;

      return each(
        {
          company: `${companyName},  ${jobTitle}`,
          phone,
          'additional-phone': additionalPhone,
          vatId,
        },
        (value, key) => {
          // translate salutation value
          if (key === 'language') {
            value = this.$t(`language.${value}`);
          }

          return { label: `fields.${key.toLowerCase()}`, value };
        },
      );
    },
    addressValues() {
      const { street, locality, zip, countryCode } = this.account.address;
      return each(
        {
          street,
          locality: `${zip} ${locality}`,
          countryCode,
        },
        (value, key) => {
          if (key === 'countryCode') {
            value = this.$t(`countries.${value}`);
          }

          return { label: `fields.${key.toLowerCase()}`, value };
        },
      );
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`${prefix}.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    this.$api.account
      .get()
      .then(account => (this.account = account))
      .catch(error => this.$store.dispatch('error', { error }));
  },
};
</script>

<style lang="scss" scoped>
.account-name {
  font-weight: bold;
  margin: 0;
}
</style>
