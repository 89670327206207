<template>
  <div class="c-list-bookmarked content-component">
    <CProfileList fetch-profile-status :list-service="listService" :item-props="itemProps" />
  </div>
</template>

<script>
import CProfileList from '../../c/profile/list';

export default {
  name: 'c-list-bookmarked',
  components: {
    CProfileList,
  },
  computed: {
    listService() {
      return this.$api.bookmark.pagingByOwner.bind(this.$api.bookmark);
    },
    itemProps() {
      return {
        variant: 'search',
      };
    },
  },
};
</script>
