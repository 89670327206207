import ACL from 'library/src/models/acl.enum';

export default {
  routeId: 'vacancy',
  label: 'navigation.vacancy-label',
  right: ACL.VACANY,
  path: '/vacancy',
  childs: {
    search: {
      routeId: 'vacancy.search',
      path: 'search',
      icon: 'search',
      label: 'navigation.search-vacancy-label',
      right: ACL.PROFILE_CONVEYING,
    },
    create: {
      routeId: 'vacancy.create',
      path: 'create',
      icon: 'id-card-alt',
      label: 'navigation.create-vacancy-label',
      right: ACL.VACANY,
    },
    /*
    bookmark: {
      routeId: 'vacancy.bookmark',
      path: 'bookmark',
      icon: 'bookmark',
      label: 'navigation.bookmark-vacancy-label',
      right: ACL.PROFILE_CONVEYING,
    },
     */
    owner: {
      routeId: 'vacancy.owner',
      path: 'owner',
      icon: 'clipboard-list',
      label: 'navigation.owner-vacancy-label',
      right: ACL.VACANY,
    },
  },
};
