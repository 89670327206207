<template>
  <span class="o-model-keywords button-container for-all-kind">
    <BBadge v-for="keyword in keywords" :key="keyword" variant="light">{{ keyword }}</BBadge>
  </span>
</template>

<script>
export default {
  name: 'o-model-keywords',
  props: {
    keywords: {
      type: Array,
      required: true,
    },
  },
};
</script>
