import { ownerRouteId } from '../../sitemap';

export const profileMixin = {
  data() {
    return {
      profileId: null,
      profile: null,
      hasProfileValidSchema: false,
    };
  },
  computed: {
    hasProfile() {
      return !!this.profile;
    },
  },
  beforeMount() {
    // set profile by request route
    const { id } = this.$router.currentRoute.params;

    if (id) {
      this.$logger.info('Load profile by request', id);
      this.profileId = id;
    }

    this.loadProfile();
  },

  // methode
  methods: {
    profileRoute(type, name = 'direct-pv.profile.step-1') {
      switch (type) {
        case 'preview':
          this.$router.push({
            name: 'direct-pv.profile.detail',
            target: 'print',
            params: { id: this.profileId },
          });
          break;

        case 'return':
          this.$router.push({ name: ownerRouteId });
          break;

        default:
          this.$router.push({ name, params: { id: this.profileId } });
      }
    },
    profileSuccess(message = null) {
      this.$store.dispatch('notify', message ? message : this.$t('profile.notify.successfully-saved'));
    },
    profileError(error, options = {}) {
      this.$store.dispatch('error', {
        error,
        options: {
          translationKey: 'profile.validation-errors',
          ...options,
        },
      });
    },
    loadProfile() {
      if (!this.profileId) {
        this.profile = null;
        this.$emit('profile-not-exists');
        return this.$logger.warn('No profile id defined');
      }

      this.$api.profile
        .getById(this.profileId)
        .then(profile => {
          this.validSchema = profile.validSchema;
          this.profile = profile;

          this.$emit('profile-loaded', profile);
        })
        .catch(error => {
          this.profileId = null;
          this.$emit('profile-not-exists');

          this.$store.dispatch('error', {
            section: 'profile.mixin',
            error,
          });
        });
    },
  },
};
