<template>
  <SCVersionDetectionContainer id="app" :class="className">
    <router-view />
    <SCCookie />
    <CNotifier />
    <CPrompt />
  </SCVersionDetectionContainer>
</template>

<script>
import SCVersionDetectionContainer from 'library/components/src/components/sc/version-detection-container';
import SCCookie from 'library/components/src/components/sc/cookie';
import CPrompt from './components/c/prompt';
import CNotifier from './components/c/notifier';

export default {
  components: { CPrompt, CNotifier, SCVersionDetectionContainer, SCCookie },
  data() {
    return {
      classes: [],
    };
  },
  computed: {
    className() {
      return ['app', this.isPrintable ? 'is-printable' : 'is-not-printable', this.$store.getters['page/classes']];
    },
    isPrintable() {
      return this.$route.meta.printable === true;
    },
  },
  beforeMount() {
    this.$store.dispatch('updateApplication');
  },
};
</script>
