<template>
  <BCard class="c-reservation-interview" no-body>
    <BCardHeader>
      <BRow>
        <BCol>{{ headline }}</BCol>
        <BCol sm="auto">
          <SOTimeleft :value="validTo" />
        </BCol>
      </BRow>
    </BCardHeader>
    <OReservationBar :status="status" />
    <BCardBody>
      <CModelInterview v-model="interviewModel" :person="person" />
    </BCardBody>
    <BCardFooter class="as-content-footer text-right">
      <div class="button-container">
        <BButton variant="primary" :disabled="!canSaveInterview" @click="onClickSaveInterview">
          <BSpinner v-if="isLoading" small />
          {{ $t(`${prefix}.save-interview-label`) }}
        </BButton>
        <BButton
          v-if="rights.allowExtendInterview"
          variant="dark"
          :disabled="isLoading"
          v-prompt.click="{
            message: $t(`${prefix}.extend-interview.prompt`),
            callback: { success: onClickArchiveInterview },
          }"
        >
          <BSpinner v-if="isLoading" small />
          {{ $t(`${prefix}.extend-interview.label`) }}
        </BButton>
      </div>
    </BCardFooter>
    <BCardBody v-if="hasLatestInterview" class="color-set-soft">
      <OLayoutToggle :label="$t(`${prefix}.depricated-toggle-label`)">
        <CModelInterview
          v-for="(interview, index) in latestInterviews"
          :key="index"
          :value="interview"
          class="latest-interview"
          readonly
        />
      </OLayoutToggle>
    </BCardBody>
    <slot name="footer" slot="footer" />
  </BCard>
</template>
<script>
import { reservationIndex, SIGNING } from 'library/src/models/reservation.enum';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';

import { BButton, BCard, BCardBody, BCardFooter, BCardHeader, BCol, BRow, BSpinner } from 'bootstrap-vue';
import SOTimeleft from 'library/components/src/components/so/timeleft';

import OReservationBar from '../../o/reservation/bar';
import OLayoutToggle from '../../o/layout/toggle';
import CReservationContract from './contract';
import CModelInterview from '../model/interview';
import CReservationTerminate from './terminate';

const prefix = 'c.reservation-process';

export default {
  name: 'c-reservation-interview',
  mixins: [serviceMixin],
  components: {
    CReservationTerminate,
    CModelInterview,
    CReservationContract,
    OLayoutToggle,
    BCard,
    BCardBody,
    BCardHeader,
    BButton,
    BCardFooter,
    BSpinner,
    SOTimeleft,
    BRow,
    BCol,
    OReservationBar,
  },
  props: {
    status: String,
    validTo: String,
    profileId: String,
    interview: Object,
    person: Object,
    latestInterviews: Array,
    rights: Object,
  },
  data() {
    return {
      interviewModel: {},
      isLoading: false,
    };
  },
  computed: {
    prefix: () => prefix,
    headline() {
      const { status } = this;
      return this.$t(`profile.reservation.status-headline.${status}`);
    },
    hasLatestInterview() {
      const { latestInterviews } = this;
      return latestInterviews && latestInterviews.length > 0;
    },
    canSaveInterview() {
      const { interviewModel, isLoading } = this;
      return !isLoading && interviewModel && interviewModel.informated && interviewModel.company;
    },
    isOnInterviewing() {
      return reservationIndex.includes(this.status);
    },
    isOnContracting() {
      return this.status === SIGNING && !this.reservation.contraction;
    },
  },
  watch: {
    interview() {
      this.interviewModel = this.interview;
    },
  },

  beforeMount() {
    this.interviewModel = this.interview;
  },

  methods: {
    onClickSaveInterview() {
      this.dispatchLoading(
        'interview',
        async () => {
          await this.$api.reservation.interview(this.profileId, this.interviewModel);
          this.$emit('refresh');
        },
        {
          error: {
            useValidation: true,
            translationKey: `validation-errors`,
          },
          notify: {
            message: this.$t(`${prefix}.interview-saved-message`),
          },
        },
      );
    },

    onClickArchiveInterview() {
      this.dispatchLoading(
        'interview',
        async () => {
          await this.$api.reservation.extendInterview(this.profileId);
          this.$emit('refresh');
        },
        {
          useValidation: true,
          translationKey: `validation-errors`,
          notify: {
            message: this.$t(`${prefix}.extend-interview.notify`),
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.latest-interview {
  &:not(:first-child) {
    margin-top: $space-default;
  }
}
</style>
