<template>
  <OInputAbstract class="o-input-upload" hide-invalid-feedback v-bind="abstractProps">
    <BInputGroup>
      <BInputGroupText>
        <SOIcon :name="uploadType.iconName" size="sm" />
      </BInputGroupText>

      <div v-if="modelExists" class="form-control">
        {{ inputLabel }}
      </div>
      <template v-else>
        <VueUploadComponent
          ref="upload"
          class="form-control"
          :class="{ 'is-invalid': hasError }"
          v-bind="uploadComponentProps"
          @drop.native="onDrop"
          @input-file="onInputFile"
          @input-filter="onInputFilter"
        >
          <div v-if="showDraggable" class="drop color-set-soft">
            <SOIcon name="file-upload" />
          </div>
          <BProgressBar v-if="isUploading" show-progress class="loading-bar" variant="primary" :value="progress" />
          <template v-else>
            {{ inputLabel }}
          </template>
        </VueUploadComponent>
      </template>

      <OMarkRequired v-if="required" />
      <BInputGroupAppend>
        <SOButtonDelete v-if="canDelete" :action="() => deleteDocument()" />
      </BInputGroupAppend>
    </BInputGroup>
  </OInputAbstract>
</template>

<script>
import { isString, isObject } from 'library/src/utilities/types';
import { ignore } from 'library/src/utilities/ignore';
import VueUploadComponent from 'vue-upload-component';
import {
  BInputGroup,
  BInputGroupAppend,
  BProgress,
  BProgressBar,
  BButton,
  BInputGroupText,
  BSpinner,
} from 'bootstrap-vue';
import OInputAbstract from './abstract';
import SOIcon from 'library/components/src/components/so/icon';
import SOButtonDelete from 'library/components/src/components/so/button/delete';
import { TYPE_IMAGE, ALLOWED_TYPES } from './upload-type.enum';
import OMarkRequired from '../mark/required';
import get from 'lodash.get';

const prefix = 'o.input-upload';
const DEFAULT_TYPE = TYPE_IMAGE;

export default {
  name: 'o-input-upload',
  mixins: [OInputAbstract],
  components: {
    VueUploadComponent,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BSpinner,
    BButton,
    BProgress,
    BProgressBar,
    SOButtonDelete,
    OMarkRequired,
    SOIcon,
    OInputAbstract,
  },
  model: {
    value: 'value',
    event: 'change',
  },
  data() {
    return {
      showDraggable: false,
      inputId: `${this.key || 'upload'}-${Date.now()}`,
      uploadFile: null,
    };
  },
  props: {
    value: Object,
    placeholder: String,
    loading: String,
    additional: Object,
    acceptType: {
      type: String,
      default: DEFAULT_TYPE,
      validate: v => ALLOWED_TYPES.hasOwnProperty(v),
    },
    required: Boolean,
    deleteNotAllowed: Boolean,
  },
  computed: {
    inputLabel() {
      if (this.modelExists) {
        return this.documentId;
      } else if (this.errorMessage) {
        return this.errorMessage;
      }
      return this.placeholder || this.$t(this.uploadType.placeholderKey);
    },
    /**
     * @property { Object } uploadType
     * @property { String } uploadType.iconName
     * @property { String } uploadType.accept
     **/
    uploadType() {
      if (ALLOWED_TYPES.hasOwnProperty(this.acceptType)) {
        return ALLOWED_TYPES[this.acceptType];
      }
      return ALLOWED_TYPES[DEFAULT_TYPE];
    },
    modelExists() {
      const { value } = this;
      return value && typeof value === 'object';
    },
    documentId() {
      if (this.modelExists) {
        return this.value.id;
      }
      return null;
    },
    uploadComponentProps() {
      const postAction = '/api/v1/document/document/upload';
      return {
        inputId: this.inputId,
        drop: true,
        maximum: 1,
        accept: this.uploadType.accept.join(','),
        chunkEnabled: true,
        chunk: {
          headers: {
            ...this.$store.getters['auth/getHeaders'],
          },
          action: postAction,
          minSize: 10, //force chunk upload
          // server not support multi parts upload
          maxActive: 1,
          maxRetries: 5,
        },
        postAction,
      };
    },
    canDelete() {
      // force not allowed to delete
      if (this.deleteNotAllowed) {
        return false;
      }
      return this.modelExists;
    },
    isUploading() {
      if (this.hasError) {
        return false;
      }

      return this.uploadFile ? !this.uploadFile.success : false;
    },
    progress() {
      return this.uploadFile ? this.uploadFile.progress : 0;
    },
  },

  mounted() {
    this.$root.$on('dropped', () => {
      this.showDraggable = false;
    });

    // closer callbacks
    this.$dragStart = () => (this.showDraggable = true);
    this.$dragEnd = () => this.$root.$emit('dropped');

    // not ssr safe, ignore all errors
    ignore(() => {
      window.addEventListener('dragenter', this.$dragStart);
      window.addEventListener('drop', this.$dragEnd);
      document.addEventListener('mouseout', this.$dragEnd);
    });
  },

  beforeDestroy() {
    // not ssr safe, ignore all errors
    ignore(() => {
      window.removeEventListener('dragenter', this.$dragStart);
      window.removeEventListener('drop', this.$dragStart);
      document.removeEventListener('mouseout', this.$dragEnd);
    });
  },

  // methods

  methods: {
    onDrop() {
      this.$root.$emit('dropped');
    },

    /**
     * @param {Object} uploadEvent
     **/
    onInputFile(uploadEvent) {
      // error updates
      this.internalError = undefined;

      if (isObject(uploadEvent)) {
        if (uploadEvent.error) {
          this.internalError = get(uploadEvent, 'response.message') || uploadEvent.error;

          // translate server errors
          if (this.internalError === 'oversize') {
            this.internalError = this.$t(`${prefix}.errors.file-oversize`);
          }
        }

        // file status update
        if (uploadEvent.fileObject) {
          this.uploadFile = uploadEvent;

          // if upload finished
          if (uploadEvent.success) {
            // set model
            this.$emit('change', uploadEvent.response.model);
          }
        }
      }
      // critical server error?
      else if (isString(uploadEvent)) {
        this.internalError = uploadEvent;
      }

      this.$refs.upload.active = true;
    },

    /**
     * filter handling for file before upload
     **/
    onInputFilter(file, oldFile, prevent) {
      // is new file
      if (file && !oldFile) {
        const { uploadType } = this;

        // Filter for format
        if (!uploadType.accept.includes(file.type)) {
          this.internalError = this.$t(`${prefix}.errors.${uploadType.errorFormatKey}`);
          return prevent();
        }

        // Filter for size
        if (file.size > uploadType.maxSize) {
          this.internalError = this.$t(`${prefix}.errors.${uploadType.errorSizeKey}`);
          return prevent();
        }
      }
    },

    /**
     * safe delete call
     *
     * @returns {Promise<boolean>}
     */
    async deleteDocument() {
      if (this.documentId) {
        try {
          // delete existing document
          await this.$api.document.delete(this.documentId);
        } catch (e) {
          this.errorHandling(e);
        }
      }

      this.uploadFile = undefined;
      this.$emit('change', null);

      return true;
    },

    /**
     * call global error handling
     *
     * @param error
     */
    errorHandling(error) {
      this.$store.dispatch('error', {
        section: 'o-input-upload',
        error,
      });
    },
  },
};
</script>

<style lang="scss">
$padding: 2px;

.o-input-upload {
  .loading-bar {
    position: absolute;
    left: $padding;
    right: $padding;
    top: $padding;
    bottom: $padding;
    height: auto;
    transition: none;
  }

  .drop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
}
</style>
