import { acl } from '../services';
import { getRouteItem, getRoute, navigation } from '../sitemap';
import VSubHeaderContainer from '../components/c/container/subheader';

import VMyDashboard from '../components/vw/my/dashboard';
import VMyAccount from '../components/vw/my/account';
import VMyPassword from '../components/vw/my/password';
import VMyStatistics from '../components/vw/my/statistic';
import VMyDownload from '../components/vw/my/download';

export default {
  ...getRouteItem(navigation.my),
  component: VSubHeaderContainer,
  redirect: getRoute(navigation.my),
  beforeEnter: acl.loggedGuard(true),
  props: {
    heading: 'navigation.my.title',
  },
  children: [
    {
      ...getRouteItem(navigation.my.childs.account),
      component: VMyDashboard,
      children: [],
    },
    {
      path: 'account/edit',
      name: 'my.account.edit',
      component: VMyAccount,
    },
    {
      path: 'account/password',
      name: 'my.account.password',
      component: VMyPassword,
    },
    {
      ...getRouteItem(navigation.my.childs.statistic),
      component: VMyStatistics,
    },
    {
      ...getRouteItem(navigation.my.childs.download),
      component: VMyDownload,
    },
    {
      ...getRouteItem(navigation.my.childs.sessions),
      component: () => import(/* webpackChunkName: "my-sessions" */ '../components/vw/my/sessions'),
    },

    // external tool
    {
      name: 'privacy.obtaining',
      path: '/privacy/obtaining',
      component: () => import(/* webpackChunkName: "privacy" */ '../components/vw/privacy/obtaining'),
    },
  ],
};
