<template>
  <BButton :variant="variant" :to="to">
    {{ $t('profile.reservation.open-button-label') }}
  </BButton>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { getRoute, navigation } from '@/src/sitemap';

export default {
  name: 'o-reservation-link',
  components: {
    BButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    variant: {
      default: 'primary',
    },
  },
  data() {
    return {
      openDialog: false,
    };
  },
  computed: {
    to() {
      const { id } = this;
      return getRoute(navigation.directPv.childs.reserved.childs.open, { id });
    },
  },
};
</script>
