<template>
  <BButton :variant="variant" :size="size" :to="previewLink">
    <SOIcon name="eye" size="sm" as-label-icon />
    {{ label }}
  </BButton>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-profile-button-download',
  components: {
    SOIcon,
    BButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'dark',
    },
    label: {
      type: String,
      default() {
        return this.$t('profile.toolbar.open-profile');
      },
    },
  },
  computed: {
    previewLink() {
      const { id } = this;
      return {
        name: 'direct-pv.profile.detail',
        target: 'print',
        params: { id },
      };
    },
  },
};
</script>
