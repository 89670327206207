<template>
  <div class="o-layout-toggle" :class="{ 'is-active': isActive }">
    <OToggle :label="label" v-model="isActive" />
    <div v-if="isActive" class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import OToggle from '../toggle';
import OLayoutGroup from './group';

export default {
  name: 'o-layout-toggle',
  components: { OLayoutGroup, OToggle },
  data() {
    return {
      active: false,
    };
  },
  props: {
    label: String,
  },
  computed: {
    isActive: {
      get() {
        return this.active;
      },
      set(value) {
        this.active = value;
        this.$emit('change', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.o-layout-toggle {
  &.is-active {
    > .o-toggle {
      margin-bottom: $space-default;
    }
  }
}
</style>
