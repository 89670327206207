<template>
  <BCard class="c-reservation-contract" no-body>
    <BCardHeader :header="headline" />
    <BCardBody>
      <CModelContract :payment-intervals="paymentIntervals" v-model="contract" />
    </BCardBody>
    <BCardFooter class="text-right">
      <div class="button-container">
        <BButton variant="dark" :to="{ name: 'direct-pv.reserved' }">
          {{ $t(`${prefix}.back-label`) }}
        </BButton>
        <BButton variant="primary" @click="onClickSave">
          {{ $t(`${prefix}.save-label`) }}
        </BButton>
      </div>
    </BCardFooter>
  </BCard>
</template>
<script>
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { BCard, BCardHeader, BCardBody, BCardFooter, BButton } from 'bootstrap-vue';
import OReservationToolbar from '../../o/reservation/toolbar';
import OInputOption from '../../o/input/option';
import CModelContract from '../model/contract';

const prefix = 'c.reservation-contract';

export default {
  name: 'c-reservation-contract',
  mixins: [serviceMixin],
  components: {
    CModelContract,
    OInputOption,
    OReservationToolbar,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BButton,
  },
  props: {
    status: String,
    profileId: String,
    paymentIntervals: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      contract: {},
    };
  },
  computed: {
    prefix: () => prefix,
    headline() {
      const { status } = this;
      return this.$t(`profile.reservation.status-headline.${status}`);
    },
  },
  methods: {
    onClickSave() {
      this.dispatchLoading(
        'reservation.contract',
        () => this.$api.reservation.contraction(this.profileId, this.contract).then(() => this.$emit('refresh')),
        {
          error: {
            useValidation: true,
            translationKey: `${prefix}.errors`,
          },
        },
      );
    },
  },
};
</script>
