import Vue from 'vue';
import Vuex from 'vuex';
import { resolveMessage } from 'library/src/utilities/resolve-message';
import { mapMutations } from 'library/src/utilities/vueex/store-mapping';
import types from 'library/src/utilities/types';
import { SINGLETON_KEY } from '../setting/notifier';
import { localStorage, logger } from '../services';
import { SessionKey } from 'library/src/utilities/session-storage';

// modules
import registrationModule from './module/registration';
import { createModule as pageStoreModule } from 'library/components/src/plugins/page/store/page';
import { createModule as authStoreModule } from 'library/components/src/plugins/auth/store/auth';
import { createModule as profileStoreModule } from './module/profile';
import { createModule as statisticStoreModule } from './module/statistic';
import { createModule as currentAccountStoreModule } from './module/current-account';
import { createModule as positionStoreModule } from './module/position';

Vue.use(Vuex);

const enterUri = '/login';
const sessionSimplify = new SessionKey('simplify');

const state = {
  currentPrompt: null,
  simplify: sessionSimplify.get() === 'true',
};

const store = new Vuex.Store({
  modules: {
    page: pageStoreModule({ Vue, logger }),
    auth: authStoreModule({ Vue, logger }),
    currentAccount: currentAccountStoreModule({ Vue, logger }),
    profile: profileStoreModule({ Vue, logger }),
    position: positionStoreModule({ Vue, logger }),
    statistic: statisticStoreModule({ Vue, logger }),
    registration: registrationModule,
  },

  state,
  mutations: {
    ...mapMutations(state),
    setSimplify(state, value) {
      state.simplify = !!value;
      sessionSimplify.set(state.simplify);
    },
  },
  actions: {
    updateApplication({ dispatch, state }) {
      // called after application loaded
      if (state.auth.user) {
        dispatch('profile/fetchBookmark', { passive: true });
        dispatch('currentAccount/fetch', { passive: true });
      }
    },

    // called by other actions, after user login
    userLogin({ dispatch }) {
      // called after user login
      dispatch('profile/fetchBookmark', { passive: true });
      dispatch('currentAccount/fetch', { passive: true });
    },

    // called by other actions, after user logout
    userLogout({ dispatch }) {
      dispatch('profile/resetBookmark');
      dispatch('statistic/reset');
      dispatch('currentAccount/reset');

      store.$root.$router.push(enterUri);
    },
    /**
     * application notification service by store for errors
     *
     * @param event
     * @param payload
     */
    error(event, payload) {
      const options = {};

      if (payload instanceof Error) {
        payload = resolveMessage(payload);

        payload.message = store.$root.$t(`response-errors.${payload.message}`);
      } else if (payload && payload.error) {
        Object.assign(options, payload.options);

        payload = resolveMessage(payload.error, options);

        if (options.translationKey) {
          payload.message = store.$root.$t(`${options.translationKey}.${payload.message}`);
        }
      }

      store[SINGLETON_KEY].addNotification({
        lifetime: 5,
        ...payload,
        variant: 'error',
      });
    },
    /**
     * application notification service by store
     *
     * @param event
     * @param payload
     * @example
     *  $store.dispatch('notify', {
     *    message: 'Erfolgreich'
     *  });
     */
    notify(event, payload) {
      payload = resolveMessage(payload);

      store[SINGLETON_KEY].addNotification({
        lifetime: 2,
        ...payload,
      });
    },

    /**
     * prompt dialog
     *
     * @param commit
     * @param payload
     */
    prompt({ state }, payload) {
      let { message, callback } = payload;

      return new Promise(resolve => {
        // add prompt config to store
        state.currentPrompt = {
          message,
          callback(answer) {
            // remove after calling
            state.currentPrompt = null;

            // execute logic for callback
            if (types.function(callback)) {
              callback(answer);
            }
            // split callback config for success or cancel
            else if (types.object(callback)) {
              const { success, cancel } = callback;

              if (types.function(success) && answer) {
                success();
              } else if (types.function(cancel) && !answer) {
                cancel();
              }
            }

            resolve(answer);
          },
        };
      });
    },
  },
});

export default store;
