import Vue from 'vue';
import VueI18n from 'vue-i18n';
import deepmerge from 'deepmerge';

// all translation files
import frontend_DE from '@/src/translations/de.json';
import backend_DE from 'library/src/translations/de.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  silentTranslationWarn: false,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: {
    de: deepmerge(backend_DE, frontend_DE),
  },
  // debug missing translation
  missing(locale, path) {
    // for debug only
    console.warn('Missing translation', locale, path);
    window.missingTranslations = window.missingTranslations || [];
    if (!window.missingTranslations.includes(path)) {
      window.missingTranslations.push(path);
    }
  },
});

export default i18n;
