<template>
  <OInputAbstract :class="className" v-bind="fieldProps">
    <div v-if="required" class="holder">
      <OMarkRequired class="required-marker" />
    </div>
    <BFormTextarea
      :class="{ 'has-required-marked': required }"
      :data-identity="identity"
      :state="errorState"
      v-bind="inputProps"
      v-model="model"
    />
  </OInputAbstract>
</template>

<script>
import types from 'library/src/utilities/types';
import { placeholder } from 'library/src/utilities/placeholder';
import { BFormTextarea } from 'bootstrap-vue';
import OInputAbstract from './abstract';
import OText from '../text';
import OMarkRequired from '../mark/required';

export default {
  name: 'o-input-textarea',
  mixins: [OInputAbstract],
  components: {
    OMarkRequired,
    OText,
    OInputAbstract,
    BFormTextarea,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  props: {
    required: Boolean,
    value: String,
    identity: String,
    min: [String, Number],
    max: [String, Number],
    autoDescription: Boolean,
  },
  computed: {
    className() {
      return ['o-input-textarea'];
    },
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
    inputProps() {
      const { min: minlength, max: maxlength, required } = this;

      return {
        ...this.$attrs,
        required,
        minlength,
        maxlength,
      };
    },
    fieldProps() {
      const props = { ...this.abstractProps };
      let { description } = props;

      if (this.autoDescription) {
        const { min, max } = this;

        if (min && max) {
          description = this.$t('generic.min-max-char-template');
        } else if (max) {
          description = this.$t('generic.max-char-template');
        } else if (min) {
          description = this.$t('generic.min-char-template');
        } else {
          description = this.$t('generic.char-template');
        }
      }

      // rendering description and replace optional placeholders
      if (types.string(description)) {
        const { min, max } = this;
        const length = String(this.value || '').length;
        props.description = placeholder(description, {
          length,
          min,
          max,
        });
      }

      return props;
    },
  },
};
</script>

<style lang="scss" scoped>
.holder {
  position: relative;
}
.required-marker {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
