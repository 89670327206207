<template>
  <div class="c-model-contract">
    <OInputOption v-bind="$t(`${prefix}.contracing-kind`)" v-model="contractingKind" />
    <OInputValue required v-bind="$t(`${prefix}.company`)" v-model="company" />
    <OInputMoney required no-value-description v-bind="$t(`${prefix}.annual-income`)" v-model="annualIncome" />
    <OInputDate required v-bind="$t(`${prefix}.starting-date`)" v-model="startingDate" />
    <OInputValue required none-appearance type="number" v-bind="$t(`${prefix}.probation`)" v-model="probation" />
    <OInputUpload
      required
      acceptType="document"
      v-bind="$t(`${prefix}.salary-statement`)"
      v-model="salaryStatementDocument"
    />
    <OInputOption
      required
      v-bind="$t(`${prefix}.payment-intervals`)"
      :options="paymentIntervalOptions"
      v-model="paymentInterval"
      stacked
    />
  </div>
</template>
<script>
import { computedModel } from 'library/src/utilities/vue/computed-model';
import OInputOption from '../../o/input/option';
import OInputValue from '../../o/input/value';
import OInputUpload from '../../o/input/upload';
import OInputDate from '../../o/input/date';
import OInputMoney from '../../o/input/money';

const prefix = 'c.model-contract';

export default {
  name: 'c-model-contract',
  components: {
    OInputDate,
    OInputUpload,
    OInputValue,
    OInputOption,
    OInputMoney,
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    value: Object,
    paymentIntervals: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      prefix,
    };
  },
  computed: {
    ...computedModel([
      'contractingKind',
      'annualIncome',
      'company',
      'probation',
      'startingDate',
      'salaryStatementDocument',
      'paymentInterval',
    ]),
    paymentIntervalOptions() {
      return this.paymentIntervals.map(interval => {
        return {
          text: this.$t(`intervals.${interval}`),
          value: interval,
        };
      });
    },
  },
};
</script>
