<template>
  <OInputAbstract class="o-input-date" v-bind="abstractProps">
    <BInputGroup>
      <BInputGroupText v-if="iconName">
        <SOIcon :name="iconName" size="sm" />
      </BInputGroupText>
      <BFormInput
        type="date"
        :data-identity="identity"
        :class="{ 'has-required-marked': required }"
        v-bind="inputProps"
        v-model="internalValue"
        @blur="model = internalValue"
      />
      <OMarkRequired v-if="required" />
    </BInputGroup>
  </OInputAbstract>
</template>

<script>
import { BInputGroup, BInputGroupText, BFormInput } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import OInputAbstract from './abstract';
import { dateFormat, toDate, asDate, isValidDate, ISO_FORMAT_SHORT } from 'library/src/utilities/date';
import OMarkRequired from '../mark/required';

export default {
  name: 'o-input-date',
  mixins: [OInputAbstract],
  components: {
    BInputGroup,
    BInputGroupText,
    BFormInput,
    OMarkRequired,
    SOIcon,
    OInputAbstract,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return { internalValue: null };
  },
  props: {
    identity: String,
    maxDate: Date,
    minDate: Date,
    required: Boolean,
    readonly: Boolean,
    value: {},
    preset: {},
    iconName: {
      type: String,
      default: 'calendar',
    },
  },
  computed: {
    model: {
      get() {
        if (this.value) {
          return this.getValidDate(this.value);
        }
        return null;
      },
      set(newValue) {
        let value = asDate(newValue);
        value = isValidDate(value) ? value : null;
        this.internalValue = this.getValidDate(value);
        this.$emit('change', value);
      },
    },
    inputProps() {
      const { required, readonly, minDate, maxDate } = this;
      return {
        required,
        readonly,
        min: this.getValidDate(minDate),
        max: this.getValidDate(maxDate),
      };
    },
  },
  watch: {
    value(v) {
      this.internalValue = this.getValidDate(v);
    },
  },
  beforeMount() {
    // preset definition
    if (this.preset && !this.value) {
      this.model = this.preset;
    }

    this.internalValue = this.model;
  },
  methods: {
    getValidDate(value) {
      const v = toDate(value);

      if (isValidDate(v)) {
        return dateFormat(v, ISO_FORMAT_SHORT);
      }

      return null;
    },
  },
};
</script>
