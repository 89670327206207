/**
 * position store module
 *
 * @param Vue
 * @param logger
 * @returns {boolean|{mutations : {setValid(*, *) : void, setStatistic(*, *) : void}, state : {language : null, positions : {}}, getters : {positionByKey : (function(*) : function(...[*]=))}, actions : {fetchLanguage({commit : *, state : *, dispatch : *}, {language? : *}) : Promise<void>}, namespaced : boolean}}
 */
export function createModule({ Vue, logger }) {
  const namespaced = true;

  const state = {
    language: null,
    positions: {},
    cached: [],
  };

  const getters = {
    /**
     * return position label by key
     *
     * @param state
     * @returns {function(...[*]=)}
     */
    positionByKey: state => key => {
      const { positions } = state;
      if (positions.hasOwnProperty(key)) {
        return positions[key];
      }

      logger.warn(`position/positionByKey, position not found by key ${key}`);
      return key;
    },

    /**
     * get position list by input
     *
     * @param state
     * @returns Array list of positions [{ key: String, label: String }, ...]
     */
    findPositionByInput: state => (input, length = 4) => {
      const pattern = new RegExp(input, 'i');
      const positions = state.cached.filter(item => pattern.test(item.label));
      return positions.slice(0, length);
    },

    /**
     * get position list by input and filter defined items before cut length
     * @param state
     * @return {function(*=, *, *=): T[]}
     */
    findPositionByInputFilter: state => (input, filterItems = [], length = 4) => {
      const pattern = new RegExp(input, 'i');
      const positions = state.cached
        // filter by custom key list
        .filter(item => !filterItems.includes(item.key))
        // filter by matching
        .filter(item => pattern.test(item.label));

      if (input && `${input}`.length > 3) {
        const iOrder = `${input}`.toLowerCase();
        return positions
          .map(i => {
            i.orderLabel = i.label.toLowerCase();
            return i;
          })
          .sort((a, b) => a.orderLabel.indexOf(iOrder) - b.orderLabel.indexOf(iOrder))
          .slice(0, length);
      }
      
      return positions.slice(0, length);
    },
  };

  const mutations = {};

  const actions = {
    /**
     * load position list by language
     * @param commit
     * @param state
     * @param dispatch
     * @param language
     * @returns {Promise<Boolean>}
     */
    async fetchLanguage({ commit, state, dispatch }, { language }) {
      logger.info(`position/fetchLanguage fetching language ${language}`);

      if (state.language === language) {
        return false; // already loaded or on loading
      }

      state.language = language;
      state.positions = {};
      state.cached = [];

      await Vue.$api.position
        .list(language)
        .catch(logger.error)
        .then(list => {
          state.positions = list;

          // create cache list for optimize search
          state.cached = Object.keys(list).map(key => {
            return {
              key,
              label: list[key],
            };
          });
        });

      return true;
    },
  };

  return {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  };
}
