<template>
  <BContainer class="v-my-account mt-4">
    <BForm @submit.prevent="onSubmit()">
      <BCard no-body>
        <BCardBody>
          <template v-if="model">
            <CModelPerson
              validate-on-change
              validate-on-mount
              v-model="model.person"
              @invalid="invalid => (invalidPerson = invalid)"
            />
            <CModelContact
              validate-on-change
              validate-on-mount
              v-model="model.person"
              :phone-required="true"
              @invalid="invalid => (invalidContact = invalid)"
            />
            <CModelAddress
              validate-on-change
              validate-on-mount
              v-model="model.address"
              allow-autofill
              :allowed-countries="allowedCountries"
              @invalid="invalid => (invalidAddress = invalid)"
            />
          </template>
          <BSpinner v-else small />
        </BCardBody>
        <OCardFooterButtons :prefix="prefix" :is-valid="isValid" :back-to="backTo" :is-loading="isLoading" />
      </BCard>
    </BForm>
  </BContainer>
</template>

<script>
import { BContainer, BCard, BCardBody, BSpinner, BForm } from 'bootstrap-vue';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';

import { COUNTRY_FILTER } from 'library/src/models/country-filter.enum';

import CModelPerson from '../../c/model/person';
import CModelAddress from '../../c/model/address';
import CModelCompany from '../../c/model/company';
import CModelContact from '../../c/model/contact';
import OCardFooterButtons from '../../o/card/footer-buttons';

const prefix = 'vw.my-account';

export default {
  mixins: [pageMixin, serviceMixin, currentUserMixin],
  components: {
    BContainer,
    BCard,
    BCardBody,
    BSpinner,
    BForm,
    CModelPerson,
    CModelAddress,
    CModelCompany,
    CModelContact,
    OCardFooterButtons,
  },
  data() {
    return {
      prefix,
      allowedCountries: COUNTRY_FILTER,
      invalidPerson: false,
      invalidAddress: false,
      invalidCompany: false,
      invalidContact: false,
      backTo: { name: 'my' },
      model: null,
    };
  },
  computed: {
    isValid() {
      return ![this.invalidPerson, this.invalidAddress, this.invalidCompany, this.invalidContact].includes(true);
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`${prefix}.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    this.dispatchLoading('my.account', () =>
      this.$api.account
        .get()
        // assign model
        .then(account => {
          // set not defined countryCode value
          const { address } = account;
          if (!address.countryCode) {
            address.countryCode = 'DEU';
          }

          this.model = account;
        }),
    );
  },

  methods: {
    /**
     * submit method
     * @returns {Promise<boolean>}
     */
    async onSubmit() {
      this.$logger.debug('Submit account updates', this.isValid, this.model);

      if (!this.isValid) {
        return false; // ignore if invalid
      }

      await this.dispatchLoading(
        'my.account',
        async () => {
          await this.$api.account.update(this.model);
          this.$router.push(this.backTo);
        },
        {
          notify: {
            message: this.$t(`${prefix}.notfiy.successful`),
          },
          error: {
            useValidation: true,
            translationKey: 'validation-errors',
          },
        },
      );
    },
  },
};
</script>
