<template>
  <BContainer class="vw-my-download mt-4">
    <BRow>
      <BCol v-for="download of downloads" :key="download.link" sm="12" md="6" lg="4">
        <BCard :header="download.title">
          <a :download="download.filename" :href="download.link">{{ download.label }}</a>
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { BContainer, BCard, BCardGroup, BRow, BCol } from 'bootstrap-vue';
import { DOCUMENTS } from 'library/src/models/documents.enum';
import { PROFILE_CONVEYING } from 'library/src/models/acl.enum';

const prefix = 'vw.my-download';

export default {
  mixins: [pageMixin, currentUserMixin],
  components: {
    BContainer,
    BCard,
    BCardGroup,
    BRow,
    BCol,
  },
  computed: {
    prefix: () => prefix,
    accountType() {
      return this.$store.getters['currentAccount/accountType'];
    },
    downloads() {
      const { accountType } = this;
      const downloads = [];

      downloads.push({
        ...this.$t(`${prefix}.links-${accountType}.${DOCUMENTS.CONDITION}`),
      });

      downloads.push({
        ...this.$t(`${prefix}.links.${DOCUMENTS.DATA_PROTECTION_REGULATION}`),
      });

      if (this.isAllowed(PROFILE_CONVEYING)) {
        downloads.push({
          ...this.$t(`${prefix}.links.${DOCUMENTS.IMPORT_CONDITION}`),
        });
      }

      return downloads;
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`${prefix}.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    this.$store.dispatch('currentAccount/fetch');
  },
};
</script>
