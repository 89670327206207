<template>
  <div class="c-list-owners content-component">
    <CSearchQuickfilter class="mb-4" prefix="c.list-owners.filter" :filter="ownerFilter" v-model="filter" />
    <CProfileList :filter="filterModel" :list-service="listService" :item-props="itemProps" />
  </div>
</template>

<script>
import OWNER_FILTERS from 'library/src/models/filter/owner.enum';
import CProfileList from '../../c/profile/list';
import CSearchQuickfilter from '../../c/search/quickfilter';

export default {
  name: 'c-list-owners',
  components: {
    CSearchQuickfilter,
    CProfileList,
  },
  data() {
    return { filter: null };
  },
  computed: {
    filterModel() {
      return { filter: this.filter };
    },
    ownerFilter() {
      return [
        OWNER_FILTERS.ALL,
        OWNER_FILTERS.INVALID,
        OWNER_FILTERS.DRAFT,
        OWNER_FILTERS.PUBLISHED,
        OWNER_FILTERS.RESERVATED,
      ];
    },
    listService() {
      return this.$api.profile.pagingByOwner.bind(this.$api.profile);
    },
    itemProps() {
      return {
        variant: 'owner',
      };
    },
  },
};
</script>
