import ACL from 'library/src/models/acl.enum';

import { acl } from '../../services';
import VBaseContainer from '../../components/c/container/base';

export default [
  {
    path: '/vacancy',
    component: VBaseContainer,
    children: [
      {
        name: 'vacancy',
        path: '/',
        redirect: { name: 'vacancy.search' },
        right: ACL.PROFILE_CONVEYING,
      },
      {
        name: 'vacancy.search',
        label: 'navigation.vacancy-search-label',
        beforeEnter: acl.allowGuardRight(ACL.PROFILE_CONVEYING, { name: 'vacancy.owner' }),
        path: 'search',
        component: () => import(/* webpackChunkName: "module-vacancy" */ './components/vw/search'),
      },
      {
        name: 'vacancy.create',
        label: 'navigation.vacancy-create-label',
        beforeEnter: acl.loggedGuard(true),
        path: 'create',
        right: ACL.VACANY,
        component: () => import(/* webpackChunkName: "module-vacancy" */ './components/vw/create'),
      },
      {
        name: 'vacancy.bookmark',
        label: 'navigation.vacancy-bookmark-label',
        beforeEnter: acl.allowGuardRight(ACL.PROFILE_CONVEYING, { name: 'vacancy.owner' }),
        path: 'bookmark',
        component: () => import(/* webpackChunkName: "module-vacancy" */ './components/vw/bookmark'),
      },
      {
        name: 'vacancy.owner',
        label: 'navigation.vacancy-owner-label',
        beforeEnter: acl.loggedGuard(true),
        path: 'owner',
        right: ACL.VACANY,
        component: () => import(/* webpackChunkName: "module-vacancy" */ './components/vw/owner'),
      },

      // not part or navigation component
      {
        name: 'vacancy.detail',
        label: 'navigation.vacancy-bookmark-label',
        beforeEnter: acl.loggedGuard(true),
        path: 'detail/:id',
        right: ACL.VACANY,
        component: () => import(/* webpackChunkName: "module-vacancy" */ './components/vw/detail'),
        meta: {
          printable: true,
        },
      },

      {
        name: 'vacancy.edit',
        label: 'navigation.vacancy-edit-label',
        beforeEnter: acl.loggedGuard(true),
        path: 'edit/:id',
        right: ACL.VACANY,
        component: () => import(/* webpackChunkName: "module-vacancy" */ './components/vw/create'),
      },
    ],
  },
];
