<template>
  <div class="v-container-subheader">
    <OLayoutSubheader :progress="progress" />
    <template v-if="showProcessBar">
      <BProgress :max="progressSteps.length">
        <BProgressBar v-for="step in progressSteps" :key="step" :value="1" class="process-step" v-bind="step.props">
          <span class="d-sm-down-none">
            {{ step.title }}
          </span>
        </BProgressBar>
      </BProgress>
    </template>
    <router-view :progress="progress" />
  </div>
</template>
<script>
import { BProgressBar, BProgress } from 'bootstrap-vue';
import OLayoutSubheader from '../../o/layout/subheader';

export default {
  name: 'c-container-subheader',
  components: { OLayoutSubheader, BProgressBar, BProgress },
  props: {
    progress: Array,
    showProcessBar: Boolean,
  },
  computed: {
    progressSteps() {
      const { progress } = this;
      const currentRouteName = this.$route.name;
      let hasFound = false;

      return progress.map((p, index) => {
        let variant = hasFound ? 'secondary' : 'dark';
        let active = false;
        let title = p.title;

        if (p.name === currentRouteName) {
          hasFound = true;
          variant = 'primary';
          active = true;
        }

        if (p.translationTitle) {
          title = this.$t(p.translationTitle);
        }

        return {
          title,
          index,
          active,
          props: {
            showProgress: false,
            animated: active,
            variant,
          },
        };
      });
    },
  },
};
</script>

<style lang="scss">
.v-container-subheader {
  .process-step {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}
</style>
