<template>
  <BImg :class="className" thumbnail :src="source" />
</template>

<script>
import { BImg } from 'bootstrap-vue';
import types from 'library/src/utilities/types';
import placeholderSource from '../../../public/img/placeholder/profile-neutral.jpg';
import { SMALL, SIZES } from './thumbnail-size.enum';

export default {
  name: 'o-thumbnail',
  components: {
    BImg,
  },
  props: {
    document: [Object, String],
    size: {
      type: String,
      default: SMALL,
      validate: v => SIZES.includes(v),
    },
    placeholder: {
      type: String,
      default: placeholderSource,
    },
    noBoxed: Boolean,
  },
  computed: {
    className() {
      return ['o-thumbnail', { 'no-boxed': this.noBoxed }];
    },
    source() {
      const { document } = this;
      let documentId = null;

      // set by id only
      if (types.string(document)) {
        documentId = document;
      }
      // set by document model
      else if (types.object(document)) {
        documentId = document.id;
      }

      if (documentId) {
        return this.$api.document.thumbnailUri(documentId, this.size);
      }

      return this.placeholderSource;
    },
  },
};
</script>

<style lang="scss" scoped>
.o-thumbnail {
  max-width: 100%;

  &.no-boxed {
    padding: 0;
    background: none;
    border: none;
  }
}
</style>
