<template>
  <div class="c-search-quickfilter">
    <BContainer>
      <label class="mr-2 hideable-on-empty">{{ $t(`${prefix}.label`) }}</label>
      <div class="button-container">
        <BButton
          v-for="key in filter"
          size="sm"
          :key="key"
          :variant="buttonVariant"
          :pressed="key === value"
          @click="updateModel(key)"
        >
          <div class="button-content">
            <i class="check" />
            <span class="label">
              {{ $t(`${prefix}.button-label.${key}`) }}
            </span>
          </div>
        </BButton>
      </div>
    </BContainer>
  </div>
</template>

<script>
import { BContainer, BButtonGroup, BButton } from 'bootstrap-vue';

export default {
  name: 'c-search-quickfilter',
  components: {
    BContainer,
    BButtonGroup,
    BButton,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    prefix: {
      type: String,
      default: 'c.search-quickfilter',
    },
    filter: {
      type: Array,
      required: true,
    },
    buttonVariant: {
      default: 'primary',
    },
    value: String,
  },

  beforeMount() {
    // always select first item if value undefined
    if (!this.value) {
      const filter = this.filter[0];
      this.updateModel(filter);
    }
  },

  methods: {
    updateModel(filter) {
      this.$emit('change', filter);
    },
  },
};
</script>

<style lang="scss">
.c-search-quickfilter {
  $color: white;

  .check {
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid $color;
    margin-right: 4px;
    border-radius: 50%;
    background-color: rgba($color, 0.2);
  }

  .button-content {
    display: flex;
    align-items: center;
  }

  .button-container {
    display: inline-block;

    > .btn {
      margin-right: 0;
    }
  }

  // state
  .active .check {
    background-color: $color;
  }
}
</style>
