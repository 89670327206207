<template>
  <div class="o-card-footer-buttons">
    <BCardFooter>
      <div class="button-container text-right">
        <BButton variant="dark" :to="backTo">
          {{ backButtonLabel }}
        </BButton>
        <BButton v-if="!asClosing" type="submit" variant="primary" :disabled="disableSubmitButton">
          <BSpinner v-if="isLoading" small />
          <template v-else>
            {{ $t(`${prefix}.submit-label`) }}
          </template>
        </BButton>
      </div>
    </BCardFooter>
  </div>
</template>

<script>
import { BCardFooter, BButton, BSpinner } from 'bootstrap-vue';
export default {
  name: 'o-card-footer-buttons',
  components: {
    BCardFooter,
    BButton,
    BSpinner,
  },
  props: {
    prefix: {
      type: String,
      required: true,
    },
    backTo: {
      type: [Object, String],
      default: null,
    },
    isValid: Boolean,
    isLoading: Boolean,
    asClosing: Boolean,
  },
  computed: {
    disableSubmitButton() {
      return !this.isValid || this.isLoading;
    },
    backButtonLabel() {
      const { asClosing, prefix } = this;
      const labelKey = asClosing ? 'close-label' : 'cancel-label';
      return this.$t(`${prefix}.${labelKey}`);
    },
  },
};
</script>
