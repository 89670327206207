<template>
  <BButton v-if="allowed" :class="className" :data-identity="routeId" v-bind="buttonProps" @click="onClick">
    <SOIcon v-if="icon" :name="icon" class="icon" size="sm" />
    <span v-if="label" :class="['label', labelClass]">{{ innerLabel }}</span>
    <SOIcon v-if="hasChilds" class="dropdown" name="ellipsis-v" size="sm" />
  </BButton>
</template>
<script>
import { BButton } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-navigation-button',
  components: { BButton, SOIcon },
  data() {
    return {
      isActive: false,
      routePattern: null,
    };
  },
  props: {
    icon: String,
    label: String,
    to: String,
    routeId: String,
    redirect: String,
    labelClass: String,
    right: String,
    variant: String,
    childs: [Array, Object],
    activeVariant: {
      type: String,
      default: 'dimmed',
    },
  },
  computed: {
    className() {
      return [
        'o-navigation-button',
        `as-active-variant-${this.activeVariant}`,
        this.icon ? 'has-icon' : 'without-icon',
        {
          'is-active': this.isActive,
        },
      ];
    },
    innerLabel() {
      return this.$t(`${this.label}`);
    },
    hasChilds() {
      return !!this.childs;
    },
    buttonProps() {
      return {
        variant: this.variant,
      };
    },
    allowed() {
      const { right } = this;

      if (right) {
        return this.$store.getters['auth/hasRight'](right);
      }

      return true;
    },
  },

  beforeMount() {
    // set only pattern if route id is set
    if (this.routeId) {
      this.routePattern = new RegExp(this.routeId);
    }

    this.$router.afterEach(to => this.onUpdateRoute(to, this.key));
    this.onUpdateRoute(this.$router.currentRoute);
  },

  methods: {
    /**
     * updateRoute
     * @param to
     */
    onUpdateRoute(to) {
      // can only mark as active if route id exists
      if (this.routePattern) {
        this.isActive = this.routePattern.test(to.name);
      }
    },

    /**
     * internal click event
     * @param event
     */
    onClick(event) {
      const name = this.redirect ? this.redirect : this.routeId;
      const currentRouteName = this.$route.name;

      this.$emit('click', event);

      // cache current route recall
      if (name && name !== currentRouteName) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

@mixin active() {
  background-color: $color-soft;
  color: $color-dark;
}

$offset: 2px;

.has-icon > .label {
  margin-left: $space-small;
}

.dropdown {
  vertical-align: 0;
  margin-left: $space-small;
  opacity: 0.4;
}

.o-navigation-button {

  &:active,
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }

  &.is-active {
    // dimmed style
    &.as-active-variant-dimmed {
      @include active();
      position: relative;
    }

    // filled
    &.as-active-variant-filled {
      position: relative;
      @include active();
    }
  }

  .sub-navigation &:not(.is-active) {
    color: lighten($color-dark, 5%)
  }

  &.btn-link {
    color: $color-dark;
  }
}
</style>
