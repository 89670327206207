<script>
const PARAGRAPH = 'paragraph';
const BREAK = 'br';

const LINE_STYLES = [PARAGRAPH, BREAK];

export default {
  name: 'o-text',
  props: {
    content: String,
    lineStyle: {
      type: String,
      default: LINE_STYLES[0],
      validate: v => LINE_STYLES.includes(v),
    },
  },
  computed: {
    className() {
      return 'o-text user-content';
    },
  },
  render(createElement) {
    const { content, lineStyle } = this;

    // render nothing if invalid input
    if (!typeof content === 'string') {
      return;
    }

    const paragraph = content.split('\n');
    let text = '';

    if (lineStyle === BREAK) {
      text = paragraph.join('br');
    } else {
      text = paragraph
        .map(p => p.trim())
        .filter(p => p)
        .map(p => `<p>${p}</p>`)
        .join('');
    }

    // show div
    return createElement('div', {
      class: this.className,
      domProps: {
        innerHTML: text,
      },
    });
  },
};
</script>
