<template>
  <div class="c-search-bar">
    <BContainer>
      <BTabs v-model="searchMode" pills>
        <!-- search -->
        <BTab :title="$t(`${prefix}.tabs.filter-label`)" active>
          <BRow class="search-bar-filters">
            <BCol sm="12" md="auto" lg="5">
              <OInputSelectPosition :max="1" v-bind="$t(`${prefix}.position`)" v-model="position" />
            </BCol>
            <BCol sm="12" md="auto" lg="5">
              <OInputGeolocation v-bind="$t(`${prefix}.location`)" :disabled="!position" v-model="location" />
            </BCol>
            <BCol>
              <OInputOption v-if="location" v-bind="getOrderOptionProps(false)" v-model="orderBy" />
              <OInputOption v-else v-bind="getOrderOptionProps(true)" v-model="orderBy" />
            </BCol>
          </BRow>
          <OToggle :disabled="!position" :label="$t(`${prefix}.extras.button-label`)" v-model="expandExtras" />
          <div v-if="expandExtras" class="extra-filters">
            <BRow>
              <BCol sm="12" md="6" lg="5">
                <OInputModelKeyword :positions="position" v-bind="$t(`${prefix}.keywords`)" v-model="keywords" />
              </BCol>
              <BCol sm="auto" md="5">
                <OInputSlider
                  :label="`${prefix}.distance.label`"
                  description=""
                  render-label
                  hide-value
                  :disabled="!location"
                  v-model="distance"
                />
                <OInputDate v-bind="$t(`${prefix}.availability`)" v-model="availability" />
              </BCol>
            </BRow>
          </div>
        </BTab>
        <!-- search by id -->
        <BTab :title="$t(`${prefix}.tabs.direct-label`)">
          <OInputValue
            identity="search-bar-input-direct-id"
            v-bind="$t(`${prefix}.direct-id`)"
            v-model="profileIdentity"
          />
        </BTab>
      </BTabs>
      <BCard no-body>
        <BCardFooter class="text-md-right">
          <div class="button-container">
            <BButton data-identity="search-bar-reset" variant="dark" @click.prevent="onReset">
              {{ resetLabel }}
            </BButton>

            <BButton
              data-identity="search-bar-submit"
              variant="primary"
              :disabled="submitDisabled"
              @click.prevent="onSubmit"
            >
              <SOIcon name="search" size="sm" as-label-icon />
              {{ submitLabel }}
            </BButton>
          </div>
        </BCardFooter>
      </BCard>
    </BContainer>
  </div>
</template>

<script>
import { onlyProps } from 'library/src/utilities/only-props';
import { BContainer, BRow, BCol, BCard, BCardFooter, BButton, BTabs, BTab } from 'bootstrap-vue';
import { createInternalId } from 'library/src/utilities/create-internal-id';
import { PROFILE_ID } from 'library/src/models/prefixes';

import SOIcon from 'library/components/src/components/so/icon';
import OInputSelectPosition from '../../o/input/select/position';
import OToggle from '../../o/toggle';
import OInputOption from '../../o/input/option';
import OInputModelKeyword from '../../o/input/model/keyword';
import OInputValue from '../../o/input/value';
import OInputDate from '../../o/input/date';
import OInputSlider from '../../o/input/slider';
import OInputGeolocation from '../../o/input/geolocation';

const prefix = 'c.search-bar';

const TABINDEX = {
  FILTER: 0,
  DIRECT: 1,
};

const ORDER_BY = {
  AVAILABILITY: 'availability',
  DISTANCE: 'distance',
};

export default {
  name: 'c-search-bar',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BButton,
    BTab,
    BTabs,
    OInputGeolocation,
    SOIcon,
    OInputSlider,
    OInputDate,
    OInputValue,
    OInputModelKeyword,
    OInputOption,
    OToggle,
    OInputSelectPosition,
  },
  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: Object,
  },
  data() {
    return {
      searchMode: TABINDEX.FILTER,
      expandExtras: false,
      position: null,
      location: null,
      keywords: null,
      profileIdentity: null,
      distance: 50,
      availability: null,
      orderBy: ORDER_BY.AVAILABILITY,
    };
  },
  computed: {
    prefix: () => prefix,
    submitDisabled() {
      const { searchMode, position, profileIdentity } = this;

      if (searchMode === TABINDEX.DIRECT) {
        return !profileIdentity;
      }

      return !position;
    },
    resetLabel() {
      return this.$t(`${prefix}.filter-reset-label`);
    },
    submitLabel() {
      return this.$t(`${prefix}.filter-submit-label`);
    },
  },
  watch: {
    position(value) {
      if (!value) {
        this.expandExtras = false;
      }
    },
    location(value) {
      if (!value) {
        this.orderBy = ORDER_BY.AVAILABILITY;
      }
    },
  },
  methods: {
    /**
     * generate order options props
     *
     * @param limited
     * @returns {{stacked : boolean, options : *, label : (*|TranslateResult)}}
     */
    getOrderOptionProps(limited) {
      const options = [
        {
          text: this.$t(`${prefix}.order.options-availability-label`),
          value: 'availability',
        },
        {
          text: this.$t(`${prefix}.order.options-distance-label`),
          value: 'distance',
        },
      ];

      if (limited) {
        options[1].disabled = true;
      }

      return {
        label: this.$t(`${prefix}.order.label`),
        options,
        stacked: true,
      };
    },

    onReset() {
      ['profileIdentity', 'position', 'location', 'keywords', 'distance', 'availability'].forEach(fieldName => {
        this[fieldName] = null;
      });
      this.onSubmit();
    },
    onSubmit() {
      const { searchMode } = this;

      if (searchMode === TABINDEX.DIRECT) {
        this.submitDirect();
      } else {
        this.submitFilter();
      }
    },
    submitDirect() {
      this.$emit('change', {
        mode: 'direct',
        profileIdentity: createInternalId(this.profileIdentity, PROFILE_ID),
      });
    },
    submitFilter() {
      const model = onlyProps(this, ['position', 'location', 'keywords', 'distance', 'availability', 'orderBy']);

      this.$emit('change', {
        mode: 'filter',
        ...model,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.extra-filters {
  padding-top: $space-default;
}
</style>
