<template>
  <header class="c-navigation is-not-printable">
    <BContainer>
      <div class="hotline for-medium d-none d-md-block">
        {{ $t(`brand.hotline-line`) }}
      </div>
      <!-- desktop navigation -->
      <BRow class="d-none d-md-flex">
        <BCol md="auto">
          <SOLogo class="logo" as-primary />
        </BCol>
        <BCol v-if="isActiveLogged">
          <nav role="navigation" class="items">
            <BRow>
              <!-- desktop navigation -->
              <BCol>
                <div class="button-container">
                  <ONavigationButton
                    v-for="(rootItem, key) in rootItems"
                    :key="key"
                    v-bind="rootItem"
                    variant="link"
                    class="navigation-parent"
                    active-variant="filled"
                  />
                </div>
              </BCol>
              <BCol md="auto">
                <ONavigationButton
                  class="item"
                  v-bind="items.logout"
                  variant="secondary"
                  label-class="hide-for-small"
                  data-identity="logout-button"
                  @click.prevent="toLogout"
                />
              </BCol>

              <!-- mobile navigation -->

              <!-- submenu -->
              <BCol sm="12">
                <div v-if="childItems" class="sub-navigation">
                  <ONavigationButton
                    v-for="item in childItems"
                    :key="item.routeId"
                    variant="link"
                    v-bind="item"
                    :childs="null"
                  />
                </div>
              </BCol>
            </BRow>
          </nav>
        </BCol>
      </BRow>

      <!-- mobile navigation -->
      <div class="mobile-navigation d-md-none">
        <div class="mobile-navigation-header">
          <SOLogo class="mobile-logo" />
          <BButton variant="primary" @click="onMobileToggle">
            <SOIcon name="bars" />
          </BButton>
        </div>
      </div>
    </BContainer>

    <!-- mobile subitems -->
    <template v-if="isMobileOpen">
      <BCard role="navigation" class="mobile-navigation-items d-md-none" no-body>
        <ONavigationMobileButton v-bind="items.directPv" as-dark @click="onMobileToggle" />
        <ONavigationMobileButton
          v-for="item in items.directPv.childs"
          :key="item.name"
          v-bind="item"
          @click="onMobileToggle"
        />

        <ONavigationMobileButton v-bind="items.my" as-dark @click="onMobileToggle" />
        <ONavigationMobileButton
          v-for="item in items.my.childs"
          :key="item.name"
          v-bind="item"
          @click="onMobileToggle"
        />

        <ONavigationMobileButton v-bind="items.logout" as-dark @click="toLogout" />
      </BCard>

      <div class="hotline for-small">
        <a :href="'tel:' + $t(`brand.service-phone`)" class="h3 hotline-phone">
          <SOIcon name="phone" size="sm" as-label-icon />
          <span class="label">{{ $t(`brand.service-phone`) }}</span>
        </a>
        <div class="hotline-opening">{{ $t(`brand.opening-hours`) }}</div>
      </div>
    </template>
  </header>
</template>
<script>
import Vue from 'vue';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import ACL from 'library/src/models/acl.enum';

import { BContainer, BCard, BRow, BCol, BButton } from 'bootstrap-vue';
import SOLogo from 'library/components/src/components/so/logo';
import SOIcon from 'library/components/src/components/so/icon';

import ONavigationButton from '../o/navigation/button';
import ONavigationMobileButton from '../o/navigation/mobile-button';

const prefix = 'navigation';

export default {
  name: 'c-navigation',
  mixins: [currentUserMixin],
  components: {
    BContainer,
    BCard,
    BRow,
    BCol,
    BButton,
    ONavigationButton,
    ONavigationMobileButton,
    SOLogo,
    SOIcon,
  },
  data() {
    return {
      activeGroup: null,
      childItems: null,
      isMobileOpen: false,
      routeName: null,
    };
  },
  computed: {
    prefix: () => prefix,
    module() {
      return this.$module || {};
    },
    items() {
      return Vue.$navigation;
    },
    rootItems() {
      const items = { ...this.items };
      delete items.logout;
      items.directPv = this.directPvItems;
      return items;
    },
    directPvItems() {
      let items = this.items.directPv;

      // has rights for import profiles
      if (this.isAllowed(ACL.PROFILE_CONVEYING)) {
        items.redirect = items.childs.owners.routeId;
      }
      // has rights for searching
      else if (this.isAllowed(ACL.PROFILE_SEARCHING)) {
        items.redirect = items.childs.search.routeId;
      } else {
        items = {};
      }

      return items;
    },
  },

  beforeMount() {
    this.$router.afterEach(to => {
      this.onUpdateRoute(to);
    });

    this.onUpdateRoute(this.$router.currentRoute);
  },

  methods: {
    onUpdateRoute(to) {
      const { rootItems } = this;
      this.routeName = to.name;
      const activeGroup = `${to.name}`.split('.')[0];

      Object.keys(rootItems).forEach(key => {
        const root = rootItems[key];

        if (root.routeId === activeGroup) {
          if (root.childs) {
            this.childItems = root.childs;
          }
        }
      });
    },
    onMobileToggle() {
      this.isMobileOpen = !this.isMobileOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

$offset: 4px;
$vertical-gutter: to-rem(20px);

.c-navigation {
  background-color: white;
  padding: {
    top: $space-tiny;
    bottom: $space-small;
  }
}

.hotline {
  &.for-small {
    color: white;
    text-align: center;
    padding-bottom: $space-default;

    > .hotline-phone {
      color: inherit;
    }
  }

  &.for-medium {
    font-size: $font-size-base;
    color: $color-noted;
    text-align: right;
    padding-top: $vertical-gutter / 4;
  }
}

.items {
  margin-top: $space-tiny;
}

.item:not(:last-child) {
  margin-right: $space-small;
}

.sub-navigation {
  // background-color: $color-;
  margin-top: $offset;
}

// mobile navigation
.mobile-navigation-header {
  display: flex;
  align-items: center;
}

.mobile-logo {
  flex-grow: 2;
}

.mobile-navigation-items {
  display: block;
  margin-top: $space-tiny;
}

// media query
.logo {
  @include property(padding-top, (null, 14px));
}
</style>
