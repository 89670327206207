import BOOKMARK from 'library/src/models/bookmark.enum';
import { SessionObject } from 'library/src/utilities/session-storage';

export function createModule({ Vue }) {
  const namespaced = true;

  const suggesstionSession = new SessionObject('vacancy_suggesstions');

  const state = {
    bookmarked: [],
    suggesstions: suggesstionSession.get()
  };

  const getters = {
    id: state => state.id,
    isBookmarkedById: state => id => {
      return state.bookmarked.includes(id);
    },
    hasSuggestionAlready: state => (vacancyId) => {
      if (Array.isArray(state.suggesstions[vacancyId]))  {
        return state.suggesstions[vacancyId].length > 0;
      }
      return false;
    },
    isProfileSuggestedAlready: state => (profileId, vacancyId) => {
      if (Array.isArray(state.suggesstions[vacancyId]))  {
        return state.suggesstions[vacancyId].includes(profileId);
      }
      return false;
    }
  };

  const mutations = {
    setBookmarked(state, list) {
      state.bookmarked = Array.from(list);
    },
    bookmark(state, id) {
      state.bookmarked.push(id);
    },
    unbookmark(state, id) {
      state.bookmarked = state.bookmarked.filter(bid => bid !== id);
    },
    suggesstion(state, { profileId, vacancyId }) {
      if (!Array.isArray(state.suggesstions[vacancyId]))  {
        state.suggesstions[vacancyId] = [];
      }

      state.suggesstions[vacancyId].push(profileId);
      suggesstionSession.set(state.suggesstions);
    },
  };

  const actions = {
    profileSuccesstion({commit}, event) {
      if (event.type === 'vacancy') {
        const { profileId, vacancyId } = event;
        commit('suggesstion', { profileId, vacancyId });
      }
    },

    /**
     * toggleBookmark
     *
     * @param commit
     * @param getters
     * @param dispatch
     * @param id
     * @returns {Promise<boolean>}
     */
    async toggleBookmark({ commit, getters, dispatch }, id) {
      // request bookmark
      const bookmarkType = BOOKMARK.VACANCY;

      try {
        const isBookmarked = getters.isBookmarkedById(id);

        if (isBookmarked) {
          await Vue.$api.bookmark.deleting(id, bookmarkType);
          commit('unbookmark', id);
        } else {
          await Vue.$api.bookmark.creating(id, bookmarkType);
          commit('bookmark', id);
        }
      } catch (error) {
        dispatch(
          'error',
          {
            section: `${bookmarkType}.bookmark`,
            error,
          },
          { root: true },
        );

        return false;
      }

      return true;
    },

    /**
     * fetch or update bookmarked list by current user
     *
     * @param commit
     * @param dispatch
     * @returns {Promise<void>}
     */
    async fetchBookmark({ commit, dispatch }, options = {}) {
      // catch if api service not available
      if (!Vue.$api) {
        return;
      }

      return Vue.$api.bookmark
        .listingByOwner(BOOKMARK.VACANCY)
        .then(list => {
          commit(
            'setBookmarked',
            list.map(item => item.documentId),
          );
        })
        .catch(error => {
          if (!options.passive) {
            dispatch('error', error, { root: true });
          }
        });
    },

    /**
     * reset or clear bookmark list
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async resetBookmark({ commit }) {
      commit('setBookmarked', []);
    },
  };

  return {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  };
}
