import { SessionKey } from 'library/src/utilities/session-storage';
import BOOKMARK from 'library/src/models/bookmark.enum';

const sessionProfileId = new SessionKey('profile-id');

export function createModule({ Vue }) {
  const namespaced = true;

  const state = {
    id: sessionProfileId.get(),
    isOnFetchingStatus: false,
    requestStatus: {}, // map with information of approvial request and reservation status
    bookmarked: [],
  };

  const getters = {
    id: state => state.id,
    isBookmarkedById: state => id => {
      return state.bookmarked.includes(id);
    },
    getRequestStatus: state => id => {
      if (state.requestStatus.hasOwnProperty(id)) {
        return state.requestStatus[id];
      }

      return {
        reservationAllowed: true,
        reservationStatus: null,
        isReservationOwner: false,
        approvalRequestAllowed: true,
        approvalRequestStatus: null,
        isApprovalRequestOwner: false,
      };
    },
  };

  const mutations = {
    setId(state, id) {
      state.id = id;
      sessionProfileId.set(id);
    },
    setBookmarked(state, list) {
      state.bookmarked = Array.from(list);
    },
    bookmark(state, id) {
      state.bookmarked.push(id);
    },
    unbookmark(state, id) {
      state.bookmarked = state.bookmarked.filter(bid => bid !== id);
    },
    setRequestStatus(state, requestStatus) {
      state.requestStatus = requestStatus || {};
    },
  };

  const actions = {
    /**
     * reservation
     *
     * @param commit
     * @param dispatch
     * @param id
     * @returns {Promise<boolean>}
     */
    async reservation({ commit, dispatch }, { id, companyName, reject, relation }) {
      try {
        Vue.$logger.log('Reservation of profile', id);

        const responseModel = await Vue.$api.reservation.reservating(id, companyName, relation);

        await Vue.$api.bookmark.deleting(id, BOOKMARK.PROFILE);
        commit('unbookmark', id);

        return {
          isApprovalRequest: responseModel.hasOwnProperty('approvalRequestId'),
        };
      } catch (error) {
        if (reject) {
          return Promise.reject(error);
        }

        dispatch(
          'error',
          {
            section: 'profile.reservation',
            error,
          },
          { root: true },
        );

        return false;
      }

      return true;
    },

    /**
     * toggleBookmark
     *
     * @param commit
     * @param getters
     * @param dispatch
     * @param id
     * @returns {Promise<boolean>}
     */
    async toggleBookmark({ commit, getters, dispatch }, id) {
      // request bookmark
      const bookmarkType = BOOKMARK.PROFILE;

      try {
        const isBookmarked = getters.isBookmarkedById(id);

        if (isBookmarked) {
          await Vue.$api.bookmark.deleting(id, bookmarkType);
          commit('unbookmark', id);
        } else {
          await Vue.$api.bookmark.creating(id, bookmarkType);
          commit('bookmark', id);
        }
      } catch (error) {
        dispatch(
          'error',
          {
            section: `${bookmarkType}.bookmark`,
            error,
          },
          { root: true },
        );

        return false;
      }

      return true;
    },

    /**
     * fetch or update bookmarked list by current user
     *
     * @param commit
     * @param dispatch
     * @returns {Promise<void>}
     */
    async fetchBookmark({ commit, dispatch }, options = {}) {
      // catch if api service not available
      if (!Vue.$api) {
        return;
      }

      return Vue.$api.bookmark
        .listingByOwner(BOOKMARK.PROFILE)
        .then(list => {
          commit(
            'setBookmarked',
            list.map(item => item.documentId),
          );
        })
        .catch(error => {
          if (!options.passive) {
            dispatch('error', error, { root: true });
          }
        });
    },

    /**
     * fetchRequestStatus
     *
     * @param commit
     * @param items list of ids
     * @param companyName
     * @return {Promise<*>}
     */
    async fetchRequestStatus({ commit, state, dispatch }, { items, companyName }) {
      state.isOnFetchingStatus = true;

      return Vue.$api.reservation
        .requestStatus(items, companyName)
        .then(requestStatus => {
          commit('setRequestStatus', requestStatus);
        })
        .catch(error => {
          dispatch('error', error, { root: true });
        })
        .finally(() => {
          state.isOnFetchingStatus = false;
        });
    },

    /**
     * reset or clear bookmark list
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async resetBookmark({ commit }) {
      commit('setBookmarked', []);
    },
  };

  return {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  };
}
