<template>
  <BContainer class="v-my-password mt-4">
    <BForm @submit.prevent="onSubmit()">
      <BCard no-body>
        <BCardBody v-if="wasSuccessful">
          {{ $t(`${prefix}.success-submitted-copy`) }}
        </BCardBody>
        <BCardBody v-else>
          <OInputPassword :label="$t(`${prefix}.old-password-label`)" v-model="currentPassword" />
          <OInputPassword :label="$t(`${prefix}.new-password-label`)" v-model="changePassword" with-confirm />
        </BCardBody>
        <OCardFooterButtons
          :as-closing="wasSuccessful"
          :prefix="prefix"
          :back-to="backTo"
          :is-valid="isValid"
          :is-loading="isLoading"
        />
      </BCard>
    </BForm>
  </BContainer>
</template>

<script>
import { hasInterface } from 'library/src/utilities/has-interface';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { BContainer, BForm, BCard, BCardBody } from 'bootstrap-vue';
import OInputPassword from '../../o/input/password';
import OCardFooterButtons from '../../o/card/footer-buttons';

const prefix = 'vw.my-password';

export default {
  mixins: [pageMixin, serviceMixin],
  components: {
    OInputPassword,
    OCardFooterButtons,
    BContainer,
    BForm,
    BCard,
    BCardBody,
  },
  data() {
    return {
      prefix,
      backTo: { name: 'my' },
      currentPassword: null,
      changePassword: null,
      wasSuccessful: false,
    };
  },
  computed: {
    // simple validation check for submit button
    isValid() {
      return hasInterface(this.model, {
        currentPassword: String,
        password: String,
        confirm: String,
      });
    },
    model() {
      const { currentPassword, changePassword } = this;
      return Object.assign({ currentPassword }, changePassword);
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`${prefix}.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  methods: {
    async onSubmit() {
      if (!this.isValid) {
        return false; // ignore base validation
      }

      const { model } = this;
      this.$logger.log('Change password', model);

      await this.dispatchLoading(
        prefix,
        () => this.$api.auth.changePassword(model).then(() => (this.wasSuccessful = true)),
        { error: { translationKey: `${prefix}.errors`, useValidation: true } },
      );
    },
  },
};
</script>
