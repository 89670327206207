<template>
  <OInputAbstract class="o-input-slider" v-bind="abstractProps" :label="inputLabel" :description="descriptionLabel">
    <b-form-input v-bind="inputProps" v-model="model" />
    <span v-if="showValue" class="small">{{ valueLabel }}</span>
  </OInputAbstract>
</template>

<script>
import OInputValue from './value';
import OInputAbstract from './abstract';

const prefix = 'o.input-slider';

export default {
  name: 'o-input-slider',
  mixins: [OInputAbstract],
  components: { OInputAbstract, OInputValue },
  model: {
    prop: 'value',
    event: 'value',
  },
  props: {
    description: {
      type: [String, Boolean],
      default: true, // will render description
    },
    disabled: Boolean,
    hideValue: Boolean,
    /**
     * @var renderLabel will translate and replace placeholder by label input
     */
    renderLabel: Boolean,
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: [Number, String],
      default: 10,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
    unit: {
      type: String,
      default: 'km',
    },
  },
  computed: {
    prefix: () => prefix,
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('value', parseFloat(newValue));
      },
    },
    inputLabel() {
      const { label } = this;

      if (this.renderLabel) {
        return this.$t(label, this);
      }

      return label;
    },
    inputProps() {
      const { min, max, disabled } = this;

      return {
        type: 'range',
        min,
        max,
        disabled,
      };
    },
    valueLabel() {
      const { model, unit } = this;
      return `${model} ${unit}`;
    },
    showValue() {
      if (this.hideValue) {
        return false;
      }
      return this.value > this.min;
    },
    descriptionLabel() {
      const { description } = this;
      if (typeof description === 'string') {
        return description;
      }

      if (description === true) {
        const { min, max, value } = this;
        return this.$t(`${prefix}.description`, { min, max, value });
      }

      return undefined;
    },
  },
};
</script>
