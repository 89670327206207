<template>
  <div class="c-model-address">
    <OInputValue
      required
      icon-name="map-marker-alt"
      type="text"
      autocomplete="off"
      :identity="`${identity}-input-street`"
      class="has-required-marked"
      :label="$t(`${prefix}.street`)"
      :description="getFieldText('street-description', $t(`${prefix}.street-description`), $v.street)"
      :state="$v.street | validationState"
      v-model="street"
      @change="onChange"
      @keyup="onStreetChanged"
    >
      <OSuggestions
        v-if="allowAutofill && suggestions.length"
        slot="append"
        :items="suggestions"
        @item-clicked="onItemClicked"
      />
    </OInputValue>
    <BRow>
      <BCol lg="4" md="5" sm="12">
        <OInputValue
          required
          as-single-line-description
          :identity="`${identity}-input-zip`"
          :label="$t(`${prefix}.zip`)"
          :error="getError('zip')"
          :description="getFieldText('zip-description', $t(`${prefix}.zip-description`))"
          v-model="zip"
          :state="$v.zip | validationState"
          @change="onChange"
        />
      </BCol>
      <BCol lg="8" md="7" sm="12">
        <OInputValue
          required
          :identity="`${identity}-input-locality`"
          :label="$t(`${prefix}.locality`)"
          :error="getError('locality')"
          :description="getFieldText('locality-description', $t(`${prefix}.locality-description`))"
          :state="$v.locality | validationState"
          v-model="locality"
          @change="onChange"
        />
      </BCol>
    </BRow>
    <OInputSelectCountry
      :identity="`${identity}-select-country`"
      :allowed-countries="allowedCountries"
      :description="getFieldText('country-description', $t(`${prefix}.country-description`))"
      v-model="countryCode"
    />
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { modelMixin } from 'library/components/src/tools/mixins/model.mixin';
import { computedModel } from 'library/src/utilities/vue/computed-model';
import { LOCATION_TYPES } from 'library/src/models/location-type.enum';

import { validationState } from 'library/components/src/tools/filters/validation-state';

import { BRow, BCol } from 'bootstrap-vue';
import OInputValue from '../../o/input/value';
import OInputSelectCountry from '../../o/input/select/country';
import OSuggestions from '../../o/suggestions';

const prefix = 'c.model-address';

export default {
  name: 'c-model-address',
  mixins: [validationMixin, modelMixin],
  components: {
    BRow,
    BCol,
    OSuggestions,
    OInputValue,
    OInputSelectCountry,
  },
  filters: { validationState },
  data() {
    return {
      suggestions: [],
    };
  },
  props: {
    labels: Object,
    identity: {
      type: String,
      default: 'address',
    },
    /**
     * enable autocomplete function for street
     */
    allowAutofill: Boolean,
    allowedCountries: [Array, String],
  },
  computed: {
    prefix: () => prefix,
    ...computedModel(['street', 'zip', 'locality', 'countryCode']),
  },
  watch: {
    showErrors(newVal) {
      if (newVal === true) {
        this.validateModel();
      }
    },
  },
  validations: {
    street: {
      required,
      minLength: minLength(3),
    },
    zip: {
      required,
      minLength: minLength(3),
    },
    locality: {
      required,
      minLength: minLength(2),
    },
    countryCode: {
      required,
    },
  },
  methods: {
    getFieldText(name, defaultLabel) {
      if (this.labels && this.labels.hasOwnProperty(name)) {
        return this.labels[name];
      }
      return defaultLabel;
    },
    getError(name, errorMessage = null) {
      const $v = this.$v[name];

      if ($v.$dirty && $v.$invalid) {
        return errorMessage || this.$t(`validation-errors.${name}`);
      }
      return null;
    },

    onItemClicked(item) {
      this.$logger.log('onItemClicked', item);
      const { filterBy } = this;

      this.$api.geocode
        .locationById(item.locationId, filterBy)
        .catch(this.$logger.warn)
        .then(location => {
          if (location && location.address) {
            const { street, zip, locality, countryCode } = location.address;
            this.$emit('change', {
              street,
              zip,
              locality,
              countryCode,
            });
          }
        })
        .finally(() => {
          this.suggestions = [];
        });
    },

    onStreetChanged() {
      if (this.allowAutofill) {
        const street = this.street;
        if (street && street.length > 4) {
          this.$api.geocode
            .autocomplete(street, LOCATION_TYPES.STREET, this.allowedCountries)
            .then(items => {
              if (this.street) {
                this.suggestions = items;
              }
            })
            .catch(this.$logger.warn);
        }
      }
    },
  },
};
</script>
