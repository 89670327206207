<template>
  <div class="c-list-reserved content-component">
    <CSearchQuickfilter class="mb-4" :prefix="`${prefix}.filter`" :filter="quickFilter" v-model="filter" />
    <CProfileList v-bind="listProps" />
  </div>
</template>

<script>
import { VARIANTS } from '../../c/profile/item.enum';
import CProfileList from '../../c/profile/list';
import CSearchQuickfilter from '../../c/search/quickfilter';
import { RESERVATION_FILTERS } from 'library/src/models/filter/reservation.enum';

const prefix = 'c.list-reserved';

export default {
  name: 'c-list-reserved',
  components: {
    CProfileList,
    CSearchQuickfilter,
  },
  data() {
    return { filter: null };
  },
  computed: {
    prefix: () => prefix,
    listProps() {
      return {
        itemProps: {
          variant: VARIANTS.reservation,
        },

        filter: { filter: this.filter },
        listService: this.$api.reservation.pagingByOwner.bind(this.$api.reservation),
      };
    },
    quickFilter() {
      return Object.values(RESERVATION_FILTERS);
    },
  },
};
</script>
