<template>
  <div class="o-suggestions" v-if="items">
    <ul>
      <li v-for="(item, index) of items" :key="index" class="suggestions-item" @click="onItemClick(item, $event)">
        {{ getItemLabel(item) }}
      </li>
    </ul>
  </div>
</template>
<script>
import types from 'library/src/utilities/types';

export default {
  name: 'o-suggestions',
  props: {
    items: Array,
  },
  methods: {
    onItemClick(item) {
      this.$emit('item-clicked', item);
    },
    getItemLabel(item) {
      if (types.object(item)) {
        return item.label;
      }
      return item;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../component';

$border: $border-default;
$background: #333333;
$background-second: lighten($background, 3);
$background-hover: darken($background, 3);
$color: white;
$hover-color: rgba($color-ci, 0.05);

.o-suggestions {
  display: block;
  position: relative;
  z-index: $index-dimmer - 1;

  > ul {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    list-style: none;
    background-color: $background;
    color: $color;
    margin: 0;
    padding: 0;

    > li {
      &:nth-child(even) {
        background-color: $background-second;
      }

      &:hover {
        background-color: $background-hover;
      }
    }
  }
}

.suggestions-item {
  display: block;
  padding: $space-small;
  cursor: pointer;

  &:hover {
    background-color: $hover-color;
  }
}
</style>
