<template>
  <div class="c-list-search content-component">
    <CSearchBar v-model="filter" />
    <CProfileList
      v-if="companyName"
      fetch-profile-status
      :list-service="searchService"
      :item-props="itemProps"
      :filter="searchFilter"
    />
  </div>
</template>

<script>
import { computedMapping } from 'library/src/utilities/vueex/computed-mapping';

import CProfileList from '../../c/profile/list';
import CSearchBar from '../../c/search/bar';
import CPagination from '../../c/pagination';

export default {
  name: 'c-list-search',
  components: {
    CPagination,
    CSearchBar,
    CProfileList,
  },
  data() {
    return {
      filter: null,
    };
  },
  computed: {
    ...computedMapping('currentAccount', ['companyName']),
    searchService() {
      return this.$api.profile.search.bind(this.$api.profile);
    },
    itemProps() {
      return {
        variant: 'search',
      };
    },
    searchFilter() {
      let { filter, companyName } = this;
      filter = filter || {};

      filter.companyName = companyName;

      return filter;
    },
  },
};
</script>
