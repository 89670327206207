<template>
  <OInputAbstract class="o-input-model-keyword" v-bind="abstractProps" :label="$t(`${prefix}.label`)">
    <OInputTagsAbstract
      create-new-keyword
      identity="input-model-keyword"
      :load-options="loadOptions"
      :placeholder="$t(`${prefix}.placeholder`)"
      :maxlength="maxlength"
      v-model="model"
    />
  </OInputAbstract>
</template>

<script>
import OInputAbstract from '../abstract';
import OInputTagsAbstract from '../tags/abstract';
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';

const prefix = 'o.input-model-keyword';

export default {
  name: 'o-input-model-keyword',
  mixins: [OInputAbstract, languageMixin],
  components: {
    OInputAbstract,
    OInputTagsAbstract,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Array,
    positions: Array,
    autosuccessByCollect: Boolean,
    maxlength: {
      type: [String, Number],
      default: 40,
    },
  },
  computed: {
    prefix: () => prefix,
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
  },
  methods: {
    /**
     * fetch new list for options
     */
    loadOptions(input) {
      if (this.positions && this.positions.length) {
        return this.$api.profile.keywords(this.positions, input, this.autosuccessByCollect, this.language);
      }

      // ignore keywords by profile if positions not defined
      return Promise.resolve([]);
    },
  },
};
</script>
