import Vue from 'vue';
import store from '../store';
import env from '../environment';
import { logger, httpClient } from '../services';
import { ApiPlugin } from 'library/components/src/plugins/api/plugin';

const config = {
  issuer: env.issuer,
  key: env.key,
};

Vue.use(ApiPlugin, { httpClient, logger, config, store });
