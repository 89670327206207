<template>
  <BCardBody class="o-profile-reason color-set-soft">
    <SOIcon name="quote-left" class="mr-2" />
    <span class="mr-2 font-dimmed">{{ $t(`${prefix}.label`) }}</span>
    {{ note }}
  </BCardBody>
</template>

<script>
import { BCardBody } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.profile-reason';

export default {
  name: 'o-profile-reason',
  components: {
    BCardBody,
    SOIcon,
  },
  props: {
    note: {
      type: String,
      required: true,
    },
  },
  computed: {
    prefix: () => prefix,
  },
};
</script>
