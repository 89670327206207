<template>
  <OInputAbstract class="o-input-select-country" v-bind="abstractProps" :label="$t('o.select-country.label')">
    <BFormSelect :data-identity="identity" :options="options" v-model="model" />
  </OInputAbstract>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import OInputAbstract from '../abstract';

/**
 * selector for country by using iso code
 */
export default {
  name: 'o-input-select-country',
  mixins: [OInputAbstract],
  components: { OInputAbstract, BFormSelect },
  model: {
    prop: 'value',
    event: 'value',
  },
  props: {
    value: {
      required: true,
    },
    allowedCountries: Array,
    identity: String,
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('value', v);
      },
    },
    options() {
      const { allowedCountries } = this;

      const countries = this.$t('countries');
      let countryOptions = Object.keys(countries).map(value => {
        return {
          value,
          text: countries[value],
        };
      });

      // if country filtering is enable remove all countries that not part of this list, based on value code
      if (Array.isArray(allowedCountries)) {
        countryOptions = countryOptions.filter(country => allowedCountries.includes(country.value));
      }

      return countryOptions;
    },
  },
};
</script>
