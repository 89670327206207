import { acl } from '../services';
import VSubHeaderContainer from '../components/c/container/subheader';

const registrationRoute = { name: 'authentification' };

export default {
  name: 'registration',
  path: '/registration',
  component: VSubHeaderContainer,
  redirect: registrationRoute,
  props: {
    heading: 'direct-PV',
  },
  children: [
    {
      name: 'authentification',
      path: 'authentification',
      component: () => import(/* webpackChunkName: "registration" */ '../components/vw/registration/auth'),
      meta: {
        title: 'navigation.registration-title',
      },
      props: {
        nextRoute: { name: 'account' },
      },
    },
    {
      name: 'account',
      path: 'account',
      component: () => import(/* webpackChunkName: "registration" */ '../components/vw/registration/account-type'),
      beforeEnter: acl.loggedGuard(false, registrationRoute),
      meta: {
        title: 'navigation.registration-title',
      },
    },
    {
      name: 'bank-account',
      path: 'bank-account',
      component: () => import(/* webpackChunkName: "registration" */ '../components/vw/registration/bank-account'),
      beforeEnter: acl.loggedGuard(false, registrationRoute),
      meta: {
        title: 'navigation.registration-title',
      },
    },
    {
      name: 'conditions',
      path: 'conditions',
      component: () => import(/* webpackChunkName: "registration" */ '../components/vw/registration/condition'),
      meta: {
        title: 'navigation.registration-title',
      },
    },
    {
      name: 'import-condition',
      path: 'import-condition',
      component: () => import(/* webpackChunkName: "registration" */ '../components/vw/registration/import-condition'),
      beforeEnter: acl.loggedGuard(false, registrationRoute),
      meta: {},
    },
    {
      name: 'overview',
      path: 'overview',
      component: () => import(/* webpackChunkName: "registration" */ '../components/vw/registration/overview'),
      beforeEnter: acl.loggedGuard(false, registrationRoute),
      meta: {},
    },
  ],
};
