import navigation from './navigation';
import routes from './routes';
import { VacancyApi } from './services/vacancy-api';
import { createModule } from './store/vacancy';
import TRANSLATION_DE from './translations/de.json';

export default {
  /**
   * @param Vue
   * @param options
   */
  install(Vue, options = {}) {
    const { logger, router, httpClient } = options;
    const name = 'vacancy';

    logger.info(`Install module plugin ${name}`);

    Vue.prototype.$module = Vue.prototype.$module || {};

    // descripe module plugin
    const vacancyModule = {
      name,
      api: new VacancyApi({ httpClient, logger }),
    };

    router.addRoutes(routes);

    // @todo change that logic to order navigation items
    const $navigation = { ...Vue.$navigation };
    if ($navigation.my) {
      delete $navigation.my;
      $navigation[name] = navigation;
      $navigation.my = Vue.$navigation.my;
      Vue.$navigation = $navigation;
    }

    // check if plugin exists
    if (options.i18n) {
      options.i18n.mergeLocaleMessage('de', TRANSLATION_DE);
    }

    // inject store module
    if (options.store) {
      options.store.registerModule(name, createModule({ Vue }));
    }

    // regist
    Vue.prototype.$module.vacancy = vacancyModule;
  },
};
