<template>
  <component class="o-input-geolocation" v-bind="containerProps" v-on-clickaway="onBlured">
    <BInputGroup>
      <BInputGroupText v-if="!noStatusIcon" class="status-icon">
        <BSpinner v-if="isLoading" small />
        <SOIcon v-else :name="iconName" size="sm" />
      </BInputGroupText>

      <BFormInput
        :autocomplete="`ignore-${Math.random()}`"
        :required="required"
        :placeholder="placeholder"
        :class="{ 'has-required-marked': required }"
        v-model="input"
        @keyup="onChange"
        @keyup.enter="onSelection"
        @keyup.esc="onBlur"
        @focus="onChange"
        @blur="onBlur"
      />
      <OMarkRequired v-if="required" />
      <slot />
    </BInputGroup>
    <OSuggestions :items="suggestions" @item-clicked="onItemClicked" />
  </component>
</template>

<script>
import { BInputGroup, BInputGroupText, BSpinner, BFormInput } from 'bootstrap-vue';
import { LOCATION_TYPES, LOCATION_TYPE_LIST } from 'library/src/models/location-type.enum';
import { mixin as clickawayMixin } from 'vue-clickaway';
import OInputAbstract from './abstract';
import OSuggestions from '../suggestions';
import SOIcon from 'library/components/src/components/so/icon';
import OMarkRequired from '../mark/required';

const prefix = 'o.input-geolocation';

export default {
  name: 'o-input-geolocation',
  mixins: [OInputAbstract, clickawayMixin],
  components: {
    BInputGroup,
    BInputGroupText,
    BSpinner,
    BFormInput,
    OMarkRequired,
    SOIcon,
    OInputAbstract,
    OSuggestions,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      input: null,
      isLoading: false,
      suggestions: null,
    };
  },
  props: {
    value: Object,
    placeholder: {
      type: String,
      default() {
        return this.$t(`${prefix}.placeholder`);
      },
    },
    // overwrite
    label: {
      type: String,
      default() {
        return this.$t(`${prefix}.label`);
      },
    },
    minSearchLength: {
      type: Number,
      default: 2,
    },
    noStatusIcon: Boolean,
    noFieldContainer: Boolean,
    required: Boolean,
    countryCode: String,
    filterBy: {
      type: String,
      default: LOCATION_TYPES.ALL,
      validate: v => LOCATION_TYPE_LIST.includes(v),
    },
    iconName: {
      type: String,
      default: 'location-arrow',
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    /**
     * generate a abstract field component of div container
     *
     * @returns {{is : string}|{is : *}}
     */
    containerProps() {
      if (this.noFieldContainer) {
        return { is: 'div' };
      }

      return {
        is: OInputAbstract,
        ...this.abstractProps,
      };
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.input = value.label;
      } else if (value === null) {
        this.input = '';
        this.suggestions = null;
      }
    },
  },

  // methods
  methods: {
    onBlured() {
      this.clearSuggesstions();
    },
    onItemClicked(item) {
      this.$logger.log('clicked item', item);
      this.selectItem(item).then(() => {
        this.$emit('selected', this.model);
      });
    },
    onSelection() {
      const { suggestions } = this;
      if (suggestions && suggestions.length > 0) {
        const first = suggestions[0];
        this.$logger.log('selected item', first);

        this.selectItem(first).then(() => {
          this.$emit('selected', this.model);
        });
      }
    },
    onChange(event) {
      const { minSearchLength, filterBy, countryCode } = this;
      const value = event.currentTarget.value;

      // check valid
      if (value.length >= minSearchLength) {
        this.isLoading = true;

        this.$logger.info('Autocomplete request with', value, filterBy, countryCode);

        this.$api.geocode
          .autocomplete(value, filterBy, countryCode)
          .then(items => {
            if (this.input) {
              this.suggestions = items;
            }
          })
          .catch(this.$logger.warn)
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.model = null;
      }
    },
    onBlur() {
      setTimeout(() => {
        const { minSearchLength } = this;
        this.suggestions = null;

        if (this.input) {
          if (this.input.length <= minSearchLength) {
            this.input = '';
          } else if (!this.model) {
            this.input = '';
          }
        }
      }, 200);
    },
    clearSuggesstions() {
      this.suggestions = [];
    },

    async selectItem(item) {
      const { filterBy } = this;

      await this.$api.geocode
        .locationById(item.locationId, filterBy)
        .then(location => (this.model = location))
        .catch(this.$logger.warn);

      this.clearSuggesstions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../component';

.o-input-geolocation {
}

.status-icon {
  width: 42px;
}
</style>
